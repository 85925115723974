import { memo } from 'react';
import { Flex } from '@mantine/core';

import { useMouseDragging } from '@/shared/hooks/mouse/useMouseDragging';

interface NotepadResizeHandlesProps {
  containerY: number;
  containerHeight: number;
  onResize: ({ newY, newHeight }: { newY: number; newHeight: number }) => void;
}

const NotepadResizeBottomHandleBase = ({ containerY, containerHeight, onResize }: NotepadResizeHandlesProps) => {
  const { onMouseDown: onBottomHandleMouseDown } = useMouseDragging({
    onDragging: (deltaX, deltaY) => {
      onResize({ newY: containerY, newHeight: containerHeight + deltaY });
    },
    stopPropagation: true,
  });

  return (
    <Flex
      pos="absolute"
      sx={(theme) => ({
        bottom: 0.5,
        background: theme.colors.dark[3],
        width: '100%',
        height: 1.5,
        cursor: 'ns-resize',
        zIndex: 300,
        userSelect: 'none',
      })}
      onMouseDownCapture={onBottomHandleMouseDown}
    />
  );
};

export const NotepadResizeBottomHandle = memo(NotepadResizeBottomHandleBase);
