import { graphql } from '@/pageAI/gql';

export const organizationQuery = graphql(/* GraphQL */ `
  query Organization($id: IDV2!) {
    organization(id: $id) {
      id
      name
      avatarUrl
      practiceAreas
      website
      createdAt
      updatedAt
      members(first: 9999) {
        edges {
          node {
            role
            user {
              id
              firstName
              lastName
              email
              avatarUrl
              phone
              role
              createdAt
              updatedAt
            }
          }
        }
        pageInfo {
          startCursor
          hasPreviousPage
          hasNextPage
          endCursor
        }
        totalCount
      }
      invitations(first: 9999) {
        nodes {
          id
          email
          role
          createdAt
          invitee {
            id
            firstName
            lastName
            email
            avatarUrl
            phone
            role
            createdAt
            updatedAt
          }
          inviterActor {
            id
            firstName
            lastName
            email
            avatarUrl
            phone
            role
            createdAt
            updatedAt
          }
        }
      }
      joinRequests(first: 9999) {
        nodes {
          id
          createdAt
          user {
            id
            firstName
            lastName
            email
            avatarUrl
            phone
            role
            createdAt
            updatedAt
          }
        }
      }
      creditsBalance
      billingInfo {
        taxId
        businessName
        billingEmail
        address {
          city
          country
          line1
          line2
          postalCode
          state
        }
      }
      autoRenewalSetting {
        amount
        enabled
        lastRenewalAt
        lastRenewalStatus
        lastRenewalError
        threshold
      }
      subscription {
        id
        startDate
        collectionMethod
        currentPeriodStart
        currentPeriodEnd
        daysUntilDue
        cancelAt
        cancelAtPeriodEnd
        status
        plan {
          id
          name
          pricePerCredit
        }
      }
      pricing {
        creditBundles {
          id
          price
          quantity
          discountPercentage
        }
        paygPrice
      }
    }
  }
`);

export const updateOrganizationMemberRoleMutation = graphql(/* GraphQL */ `
  mutation UpdateOrganizationMemberRole($input: UpdateOrganizationMemberRoleInput!) {
    updateOrganizationMemberRole(input: $input) {
      clientMutationId
    }
  }
`);

export const createOrganizationMutation = graphql(/* GraphQL */ `
  mutation CreateOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      id
      name
      avatarUrl
      practiceAreas
      website
      createdAt
      updatedAt
    }
  }
`);

export const organizationByDomainQuery = graphql(/* GraphQL */ `
  query OrganizationByDomain($domain: String!) {
    organizationByDomain(domain: $domain) {
      id
      name
      avatarUrl
      practiceAreas
      website
      createdAt
      updatedAt
    }
  }
`);

export const requestToJoinOrganizationMutation = graphql(/* GraphQL */ `
  mutation RequestToJoinOrganization($input: RequestToJoinOrganizationInput!) {
    requestToJoinOrganization(input: $input) {
      joinRequest {
        id
      }
    }
  }
`);

export const approveRequestToJoinOrganizationMutation = graphql(/* GraphQL */ `
  mutation ApproveRequestToJoinOrganization($input: ApproveRequestToJoinOrganizationInput!) {
    approveRequestToJoinOrganization(input: $input) {
      joinRequest {
        id
        organization {
          id
        }
      }
    }
  }
`);

export const declineRequestToJoinOrganizationMutation = graphql(/* GraphQL */ `
  mutation DeclineRequestToJoinOrganization($input: DeclineRequestToJoinOrganizationInput!) {
    declineRequestToJoinOrganization(input: $input) {
      joinRequest {
        id
        organization {
          id
        }
      }
    }
  }
`);

export const inviteOrganizationMemberMutation = graphql(/* GraphQL */ `
  mutation InviteOrganizationMember($input: InviteOrganizationMemberInput!) {
    inviteOrganizationMember(input: $input) {
      invitation {
        id
        email
        role
        createdAt
        invitee {
          id
          firstName
          lastName
          email
          avatarUrl
          phone
          role
          createdAt
          updatedAt
        }
        inviterActor {
          id
          firstName
          lastName
          email
          avatarUrl
          phone
          role
          createdAt
          updatedAt
        }
        organization {
          id
        }
      }
    }
  }
`);

export const removeOrganizationInvitationMutation = graphql(/* GraphQL */ `
  mutation RemoveOrganizationInvitation($input: RemoveOrganizationInvitationInput!) {
    removeOrganizationInvitation(input: $input) {
      clientMutationId
    }
  }
`);

export const organizationInvitationQuery = graphql(/* GraphQL */ `
  query OrganizationInvitation($id: IDV2!) {
    organizationInvitation(id: $id) {
      id
      email
      role
      createdAt
      organization {
        id
        name
        avatarUrl
        practiceAreas
        website
        createdAt
        updatedAt
      }
    }
  }
`);

export const acceptOrganizationInvitationMutation = graphql(/* GraphQL */ `
  mutation AcceptOrganizationInvitation($input: AcceptOrganizationInvitationInput!) {
    acceptOrganizationInvitation(input: $input) {
      invitation {
        id
        email
        role
        createdAt
        organization {
          id
          name
          avatarUrl
          practiceAreas
          website
          createdAt
          updatedAt
        }
      }
    }
  }
`);

export const removeOrganizationMemberMutation = graphql(/* GraphQL */ `
  mutation RemoveOrganizationMember($input: RemoveOrganizationMemberInput!) {
    removeOrganizationMember(input: $input) {
      clientMutationId
    }
  }
`);
