import { memo, useCallback, useMemo, useRef } from 'react';
import { ActionIcon, clsx, Flex, Text, Tooltip } from '@mantine/core';

import {
  highlightEventReference,
  useTimelineReferenceHighlightStore,
} from '@/pageAI/states/timelineReferenceHighlights';

import { addFileIndexToEventReference } from '@/pageAI/services/summaries';
import { getClientFiles } from '@/pageAI/services/clients';
import { TimelineEventSummary } from '../TimelineEventSummary';
import { ReadonlyTimelineEventProps } from './ReadonlyTimelineEvent.types';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { SummaryFileReferenceBadge } from '../../summaries/SummaryFileReferenceBadge';
import { takeNoteFromConditionEntry } from '@/pageAI/services/notes';

import IconNotePlus from '@/shared/icons/note-plus.svg?react';
import { getConditionChronologicalEventMetadata } from '@/pageAI/services/medicalConditions';

const ReadonlyTimelineEventBase = ({ event: rawEvent, keywords }: ReadonlyTimelineEventProps) => {
  const client = useSelectedClient(true);
  const isHighlighted = useTimelineReferenceHighlightStore((state) =>
    Boolean(state.highlightedEventMapping[rawEvent.id]),
  );

  const referenceBadgeRef = useRef<HTMLSpanElement>(null);
  const eventContainerRef = useRef<HTMLDivElement>(null);

  const { fileAssets } = useMemo(() => getClientFiles(client), [client]);

  const event = useMemo(
    () => ({ ...rawEvent, reference: addFileIndexToEventReference(rawEvent.reference, fileAssets) }),
    [rawEvent, fileAssets],
  );

  const handleHighlight = useCallback(() => {
    highlightEventReference(client.id, event.id);
  }, [client.id, event]);

  const handleClickEventSummary = useCallback(() => {
    referenceBadgeRef.current?.querySelector('a')?.click();
  }, []);

  const handleTakeNote = () => {
    const { condition, entry, entryKey, sectionField } = getConditionChronologicalEventMetadata(event);

    if (!condition || !entry) return;

    takeNoteFromConditionEntry({ client, condition, entry, entryKey, sectionField });
  };

  return (
    <Flex
      ref={eventContainerRef}
      direction="column"
      sx={(theme) => ({
        '.extra-action': {
          opacity: 0,
          transition: 'all 0.15s ease-in-out',
        },
        '&:hover': {
          '.extra-action': {
            opacity: 1,
            pointerEvents: 'auto',
          },
        },
      })}
    >
      <Text color="dark.4" fz="sm">
        <TimelineEventSummary event={event} onClick={handleClickEventSummary} keywords={keywords} />

        <Flex align="center" sx={{ display: 'inline-flex' }} gap={6} ml={4}>
          <SummaryFileReferenceBadge
            ref={referenceBadgeRef}
            client={client}
            reference={event.reference}
            referencedTexts={event.referencedText}
            isHighlighted={isHighlighted}
            onClick={handleHighlight}
            keywords={keywords}
          />

          <Tooltip key={event.id} label="Take note" withinPortal withArrow>
            <ActionIcon
              size="xs"
              color="gray.7"
              className={clsx('unprintable', 'extra-action')}
              onClick={handleTakeNote}
            >
              <IconNotePlus width={16} height={16} />
            </ActionIcon>
          </Tooltip>
        </Flex>
      </Text>
    </Flex>
  );
};

export const ReadonlyTimelineEvent = memo(ReadonlyTimelineEventBase);
