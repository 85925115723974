import React, { memo, useCallback, useEffect, useMemo, useRef, useState, useTransition } from 'react';
import { ActionIcon, Box, Flex, Popover, Text, Tooltip, Transition, clsx } from '@mantine/core';
import { useDisclosure, usePrevious } from '@mantine/hooks';
import { useContextMenu } from 'mantine-contextmenu';
import { IconChevronDown, IconChevronUp, IconMessage, IconPencil, IconShare } from '@tabler/icons-react';

import { CaseTimelineItemViewProps } from './CaseTimelineItemView.types';
import { timelineEventRecentlyUpdatedPubsub } from './CaseTimelineItemView.utils';
import { SummaryFileReferenceBadge } from '../SummaryFileReferenceBadge';
import { CaseTimelineItemVerificationLevel } from '../CaseTimelineItemVerificationLevel';
import { highlightTimelineEventPubsub } from '@/pageAI/services/caseTimeline';
import { CaseTimelineItemBookmarkButton } from '../CaseTimelineItemBookmarkButton';
import { CaseTimelineEditor, CaseTimelineEditorValues } from '../../timeline/edit/CaseTimelineEditor';
import { useUpdateTimelineEvent } from '@/pageAI/hooks/caseTimeline/useUpdateTimelineEvent';
import { TimelineEventCommentSection } from '../../comments/TimelineEventCommentSection';
import { useCaseTimelineContext } from '@/pageAI/contexts/caseTimelineContext';
import {
  highlightEventReference,
  useTimelineReferenceHighlightStore,
} from '@/pageAI/states/timelineReferenceHighlights';

import styles from './CaseTimelineItemView.module.scss';
import { addFileIndexToEventReference } from '@/pageAI/services/summaries';
import { getClientFiles } from '@/pageAI/services/clients';
import { FileAsset } from '@/shared/@types';
import { truthy } from '@/shared/utils/boolean';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { useLayoutStore } from '@/shared/states/layout';
import { TimelineEventExtraBox } from '../../timeline/TimelineEventExtraBox';
import { DetailsType } from '@/pageAI/gql/graphql';
import { TimelineEventPersistentExtraBoxes } from '../../timeline/TimelineEventPersistentExtraBoxes';
import { TimelineEventSummary } from '../../timeline/TimelineEventSummary';
import { useTimelineEventActionsStore } from '@/pageAI/states/timelineEventActions';
import { appendSearchParams } from '@/shared/utils/url';
import { AddOrRemoveEventFromConditionButton } from '../../medicalConditions/errorCorrection/AddOrRemoveEventFromConditionButton';
import { posthog } from '@/shared/plugins/posthog';
import { useNotesFeatureFlag } from '@/pageAI/hooks/featureFlags/useNotesFeatureFlag/useNotesFeatureFlag';

import IconNotePlus from '@/shared/icons/note-plus.svg?react';
import { TimelineEventTakeNoteButton } from '../../timeline/TimelineEventTakeNoteButton';
import { takeNoteFromTimelineEvent } from '@/pageAI/services/notes';

const CaseTimelineItemViewBase = ({ client, item: rawItem, keywords, hideDate = false }: CaseTimelineItemViewProps) => {
  const { notify } = useNotifications();
  const { enabled: isNotesFeatureEnabled } = useNotesFeatureFlag();

  const showContextMenu = useContextMenu();

  const [isPending, startTransition] = useTransition();
  const { tab, prefix, forceShowEventDetails, isPrinting, matchWholeWords, matchWholePhrase } =
    useCaseTimelineContext();
  const isHighlighted = useTimelineReferenceHighlightStore((state) =>
    Boolean(state.highlightedEventMapping[rawItem.id]),
  );
  const isEditable = useTimelineEventActionsStore((state) => state.isEditable);
  const shareFunctionOverride = useTimelineEventActionsStore((state) => state.shareFunctionOverride);

  const previousKeywords = usePrevious(keywords);
  const referenceBadgeRef = useRef<HTMLSpanElement>(null);
  const eventContainerRef = useRef<HTMLDivElement>(null);
  const [isAddingComment, setIsAddingComment] = useState(false);
  const [showDetails, setShowDetails] = useState(!!forceShowEventDetails);
  const [isRecentlyUpdated, setIsRecentlyUpdated] = useState(false);
  const [popoverYOffset, setPopoverYOffset] = useState(0);

  const { updateTimelineEventOptimistically } = useUpdateTimelineEvent();
  const [isEditing, { open: openEditing, close: closeEditing }] = useDisclosure();
  const { fileAssets } = useMemo(() => getClientFiles(client), [client]);

  const timelineEvent = useMemo(
    () => ({ ...rawItem, reference: addFileIndexToEventReference(rawItem.reference, fileAssets) }),
    [rawItem, fileAssets],
  );

  useEffect(() => {
    const unsubscribe = useLayoutStore.subscribe((state) => {
      if (state.isRightSidebarOpened || !isHighlighted) return;

      setTimeout(() => highlightTimelineEventPubsub.publish({ event: timelineEvent, hideHighlightEffect: true }));
    });

    return unsubscribe;
  }, [isHighlighted, timelineEvent]);

  useEffect(() => {
    let timeout: number | undefined = undefined;

    const highlightEvent = () => {
      setIsRecentlyUpdated(false);
      requestAnimationFrame(() => setIsRecentlyUpdated(true));

      if (timeout) clearTimeout(timeout);

      timeout = window.setTimeout(() => {
        setIsRecentlyUpdated(false);
      }, 2000);
    };

    const unsubscribe1 = timelineEventRecentlyUpdatedPubsub.subscribe(({ id }) => {
      if (id !== timelineEvent.id) return;

      highlightEvent();
    });

    const unsubscribe2 = highlightTimelineEventPubsub.subscribe(({ event, hideHighlightEffect }) => {
      if (event.id !== timelineEvent.id || hideHighlightEffect) return;

      highlightEvent();
    });

    return () => {
      unsubscribe1();
      unsubscribe2();
    };
  });

  const detailedInformation = timelineEvent.detailsByType[DetailsType.DetailedInformation];

  useEffect(() => {
    if (!detailedInformation || keywords === previousKeywords) return;

    if (keywords.some((keyword) => detailedInformation?.some((info) => info.includes(keyword.toLowerCase()))))
      return setShowDetails(true);

    setShowDetails(false);
  }, [detailedInformation, keywords, previousKeywords]);

  const handleHighlight = useCallback(() => {
    startTransition(() => {
      highlightEventReference(client.id, timelineEvent.id);
    });

    requestIdleCallback(() =>
      highlightTimelineEventPubsub.publish({ event: timelineEvent, hideHighlightEffect: true, prefix }),
    );
  }, [client.id, timelineEvent, prefix]);

  const handleClickEventSummary = useCallback(() => {
    referenceBadgeRef.current?.querySelector('a')?.click();
  }, []);

  const getPrintingHref = useCallback(
    (href: string, fileAsset?: FileAsset, pageIndex?: number) => {
      const searchParams = new URLSearchParams();

      searchParams.set('eventId', timelineEvent.id);
      if (fileAsset) searchParams.set('fileId', fileAsset.id);
      if (pageIndex) searchParams.set('page', String(pageIndex + 1));

      return [window.location.href.split('?')[0], searchParams.toString()].filter(truthy).join('?');
    },
    [timelineEvent.id],
  );

  const handleOpenEditing = useCallback(
    (isRightClick = false) => {
      openEditing();

      posthog.capture('[Timeline] Start editing event', {
        isRightClick,
        clientId: client.id,
        clientFirstName: client.firstName,
        clientLastName: client.lastName,
        eventId: timelineEvent.id,
        eventReference: timelineEvent.reference,
      });
    },
    [openEditing, client, timelineEvent],
  );

  const handleContextMenu = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      return showContextMenu(
        [
          isEditable
            ? {
                key: 'edit',
                icon: <IconPencil size={16} />,
                title: 'Edit',
                onClick: (event: React.MouseEvent) => {
                  const { top } = eventContainerRef.current?.getBoundingClientRect() || { top: 0 };

                  setPopoverYOffset(event.clientY - top);

                  handleOpenEditing(true);
                },
              }
            : null,
          {
            key: 'take-note',
            icon: <IconNotePlus width={16} height={16} />,
            title: 'Take note',
            onClick: () => takeNoteFromTimelineEvent({ client, event: timelineEvent }),
          },
          {
            key: 'share',
            icon: <IconShare size={16} />,
            title: 'Share',
            onClick: () => {
              posthog.capture('[Timeline] Share event', {
                clientId: client.id,
                clientFirstName: client.firstName,
                clientLastName: client.lastName,
                eventId: timelineEvent.id,
                eventReference: timelineEvent.reference,
              });

              if (shareFunctionOverride) {
                shareFunctionOverride(timelineEvent);

                return;
              } else {
                const searchParams = new URLSearchParams(window.location.search);

                searchParams.set('eventId', timelineEvent.id);

                navigator.clipboard.writeText(appendSearchParams(window.location.href, searchParams));

                notify('Copied', 'Event link copied', 'brand');
              }
            },
          },
        ].filter(truthy),
        { sx: { minWidth: 164 } },
      )(event);
    },
    [showContextMenu, timelineEvent, notify, isEditable, shareFunctionOverride, client, handleOpenEditing],
  );

  const handleSubmit = useCallback(
    (data: CaseTimelineEditorValues) => {
      updateTimelineEventOptimistically(client, { ...timelineEvent, ...data });
    },
    [updateTimelineEventOptimistically, client, timelineEvent],
  );

  const toggleAddingComment = useCallback(() => setIsAddingComment(!isAddingComment), [isAddingComment]);

  const content = (
    <Flex
      ref={eventContainerRef}
      className={clsx('event-content', isRecentlyUpdated ? styles.flash : undefined)}
      direction="column"
      onContextMenu={handleContextMenu}
      sx={(theme) => ({
        '&:hover': {
          '.extra-action': {
            opacity: 1,
            pointerEvents: 'auto',
          },
        },
      })}
    >
      <Text
        color="dark.4"
        fz="sm"
        sx={(theme) => ({
          display: 'block',
          ...(isEditing
            ? {
                padding: 4,
                borderRadius: 6,
                border: `1.5px solid ${theme.colors.brand[1]}`,
                backgroundColor: theme.colors.gray[1],
              }
            : {}),
        })}
        component="span"
      >
        {!hideDate && (
          <>
            <Text component="span" fw={600} sx={{ display: 'inline' }}>
              {timelineEvent.formattedDate}
            </Text>
            &nbsp;–&nbsp;
          </>
        )}

        <TimelineEventSummary
          event={timelineEvent}
          onClick={handleClickEventSummary}
          keywords={keywords}
          matchWholePhrase={matchWholePhrase}
          matchWholeWords={matchWholeWords}
        />

        <Flex align="center" sx={{ display: 'inline-flex' }} gap={6} ml={4}>
          <SummaryFileReferenceBadge
            ref={referenceBadgeRef}
            client={client}
            reference={timelineEvent.reference}
            referencedTexts={timelineEvent.referencedText}
            isHighlighted={isHighlighted}
            isPrinting={isPrinting}
            onClick={handleHighlight}
            getPrintingHref={getPrintingHref}
            keywords={keywords}
            matchWholePhrase={matchWholePhrase}
            matchWholeWords={matchWholeWords}
          />

          <CaseTimelineItemVerificationLevel client={client} item={timelineEvent} />

          {!!detailedInformation && (
            <ActionIcon
              size="xs"
              display="inline-flex"
              color="gray.8"
              onClick={() => setShowDetails(!showDetails)}
              className="unprintable"
            >
              {showDetails ? <IconChevronUp size={16} /> : <IconChevronDown size={16} />}
            </ActionIcon>
          )}

          <Flex align="center" gap={4}>
            <CaseTimelineItemBookmarkButton
              client={client}
              item={timelineEvent}
              isRecentlyUpdated={isRecentlyUpdated}
            />

            {isNotesFeatureEnabled ? (
              <TimelineEventTakeNoteButton event={timelineEvent} className={tab === 'All' ? 'extra-action' : ''} />
            ) : (
              <Tooltip key={timelineEvent.id} label="Comment" withinPortal withArrow>
                <ActionIcon
                  size="xs"
                  color="gray.7"
                  className={clsx(tab === 'All' ? 'extra-action' : '', 'unprintable')}
                  onClick={toggleAddingComment}
                >
                  <IconMessage size={16} />
                </ActionIcon>
              </Tooltip>
            )}

            <Tooltip key={timelineEvent.id} label="Edit" withinPortal withArrow>
              <ActionIcon
                data-testid="edit-event"
                size="xs"
                color="gray.7"
                className={clsx(tab === 'All' ? 'extra-action' : '', 'unprintable')}
                onClick={openEditing}
              >
                <IconPencil size={16} />
              </ActionIcon>
            </Tooltip>

            <AddOrRemoveEventFromConditionButton event={timelineEvent} tab={tab} />
          </Flex>
        </Flex>
      </Text>

      <TimelineEventPersistentExtraBoxes
        event={timelineEvent}
        isPrinting={isPrinting}
        keywords={keywords}
        matchWholePhrase={matchWholePhrase}
        matchWholeWords={matchWholeWords}
      />

      <Transition
        mounted={!!detailedInformation && (forceShowEventDetails || showDetails)}
        transition="fade"
        duration={200}
        timingFunction="ease"
      >
        {(styles) => (
          <Box style={styles} mt={8} mb={4}>
            <TimelineEventExtraBox
              event={timelineEvent}
              detailsType={DetailsType.DetailedInformation}
              isPrinting={isPrinting}
              keywords={keywords}
              matchWholePhrase={matchWholePhrase}
              matchWholeWords={matchWholeWords}
            />
          </Box>
        )}
      </Transition>
    </Flex>
  );

  return (
    <Flex direction="column" pos="relative">
      <Popover opened={isEditing} onClose={closeEditing} position="bottom-start" withinPortal shadow="sm">
        <Popover.Target>
          <Flex pos="absolute" top={-8} left={0} right={0} />
        </Popover.Target>

        <Popover.Dropdown p={0}>
          <CaseTimelineEditor item={timelineEvent} onClose={closeEditing} onSubmit={handleSubmit} />
        </Popover.Dropdown>
      </Popover>

      {content}

      {!isNotesFeatureEnabled && (
        <TimelineEventCommentSection
          opened={isAddingComment}
          client={client}
          event={timelineEvent}
          onSubmit={() => setIsAddingComment(false)}
          onReply={toggleAddingComment}
        />
      )}
    </Flex>
  );
};

export const CaseTimelineItemView = memo(CaseTimelineItemViewBase);
