import { forwardRef, memo, useCallback } from 'react';
import { Badge, Box, Flex, Text, Tooltip } from '@mantine/core';
import { LinkIcon } from '@heroicons/react/16/solid';

import { MarkdownRenderer } from '@/shared/components/common/markdown/MarkdownRenderer';
import { useClientFileAnchorReference } from '@/pageAI/hooks/files/useClientFileAnchorReference';
import {
  extractFileInfoOfClientFromHref,
  extractPageNumbersFromReference,
  getPageDisplayTextFromReference,
  normalizeCaseTimelineItemReferencedTexts,
} from '@/pageAI/services/summaries';
import { setHighlightedTexts, setPagesToHighlight } from '@/shared/states/filePreview';
import { fillNumberArray } from '@/shared/utils/array';
import { SummaryFileReferenceBadgeProps } from './SummaryFileReferenceBadge.types';
import { useSummaryFileReferenceBadgeState } from './SummaryFileReferenceBadge.utils';
import { MarkdownAnchorRenderer } from '@/shared/components/common/markdown/MarkdownAnchorRenderer';
import { useLayoutStore } from '@/shared/states/layout';

const SummaryFileReferenceBadgeBase = (
  {
    client,
    reference,
    referencedTexts,
    isHighlighted = false,
    isPrinting = false,
    getPrintingHref,
    onClick,
    keywords = [],
    matchWholePhrase,
    matchWholeWords,
    alwaysFullDisplay = false,
  }: SummaryFileReferenceBadgeProps,
  ref: React.ForwardedRef<HTMLSpanElement>,
) => {
  const { handleOpenFileReferenceByHref } = useClientFileAnchorReference(client);
  const isHrefActive = useSummaryFileReferenceBadgeState((state) => state.isHrefActive);
  const printPageNumberMapping = useSummaryFileReferenceBadgeState((state) => state.printPageNumberMapping);
  const isRightSidebarOpened = useLayoutStore((state) => state.isRightSidebarOpened);

  const openFileWithReference = useCallback(
    (href: string) => {
      const normalizedReferencedTexts = normalizeCaseTimelineItemReferencedTexts(referencedTexts || []);

      const { startPage, endPage } = extractPageNumbersFromReference(reference);

      setHighlightedTexts(normalizedReferencedTexts);

      if (!Number.isNaN(startPage) || !Number.isNaN(endPage)) {
        let pageIndicesToHighlight: number[] = [];

        if (!Number.isNaN(startPage) && !Number.isNaN(endPage))
          pageIndicesToHighlight = fillNumberArray(startPage - 1, endPage - 1);
        else if (Number.isNaN(endPage)) pageIndicesToHighlight = [startPage - 1];

        setPagesToHighlight(pageIndicesToHighlight);
      }

      return handleOpenFileReferenceByHref(href);
    },
    [reference, referencedTexts, handleOpenFileReferenceByHref],
  );

  const ReferenceAnchorRenderer = ({ href, children }: { href?: string; children?: React.ReactNode }) => {
    if (!href) return null;

    const handleClick = (event: React.MouseEvent) => {
      if (!href) return;

      event.preventDefault();

      if (openFileWithReference(href)) onClick?.();
    };

    const { fileAsset, pageIndex } = isPrinting
      ? extractFileInfoOfClientFromHref(client, href)
      : { fileAsset: undefined, pageIndex: undefined };

    const actualHref = isPrinting ? getPrintingHref?.(href, fileAsset, pageIndex) || window.location.href : href;

    let textToDisplay = children;

    if (isPrinting) {
      const pageAIMetadata = fileAsset?.metadata?.pageAIMetadata;

      const pageNumberOffset = fileAsset?.id ? printPageNumberMapping?.[fileAsset.id] || 1 : 1;

      textToDisplay = `${pageAIMetadata?.vaFileTypeName}, ${getPageDisplayTextFromReference(
        reference,
        pageNumberOffset,
      )} [${pageAIMetadata?.indexNumber}]`;
    }

    if ((!isHrefActive || printPageNumberMapping) && isPrinting)
      return (
        <Text className="reference-badge" fz="0.75rem" color="dark">
          {textToDisplay}
        </Text>
      );

    const anchor = (
      <MarkdownAnchorRenderer
        href={actualHref}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleClick}
        color="blue.8"
        className="reference-badge"
        sx={{
          display: 'flex',
          gap: 4,
          alignItems: 'center',
        }}
      >
        <Flex
          align="center"
          justify="center"
          sx={{ minHeight: 10, height: 10, minWidth: 14, width: 14 }}
          pos="relative"
        >
          <LinkIcon width={14} height={14} style={{ position: 'absolute', left: 0 }} />
        </Flex>

        <Text
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {!isRightSidebarOpened || alwaysFullDisplay
            ? textToDisplay
            : String(textToDisplay).slice(0, 20).concat('...')}
        </Text>
      </MarkdownAnchorRenderer>
    );

    if (!isRightSidebarOpened) return anchor;

    return (
      <Tooltip label={textToDisplay} openDelay={500} withArrow>
        <Box>{anchor}</Box>
      </Tooltip>
    );
  };

  return (
    <Badge
      ref={ref}
      component="span"
      {...(isHighlighted && { 'data-active': true })}
      sx={(theme) => ({
        '> p': { display: 'inline' },
        fontWeight: 500,
        textTransform: 'none',
        ...(isPrinting
          ? {
              whiteSpace: 'nowrap',
              border: `1px solid ${theme.colors.dark[2]}`,
              width: 'fit-content',
            }
          : {}),
        backgroundColor:
          isHighlighted && !isPrinting ? theme.colors.yellow[3] : `${theme.fn.lighten(theme.colors.blue[0], 0.5)}`,
      })}
    >
      <MarkdownRenderer
        content={reference}
        components={{ a: ReferenceAnchorRenderer }}
        highlights={keywords}
        matchWholePhrase={matchWholePhrase}
        matchWholeWords={matchWholeWords}
      />
    </Badge>
  );
};

export const SummaryFileReferenceBadge = memo(forwardRef(SummaryFileReferenceBadgeBase));
