import { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';

import { dismissHintsForConditionMutation, undoDismissHintsForConditionMutation } from '@/pageAI/api/conditions';
import {
  DismissHintsForConditionInput,
  DismissHintsForConditionMutation,
  UndoDismissHintsForConditionInput,
  UndoDismissHintsForConditionMutation,
} from '@/pageAI/gql/graphql';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { useUpdateCondition } from '../useUpdateCondition';
import { showAsyncNotification } from '@/shared/services/notifications';
import { useSelectedClient } from '../../clients/useSelectedClient';

export const useDismissHintsForCondition = () => {
  const { request } = useGraphQLRequest();
  const client = useSelectedClient(true);
  const { updateConditionLocally } = useUpdateCondition(client.id);

  const { mutateAsync: dismissHintsForConditionBase, isLoading: isDismissing } = useMutation<
    DismissHintsForConditionMutation,
    Error,
    DismissHintsForConditionInput
  >({
    mutationFn: async (input) => {
      const responseBody = await request(dismissHintsForConditionMutation, { input });

      return responseBody;
    },
  });

  const dismissHintsForCondition = useCallback(
    async (input: DismissHintsForConditionInput) => {
      const { onSuccess, onError } = showAsyncNotification(
        'Executing...',
        `The missing docs hints are being dismissed for the condition.`,
      );

      try {
        const responseBody = await dismissHintsForConditionBase(input);

        updateConditionLocally(input.conditionId, responseBody.dismissHintsForCondition);

        onSuccess('Success', `The missing docs hints have been dismissed for the condition.`);
      } catch (error) {
        console.error(error);

        onError('Error', 'The missing docs hints could not be dismissed.');
      }
    },
    [dismissHintsForConditionBase, updateConditionLocally],
  );

  const { mutateAsync: undoDismissHintsForConditionBase, isLoading: isUndoDismissing } = useMutation<
    UndoDismissHintsForConditionMutation,
    Error,
    UndoDismissHintsForConditionInput
  >({
    mutationFn: async (input) => {
      const responseBody = await request(undoDismissHintsForConditionMutation, { input });

      return responseBody;
    },
  });

  const undoDismissHintsForCondition = useCallback(
    async (input: UndoDismissHintsForConditionInput) => {
      const { onSuccess, onError } = showAsyncNotification(
        'Executing...',
        `The missing docs hints are being restored for the condition.`,
      );

      try {
        const responseBody = await undoDismissHintsForConditionBase(input);

        updateConditionLocally(input.conditionId, responseBody.undoDismissHintsForCondition);

        onSuccess('Success', `The missing docs hints have been restored for the condition.`);
      } catch (error) {
        console.error(error);

        onError('Error', 'The missing docs hints could not be restored.');
      }
    },
    [undoDismissHintsForConditionBase, updateConditionLocally],
  );

  return {
    dismissHintsForCondition,
    undoDismissHintsForCondition,
    isDismissing,
    isUndoDismissing,
  };
};
