import { memo, useLayoutEffect, useRef, useState } from 'react';
import { ActionIcon, Flex, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconNotes } from '@tabler/icons-react';
import { useParams } from 'react-router-dom';

import { NoteListContainer } from '../NoteListContainer';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { useNotes } from '@/pageAI/hooks/notes/useNotes';
import { useMouseDragging } from '@/shared/hooks/mouse/useMouseDragging';
import { useNotesStore } from '@/pageAI/states/notes';
import { posthog } from '@/shared/plugins/posthog';
import { useNotesFeatureFlag } from '@/pageAI/hooks/featureFlags/useNotesFeatureFlag/useNotesFeatureFlag';

interface NoteListButtonProps {}

const NoteListButtonBase = (props: NoteListButtonProps) => {
  const { enabled } = useNotesFeatureFlag();

  const { clientId } = useParams();
  const client = useSelectedClient();
  const { notes, isLoading } = useNotes({ clientId: client?.id });
  const isFullscreen = useNotesStore((state) => state.isFullscreen);

  const [opened, { open, close, toggle }] = useDisclosure();
  const [x, setX] = useState(48);
  const [y, setY] = useState(48);
  const ref = useRef<HTMLButtonElement>(null);

  const { isDragging, onMouseDown } = useMouseDragging({ x, y, setX, setY, disabled: isFullscreen });

  const handleToggle = () => {
    posthog.capture('[Notes] Open notepad', {
      clientId: client?.id,
      clientFirstName: client?.firstName,
      clientLastName: client?.lastName,
    });

    toggle();
  };

  useLayoutEffect(() => {
    if (!ref.current) return;

    const { x, y, height } = ref.current.getBoundingClientRect();

    setX(x - 522 / 2);
    setY(y + height + 8);
  }, []);

  if (!enabled || !client || !clientId || client.id !== clientId) return null;

  return (
    <>
      <Flex align="center" justify="center" pos="relative">
        <Tooltip label="Open notepad" withArrow withinPortal>
          <ActionIcon ml={4} ref={ref} variant={opened ? 'filled' : 'outline'} color="dark.4" onClick={handleToggle}>
            <IconNotes size={16} />
          </ActionIcon>
        </Tooltip>

        <Flex
          sx={(theme) => ({
            position: 'absolute',
            background: theme.colors.red[6],
            borderRadius: 24,
            padding: '2px 4px',
            top: -6,
            right: -12,
          })}
        >
          <Text color="white" fz="6px" fw={700} sx={{ userSelect: 'none' }}>
            NEW
          </Text>
        </Flex>
      </Flex>

      <Flex
        align="center"
        justify="center"
        sx={{
          position: 'fixed',
          left: x,
          top: y,
          zIndex: 200,
          display: opened ? 'flex' : 'none',
        }}
        onMouseDown={onMouseDown}
      >
        <NoteListContainer
          notes={notes || []}
          x={x}
          y={y}
          setX={setX}
          setY={setY}
          onClose={close}
          onOpen={open}
          isDragging={isDragging}
        />
      </Flex>
    </>
  );
};

export const NoteListButton = memo(NoteListButtonBase);
