import { memo } from 'react';
import { ActionIcon, Button, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconArchive } from '@tabler/icons-react';

import { DeleteClientButtonProps } from './DeleteClientButton.types';
import { DeleteClientModal } from '../DeleteClientModal';

const DeleteClientButtonBase = ({ client, color, compact = true }: DeleteClientButtonProps) => {
  const [opened, { open, close }] = useDisclosure();

  const handleClickDelete = (event: React.MouseEvent) => {
    event.stopPropagation();

    open();
  };

  if (!client.viewerCanArchive) return null;

  return (
    <>
      {compact ? (
        <Tooltip label="Archive client" withArrow withinPortal>
          <ActionIcon onClick={handleClickDelete} color={color} data-testid="archive-client">
            <IconArchive size={16} />
          </ActionIcon>
        </Tooltip>
      ) : (
        <Button
          color="red"
          leftIcon={<IconArchive size={16} />}
          variant="subtle"
          size="xs"
          onClick={handleClickDelete}
          data-testid="archive-client"
        >
          Archive
        </Button>
      )}

      <DeleteClientModal opened={opened} client={client} onClose={close} />
    </>
  );
};

export const DeleteClientButton = memo(DeleteClientButtonBase);
