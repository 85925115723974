import { Fragment, memo } from 'react';
import { Link } from 'react-router-dom';
import { Anchor, Flex, Skeleton, Table, Text } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';

import { useUsageHistory } from '@/pageAI/hooks/usage/useUsageHistory';
import { Organization } from '@/pageAI/@types';
import { UsageItem } from '@/pageAI/@types/usage';
import { formatDateTime } from '@/shared/utils/date';
import { getClientDetailsUrl, getClientDisplayName } from '@/pageAI/services/clients';
import { useUsageStore } from '@/pageAI/states/usage';
import { snakeCaseToDisplayText } from '@/shared/utils/string';

interface UsageHistoryProps {
  organization: Organization;
}

const USAGE_TYPE_LABEL_MAPPING: Record<string, string> = {
  USAGE: 'Summary generation',
  PURCHASE: 'Purchase',
};

const UsageHistoryBase = ({ organization }: UsageHistoryProps) => {
  const from = useUsageStore((state) => state.from);
  const to = useUsageStore((state) => state.to);
  const { usageHistory, isLoading, isError } = useUsageHistory({ organizationId: organization.id, from, to });

  const renderLoadingRows = () => {
    return (
      <Fragment>
        {Array.from({ length: 5 }).map((_, index) => (
          <tr key={index}>
            <td>
              <Skeleton h={24} />
            </td>
            <td>
              <Skeleton h={24} />
            </td>
            <td>
              <Skeleton h={24} />
            </td>
            <td>
              <Skeleton h={24} />
            </td>
          </tr>
        ))}
      </Fragment>
    );
  };

  const renderAmountText = (amount: number) => {
    return (
      <Text fw={500} color={amount > 0 ? 'teal.6' : 'red.6'}>
        {amount > 0 ? `+${amount}` : amount}
      </Text>
    );
  };

  const renderUsageRow = (usageItem: UsageItem) => {
    if (usageItem.type === 'USAGE') {
      return (
        <tr>
          <td>{formatDateTime(usageItem.createdAt)}</td>
          <td>Summary generation</td>
          <td>
            <Flex>
              Credits used for summary generation
              {usageItem.client && (
                <>
                  &nbsp;(Client:&nbsp;
                  <Anchor
                    to={getClientDetailsUrl(usageItem.client.id)}
                    component={Link}
                    sx={{ textDecoration: 'underline' }}
                    fw={600}
                    color="dark"
                  >
                    {getClientDisplayName(usageItem.client)}
                  </Anchor>
                  )
                </>
              )}
            </Flex>
          </td>
          <td>{renderAmountText(usageItem.amount)}</td>
        </tr>
      );
    }

    if (usageItem.type === 'PURCHASE') {
      return (
        <tr>
          <td>{formatDateTime(usageItem.createdAt)}</td>
          <td>{USAGE_TYPE_LABEL_MAPPING[usageItem.type]}</td>
          <td>
            Purchased a credit bundle.
            {usageItem.order?.invoice?.hostedUrl && (
              <>
                &nbsp;(Invoice:&nbsp;
                <Anchor
                  href={usageItem.order.invoice?.hostedUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ textDecoration: 'underline' }}
                  fw={600}
                  color="dark"
                >
                  {usageItem.order.invoice?.number}
                </Anchor>
                )
              </>
            )}
          </td>
          <td>{renderAmountText(usageItem.amount)}</td>
        </tr>
      );
    }

    return (
      <tr>
        <td>{formatDateTime(usageItem.createdAt)}</td>
        <td>{USAGE_TYPE_LABEL_MAPPING[usageItem.type] || snakeCaseToDisplayText(usageItem.type)}</td>
        <td>{usageItem.description}</td>
        <td>{renderAmountText(usageItem.amount)}</td>
      </tr>
    );
  };

  const renderUsageTableBody = () => {
    if (isLoading) return <tbody>{renderLoadingRows()}</tbody>;

    if (isError) {
      return (
        <tbody>
          <Flex align="center" gap={4} p="xs" sx={(theme) => ({ color: theme.colors.red[6] })}>
            <IconAlertCircle size={16} />

            <Text fz="0.875rem" fw={500}>
              Failed to load your organization&apos;s usage history.
            </Text>
          </Flex>
        </tbody>
      );
    }

    if (!usageHistory?.length) {
      return (
        <tbody>
          <Flex direction="column" justify="center" p="xs">
            <Text fz="0.875rem" color="gray.7">
              Your organization has no usage history within the selected period.
            </Text>
          </Flex>
        </tbody>
      );
    }

    return (
      <tbody>
        {usageHistory.map((usageItem) => {
          return <Fragment key={usageItem.id}>{renderUsageRow(usageItem)}</Fragment>;
        })}
      </tbody>
    );
  };

  const usageTable = (
    <Table verticalSpacing="sm">
      <thead>
        <tr>
          <th>Date</th>
          <th>Action</th>
          <th>Description</th>
          <th>Credits</th>
        </tr>
      </thead>

      {renderUsageTableBody()}
    </Table>
  );

  return (
    <Flex direction="column" gap="sm">
      <Flex gap="xs" align="center" justify="space-between">
        <Text fw={600} color="dark.4" sx={{ whiteSpace: 'nowrap' }}>
          Usage History
        </Text>
      </Flex>

      {usageTable}
    </Flex>
  );
};

export const UsageHistory = memo(UsageHistoryBase);
