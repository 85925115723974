import { DocHintType } from '@/pageAI/gql/graphql';

export const DOC_HINT_TYPE_TEXT_MAPPING = {
  [DocHintType.OriginalClaim]: 'Original Claim',
  [DocHintType.BvaNod]: 'BVA Disagreement Notice(s)',
  [DocHintType.Form_9]: 'VA Form 9',
  [DocHintType.Nod]: 'Disagreement Notice(s)',
};

export const getDocHintTypeFromEntryKey = (entryKey: string): DocHintType | null => {
  if (entryKey.includes('OriginalClaim')) return DocHintType.OriginalClaim;

  if (entryKey.includes('vaForm9')) return DocHintType.Form_9;

  if (entryKey.includes('bvaDisagreementNotices')) return DocHintType.BvaNod;

  if (entryKey.includes('disagreementNotices')) return DocHintType.Nod;

  return null;
};
