import { useQuery } from '@tanstack/react-query';

import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { notesQuery } from '@/pageAI/api';
import { GraphQLRequestFunction } from '@/shared/lib/graphql';
import { NotesQuery } from '@/pageAI/gql/graphql';
import { truthy } from '@/shared/utils/boolean';

export const normalizeNotesQuery = (notesQuery: NotesQuery) => {
  return notesQuery.client.notes.nodes.filter(truthy);
};

export const queryMap = {
  client: {
    notes: {
      queryKey: (clientId?: string) => ['client-notes', clientId],
      queryFn: async (request: GraphQLRequestFunction, clientId: string | undefined) => {
        if (typeof clientId !== 'string') return Promise.resolve(null);

        const responseBody = await request(notesQuery, { id: clientId });

        const notes = normalizeNotesQuery(responseBody);

        return notes;
      },
    },
  },
};

export type ClientNotesQuery = Awaited<ReturnType<typeof queryMap.client.notes.queryFn>>;

export interface UseNotesProps {
  clientId: string | undefined;
}

export const useNotes = ({ clientId }: UseNotesProps) => {
  const { request } = useGraphQLRequest();

  const { data, isLoading, isError } = useQuery<ClientNotesQuery>(queryMap.client.notes.queryKey(clientId), () =>
    queryMap.client.notes.queryFn(request, clientId),
  );

  return { notes: data, isLoading, isError };
};
