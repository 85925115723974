import { useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { availableConditionKeywordsQuery, conditionSearchTermsQuery } from '@/pageAI/api/conditions';
import { SimpleCondition } from '@/pageAI/@types/summaries';
import {
  getConditionCode,
  setConditionTermScore,
  setConditionTermFetchStatus,
} from '@/pageAI/services/conditionSearchTerms';
import { useSelectedClient } from '../../clients/useSelectedClient';
import { FetchStatus } from '@/shared/@types';

export enum ConditionTermCategory {
  TERMS_FROM_CFR = 'terms_from_cfr',
  CONDITION_NAMES = 'condition_names',
  SYMPTOMPS_AND_RELATED_TERMS = 'symptomps_and_related_terms',
}

export const CONDITION_TERM_CATEGORY_LABEL_MAPPING = {
  [ConditionTermCategory.TERMS_FROM_CFR]: 'Terms from CFR',
  [ConditionTermCategory.CONDITION_NAMES]: 'Condition names',
  [ConditionTermCategory.SYMPTOMPS_AND_RELATED_TERMS]: 'Symptoms and related terms',
};

const useAvailableConditionTerms = () => {
  const { request } = useGraphQLRequest();
  const queryClient = useQueryClient();
  const client = useSelectedClient(true);
  const clientId = client.id;

  const fetchAvailableConditionTerms = useCallback(
    async (keywords: string[]) => {
      setConditionTermFetchStatus(FetchStatus.LOADING);

      try {
        const { availableKeywords } = await queryClient.fetchQuery(
          ['available-condition-terms', clientId, keywords],
          () => {
            return request(availableConditionKeywordsQuery, { clientIds: [clientId], keywords });
          },
        );

        setConditionTermFetchStatus(FetchStatus.SUCCESS);

        availableKeywords.forEach(({ keyword, score }) => {
          setConditionTermScore(keyword, score);
        });
      } catch (error) {
        setConditionTermFetchStatus(FetchStatus.ERROR);

        throw error;
      }
    },
    [queryClient, request, clientId],
  );

  return { fetchAvailableConditionTerms };
};

export const useConditionSearchTerms = () => {
  const { request } = useGraphQLRequest();
  const client = useSelectedClient(true);
  const { fetchAvailableConditionTerms } = useAvailableConditionTerms();

  const { data, isLoading, isError } = useQuery(['client-condition-search-terms', client.id], () => {
    return request(conditionSearchTermsQuery, { id: client.id });
  });

  const getConditionSearchTerms = useCallback(
    (condition: SimpleCondition) => {
      const conditionTermObject = data?.client?.searchTerms?.find((term) => term.code === getConditionCode(condition));

      const categorizedSearchTermMapping: Record<ConditionTermCategory, string[]> = {
        [ConditionTermCategory.TERMS_FROM_CFR]: [],
        [ConditionTermCategory.CONDITION_NAMES]: [],
        [ConditionTermCategory.SYMPTOMPS_AND_RELATED_TERMS]: [],
      };

      conditionTermObject?.termsFromDiagnostic.forEach((term) => {
        if (categorizedSearchTermMapping[ConditionTermCategory.TERMS_FROM_CFR].includes(term)) return;

        categorizedSearchTermMapping[ConditionTermCategory.TERMS_FROM_CFR].push(term);
      });

      categorizedSearchTermMapping[ConditionTermCategory.CONDITION_NAMES].push(condition.headerCondition);

      const isDuplicated = (term: string) => {
        return Object.values(categorizedSearchTermMapping)
          .flat()
          .some((searchTerm) => searchTerm.toLowerCase() === term.toLowerCase());
      };

      condition.subConditions.forEach((subCondition) => {
        if (isDuplicated(subCondition)) return;

        categorizedSearchTermMapping[ConditionTermCategory.CONDITION_NAMES].push(subCondition);
      });

      conditionTermObject?.abbreviations.forEach((abbreviation) => {
        if (isDuplicated(abbreviation)) return;

        categorizedSearchTermMapping[ConditionTermCategory.SYMPTOMPS_AND_RELATED_TERMS].push(abbreviation);
      });

      conditionTermObject?.terms.forEach((searchTerm) => {
        if (isDuplicated(searchTerm)) return;

        categorizedSearchTermMapping[ConditionTermCategory.SYMPTOMPS_AND_RELATED_TERMS].push(searchTerm);
      });

      const conditionSearchTerms = Object.values(categorizedSearchTermMapping).flat();

      fetchAvailableConditionTerms(conditionSearchTerms);

      return { conditionSearchTerms, categorizedSearchTermMapping };
    },
    [data, fetchAvailableConditionTerms],
  );

  return { getConditionSearchTerms, isLoading, isError };
};
