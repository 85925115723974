import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Flex, LoadingOverlay, Table, Text, useMantineTheme } from '@mantine/core';
import { IconChevronDown, IconChevronUp, IconSelector } from '@tabler/icons-react';
import { useInView } from 'react-intersection-observer';

import { AssetTableProps } from './AssetTable.types';
import { AssetType } from '@/shared/@types';
import { getFileDisplayStatus } from '@/shared/services/files';
import { sortAlphabetically } from '@/shared/utils/string';
import { sortDate } from '@/shared/utils/date';
import { FileTableRow } from '../FileTableRow';
import { useFileIdSearchParams } from './AssetTable.utils';
import { useTableRowNavigation } from '@/shared/hooks/tables/useTableRowNavigation';
import { saveFileIndexLoadedCount } from '@/pageAI/states/unified';

const headers = ['#', 'File Type', 'Received Date', 'Size', 'Status', ''];

const AssetTableBase: React.FC<AssetTableProps> = ({
  fileAssets,
  loading = false,
  placeholderText = 'There are no files. Please upload some files.',
  loadingPlaceholderHeight = 240,
  headerOverrides,
  colgroup,
  sx,
  trStyle,
  hiddenColumns,
  initialLoadCount,
  renderActionCell,
  onRowClick,
}) => {
  const theme = useMantineTheme();
  const [infiniteScrollRef, inView] = useInView();

  const tableRef = useRef<HTMLTableElement | null>(null);
  const [sortedColumnName, setSortedColumnName] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | null>(null);
  const [currentLoad, setCurrentLoad] = useState(initialLoadCount ?? fileAssets.length);

  useEffect(() => {
    if (!inView || typeof initialLoadCount === 'undefined') return;

    setCurrentLoad((prev) => {
      const newLoadCount = prev + initialLoadCount;

      saveFileIndexLoadedCount(newLoadCount);

      return newLoadCount;
    });
  }, [inView, initialLoadCount]);

  const sortedAssetItems = useMemo(() => {
    let sorted = [...fileAssets].sort((assetA, assetB) => {
      if (!sortedColumnName || sortedColumnName === 'File Type') {
        return sortAlphabetically(assetA.name, assetB.name);
      }

      if (sortedColumnName === '#') {
        return sortAlphabetically(assetA.index || '', assetB.index || '');
      }

      if (sortedColumnName === 'Received Date') {
        return sortDate(assetA.fileReceiveDate || new Date(), assetB.fileReceiveDate || new Date());
      }

      if (sortedColumnName === 'Size') {
        if (assetA.type === AssetType.FILE && assetB.type === AssetType.FILE) {
          if (typeof assetA.metadata?.totalPages === 'number' && typeof assetB.metadata?.totalPages === 'number') {
            return assetA.metadata.totalPages - assetB.metadata.totalPages;
          }
        }

        const sizeA = assetA.size || 0;
        const sizeB = assetB.size || 0;

        return sizeA - sizeB;
      }

      if (sortedColumnName === 'Status') {
        const displayStatusA = getFileDisplayStatus(assetA);
        const displayStatusB = getFileDisplayStatus(assetB);

        return sortAlphabetically(displayStatusA, displayStatusB);
      }

      return 0;
    });

    if (sortOrder === 'asc') sorted = sorted.reverse();

    return sorted;
  }, [fileAssets, sortedColumnName, sortOrder]);

  const visibleItems = useMemo(
    () => (currentLoad > 0 ? sortedAssetItems.slice(0, currentLoad) : sortedAssetItems),
    [sortedAssetItems, currentLoad],
  );

  useTableRowNavigation(tableRef, visibleItems.length);

  useFileIdSearchParams(sortedAssetItems, currentLoad, setCurrentLoad);

  const handleSortColumn = (columnName: string) => () => {
    setSortedColumnName(columnName);

    if (sortOrder === null || sortedColumnName !== columnName) return setSortOrder('desc');

    if (sortOrder === 'desc') return setSortOrder('asc');

    if (sortOrder === 'asc') {
      setSortedColumnName(null);

      return setSortOrder(null);
    }
  };

  const renderTableHeader = () => {
    return (
      <thead>
        <tr
          className="asset-table-header-row"
          style={{
            position: 'sticky',
            top: 59,
            background: 'white',
            outline: `1px solid ${theme.colors.gray[2]}`,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            zIndex: 3,
          }}
        >
          {headers.map((header, headerIndex) => {
            if (hiddenColumns?.includes(header)) return null;

            return (
              <th key={header} onClick={handleSortColumn(header)}>
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 4,
                    ...(headerIndex === headers.length - 1
                      ? {
                          justifyContent: 'flex-end',
                        }
                      : {}),
                    ...(header === 'Received Date'
                      ? { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }
                      : {}),
                  }}
                >
                  {headerOverrides?.[headerIndex] || header}

                  {headerIndex !== headers.length - 1 && (
                    <>
                      {sortedColumnName === header ? (
                        <>{sortOrder === 'asc' ? <IconChevronUp size={14} /> : <IconChevronDown size={14} />}</>
                      ) : (
                        <IconSelector size={14} />
                      )}
                    </>
                  )}
                </span>
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  const renderTableBody = () => {
    if (loading)
      return (
        <tbody style={{ position: 'relative', height: loadingPlaceholderHeight }}>
          <tr>
            <th>
              <LoadingOverlay visible />
            </th>
          </tr>
        </tbody>
      );

    if (!sortedAssetItems.length) {
      return (
        <tbody>
          <tr></tr>
        </tbody>
      );
    }

    return (
      <tbody>
        {visibleItems.map((fileAsset, rowIndex) => {
          return (
            <FileTableRow
              key={fileAsset.id}
              fileAsset={fileAsset}
              rowIndex={rowIndex}
              hiddenColumns={hiddenColumns}
              style={{
                ...trStyle,
                ...(rowIndex === visibleItems.length - 1 && {
                  border: 'none',
                  outlineOffset: -0.5,
                  outline: `1px solid ${theme.colors.gray[2]}`,
                  borderBottomLeftRadius: 4,
                  borderBottomRightRadius: 4,
                }),
              }}
              renderActionCell={renderActionCell}
              onClick={onRowClick}
            />
          );
        })}
      </tbody>
    );
  };

  return (
    <Flex direction="column" gap={6} sx={sx} pos="relative">
      <Table
        ref={tableRef}
        className="asset-table"
        highlightOnHover={!!fileAssets.length}
        sx={(theme) => ({
          '&.ghost-Table-root.asset-table': {
            outline: 'none',
          },
          th: {
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: theme.colors.gray[1],
            },
          },
        })}
      >
        {colgroup ?? (
          <colgroup>
            <col style={{ width: '3%', minWidth: 100 }} />
            <col style={{ width: '60%' }} />
            <col style={{ width: '12%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '5%' }} />
          </colgroup>
        )}

        {renderTableHeader()}

        {renderTableBody()}
      </Table>

      <Box ref={infiniteScrollRef} sx={{ position: 'absolute', bottom: 40 }} />

      {!visibleItems.length && !loading && (
        <Flex
          direction="column"
          justify="center"
          p="xs"
          mt={-5}
          sx={(theme) => ({
            outline: `1px solid ${theme.colors.gray[2]}`,
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
          })}
        >
          {typeof placeholderText === 'string' ? (
            <Text fz="0.875rem" color="gray.7">
              {placeholderText}
            </Text>
          ) : (
            placeholderText
          )}
        </Flex>
      )}
    </Flex>
  );
};

export const AssetTable = memo(AssetTableBase);
