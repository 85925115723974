export type NonNullableArray<T> = { [K in keyof T]: NonNullable<T[K]> };

export const sortByAlphabetical = <T extends Record<string, unknown>[]>(objectArray: T, byProperty: string) => {
  return [...objectArray].sort((objectA, objectB) => {
    const textA = String(objectA[byProperty]).toUpperCase();
    const textB = String(objectB[byProperty]).toUpperCase();

    return textA < textB ? -1 : textA > textB ? 1 : 0;
  }) as T;
};

export const fillNumberArray = (startIndex: number, endIndex: number) =>
  Array.from({ length: endIndex - startIndex + 1 }, (_, index) => startIndex + index);

export const removeItemByIndex = <T>(array: T[], index: number) => {
  return [...array.slice(0, index), ...array.slice(index + 1)];
};

export const getNextItemInArray = <T>(array: T[], currentItem: T) => {
  const currentIndex = array.indexOf(currentItem);

  return array[currentIndex + 1] || array[0];
};
