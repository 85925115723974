import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  getMedicalConditionEntryId,
  getMedicalConditionParamsFromSearchParams,
} from '@/pageAI/services/medicalConditions';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { updateSearchParams } from '@/shared/lib/browser';
import { setSummaryHighlighted } from '@/pageAI/states/unified';

export interface UseConditionSharingProps {
  selectedMedicalConditionId?: string;
  isLoading: boolean;
}

export const resetConditionSharingState: { reset: () => void } = { reset: () => {} };

export const useConditionSharing = ({ selectedMedicalConditionId, isLoading }: UseConditionSharingProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const client = useSelectedClient(true);
  const hasInit = useRef(false);

  useEffect(() => {
    if (!selectedMedicalConditionId) return;

    const searchParams = new URLSearchParams(window.location.search);

    const updateSearchParamsFn = updateSearchParams(searchParams);

    updateSearchParamsFn('medicalConditionId', selectedMedicalConditionId);

    setSearchParams(searchParams, { replace: true });
  }, [setSearchParams, selectedMedicalConditionId]);

  useEffect(() => {
    resetConditionSharingState.reset = () => {
      hasInit.current = false;
    };

    if (hasInit.current || !selectedMedicalConditionId || isLoading) return;

    hasInit.current = true;

    const searchParams = new URLSearchParams(window.location.search);

    const { entryKey, medicalConditionId } = getMedicalConditionParamsFromSearchParams(searchParams);

    if (!medicalConditionId || !entryKey) return;

    searchParams.delete('entryKey');

    setSearchParams(searchParams);

    const entryElement = document.getElementById(getMedicalConditionEntryId(medicalConditionId, entryKey));

    if (!entryElement) return;

    entryElement.scrollIntoView({ block: 'center' });

    setSummaryHighlighted(entryKey);
  }, [isLoading, client, selectedMedicalConditionId, setSearchParams]);
};
