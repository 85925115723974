import { Organization } from '@/pageAI/@types';
import { OrganizationMember, OrganizationMemberRole } from '@/pageAI/gql/graphql';

export const PLAN_NAME_COLOR_MAPPING: Record<string, string> = {
  Trial: 'orange',
  'Pay as you go': 'dark',
  'Pay-as-you-go': 'dark',
};

export const canViewUsage = (member: OrganizationMember) => {
  return [OrganizationMemberRole.Owner, OrganizationMemberRole.Admin].includes(member.role);
};

export const canViewBilling = (member: OrganizationMember) => {
  return [OrganizationMemberRole.Owner, OrganizationMemberRole.Admin].includes(member.role);
};

export const canManageBilling = (member: OrganizationMember) => {
  return [OrganizationMemberRole.Owner].includes(member.role);
};

export const getCurrentCreditBundle = (organization: Organization) => {
  return organization.pricing.creditBundles.find(
    (creditBundle) => creditBundle.quantity === organization.autoRenewalSetting.amount,
  );
};

export const getPricePerCredit = (organization: Organization) => {
  if (organization.subscription) {
    return organization.pricing.paygPrice / 100;
  }

  const currentCreditBundle = getCurrentCreditBundle(organization);

  return (currentCreditBundle?.price || organization.pricing.paygPrice) / 100;
};

export const getCurrentPlanInfo = (organization: Organization) => {
  const subscription = organization.subscription;
  let planName = subscription?.plan.name || 'Trial';

  const pricePerCredit = getPricePerCredit(organization);

  if (organization.autoRenewalSetting.enabled) {
    planName = `${organization.autoRenewalSetting.amount} credit bundle - Auto refresh on`;
  }

  return {
    planName,
    pricePerCredit,
  };
};

export const getNextBillingInfo = (organization: Organization) => {
  const currentPeriodStart = organization.subscription?.currentPeriodStart;
  const currentPeriodEnd = organization.subscription?.currentPeriodEnd;

  return {
    currentPeriodStart,
    currentPeriodEnd,
  };
};
