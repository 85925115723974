import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { Client } from '@/pageAI/@types';
import { areGeneratingSummaries } from '@/pageAI/services/summaries';
import { queryMap as timelineEventsQueryMap } from '../../clients/useClientTimelineEvents';
import { queryMap as clientQueryMap } from '../../clients/useClient';
import { queryMap as conditionSummaryQueryMap } from '../../clients/useClientMedicalConditions';

export const useSummaryStatusPolling = (client: Client) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    const pollingInterval = setInterval(() => {
      if (
        areGeneratingSummaries({
          caseTimelineStatus: client.caseTimelineStatus,
          summaryByConditionStatus: client.summaryByConditionStatus,
        })
      ) {
        queryClient.refetchQueries(clientQueryMap.client.queryKey(client.id));
        queryClient.refetchQueries(timelineEventsQueryMap.client.timelineEvents.queryKey(client.id));
        queryClient.refetchQueries(conditionSummaryQueryMap.client.medicalConditions.queryKey(client.id));
      }
    }, 10000);

    return () => clearInterval(pollingInterval);
  }, [client.id, client.caseTimelineStatus, client.summaryByConditionStatus, queryClient]);
};
