import { useQuery } from '@tanstack/react-query';

import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { searchFilesQuery } from '@/pageAI/api';
import { normalizeFile } from '@/shared/services/files';
import { GraphQLRequestFunction } from '@/shared/lib/graphql';
import { fetchSearchResultSummaries } from '@/pageAI/services/conditionSearchTerms';
import { useUnifiedStore } from '@/pageAI/states/unified';
import { groupFileSearchResults } from '@/pageAI/services/files';
import { SearchFilesQuery } from '@/pageAI/gql/graphql';
import { setFileSummary } from '@/shared/states/fileSummary';
import { FetchStatus, FileSearchResult } from '@/shared/@types';

export const normalizeFileSearchQueryResults = (
  searchResultNodes?: SearchFilesQuery['searchFiles']['nodes'],
): FileSearchResult[] => {
  if (!searchResultNodes) return [];

  return searchResultNodes
    .map((node) => ({
      ...node,
      file: normalizeFile(node.file),
    }))
    .toSorted((resultA, resultB) => (resultA.score < resultB.score ? 1 : -1));
};

export const queryMap = {
  client: {
    fileSearch: {
      queryKey: (clientId: string, query = '', pageIndex = 0, fileNames?: string[] | null, withSummary = false) => [
        'client-file-search',
        clientId,
        query,
        pageIndex,
        fileNames,
        withSummary,
      ],
      queryFn: async (
        request: GraphQLRequestFunction,
        clientId: string,
        query = '',
        pageIndex = 0,
        fileNames?: string[] | null,
      ) => {
        if (!query)
          return {
            nodes: [] as FileSearchResult[],
            totalCount: 0,
            totalPages: 0,
            pageInfo: {
              hasNextPage: false,
              hasPreviousPage: false,
            },
          };

        const response = await request(searchFilesQuery, {
          clientIds: [clientId],
          query,
          page: pageIndex + 1,
          fileNames,
        });

        return { ...response.searchFiles, nodes: normalizeFileSearchQueryResults(response.searchFiles.nodes) };
      },
    },
  },
};

export type FileSearchResultQuery = Awaited<ReturnType<typeof queryMap.client.fileSearch.queryFn>>;

interface UseSearchClientFilesProps {
  clientId: string;
  query?: string;
  pageIndex?: number;
  fileNames?: string[] | null;
  withSummary?: boolean;
}

export const useSearchClientFiles = ({
  clientId,
  query = '',
  pageIndex = 0,
  fileNames,
  withSummary = false,
}: UseSearchClientFilesProps) => {
  const { request } = useGraphQLRequest();

  const { data, isLoading, isFetching, isError } = useQuery(
    queryMap.client.fileSearch.queryKey(clientId, query, pageIndex, fileNames, withSummary),
    () => queryMap.client.fileSearch.queryFn(request, clientId, query, pageIndex, fileNames),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      onSuccess: async (data) => {
        if (!withSummary || !data) return;

        const selectedSimpleCondition = useUnifiedStore.getState().selectedSimpleCondition;

        if (!selectedSimpleCondition) return;

        const groupedFileWithPageNumbers = groupFileSearchResults(data.nodes);

        const documents = groupedFileWithPageNumbers.map((group) => ({
          id: group.file.id,
          pages: group.results.map((result) => result.pageNumber),
        }));

        documents.forEach((document) => {
          setFileSummary({ fileId: document.id, summary: '', status: FetchStatus.LOADING });
        });

        try {
          const aiSummaries = await fetchSearchResultSummaries(request)({
            clientId,
            keyword: [query],
            condition: selectedSimpleCondition.headerCondition,
            documents,
          });

          aiSummaries.forEach((result) => {
            setFileSummary({
              fileId: result.documentId,
              summary: result.content || '',
              status: result.error ? FetchStatus.ERROR : FetchStatus.SUCCESS,
              relevant: Boolean(result.relevant),
            });
          });
        } catch (error) {
          documents.forEach((document) => {
            setFileSummary({ fileId: document.id, summary: '', status: FetchStatus.ERROR });
          });
        }
      },
    },
  );

  return {
    results: data?.nodes,
    totalCount: data?.totalCount,
    totalPages: data?.totalPages,
    pageInfo: data?.pageInfo,
    isLoading: isLoading || isFetching,
    isError,
  };
};
