import { memo } from 'react';
import { Button, Flex, Text } from '@mantine/core';
import { IconBriefcase, IconCurrencyDollar } from '@tabler/icons-react';

import { ImportantInfoCard } from '@/shared/designSystem/ImportantInfoCard';
import { ImportantInfoItem } from '@/shared/designSystem/ImportantInfoRow';
import { openPurchaseModal } from '../PurchaseModal';
import { Organization } from '@/pageAI/@types';
import { canManageBilling, getCurrentPlanInfo } from '@/pageAI/services/billing';
import { useCurrentMember } from '@/pageAI/hooks/organizations/useCurrentMember';

interface CurrentPlanCardProps {
  organization: Organization;
}

const CurrentPlanCardBase = ({ organization }: CurrentPlanCardProps) => {
  const currentMember = useCurrentMember(organization);

  const { planName, pricePerCredit } = getCurrentPlanInfo(organization);

  const currentPlanCardItems: ImportantInfoItem[] = [
    {
      icon: IconBriefcase,
      label: 'Plan',
      value: planName,
      className: 'current-plan',
    },
    {
      icon: IconCurrencyDollar,
      label: 'Unit price',
      value: `$${pricePerCredit.toFixed(2)}`,
      subValue: 'per credit',
    },
  ];

  return (
    <Flex direction="column" gap="sm">
      <Flex gap="xs" align="end" justify="space-between">
        <Flex align="center" gap={6}>
          <Text fw={600} color="dark.4" sx={{ whiteSpace: 'nowrap' }}>
            Current Plan
          </Text>
        </Flex>

        {currentMember && canManageBilling(currentMember) ? (
          <>
            {planName === 'Trial' ? (
              <Button onClick={openPurchaseModal} color="red" size="xs">
                Upgrade plan
              </Button>
            ) : (
              <Button onClick={openPurchaseModal} color="red" size="xs">
                Change plan
              </Button>
            )}
          </>
        ) : null}
      </Flex>

      <ImportantInfoCard
        items={currentPlanCardItems}
        className="current-plan-card"
        sx={(theme) => ({
          '.current-plan': {
            '.card-value': {
              color: theme.colors.dark[5],
            },
          },
        })}
      />
    </Flex>
  );
};

export const CurrentPlanCard = memo(CurrentPlanCardBase);
