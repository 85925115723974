import { graphql } from '@/pageAI/gql';
import './files';
import './users';

export const noteFullFieldsFragment = graphql(/* GraphQL */ `
  fragment Note_FullFields on Note {
    id
    content
    file {
      ...File_FullFields
    }
    author {
      ...User_CommonFields
    }
    medicalCondition {
      ...ClientMedicalCondition_CommonFields
    }
    timelineEvent {
      id
      eventType
      summaries
      summariesTypes
      details
      detailsTypes
      date
      duplicated
      confidenceLevel
      reference
      referencedText
      verificationLevel
      bookmarked
      generation
      metadata {
        key
        value
      }
      createdAt
      updatedAt
      client {
        id
      }
    }
    metadata {
      key
      value
    }
    createdAt
    updatedAt
    deletedAt
  }
`);

export const notesQuery = graphql(/* GraphQL */ `
  query Notes($id: ID!) {
    client(id: $id) {
      id
      notes(first: 9999) {
        nodes {
          ...Note_FullFields
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        totalCount
      }
    }
  }
`);

export const createNoteMutation = graphql(/* GraphQL */ `
  mutation CreateNote($input: CreateNoteInput!) {
    createNote(input: $input) {
      note {
        ...Note_FullFields
      }
    }
  }
`);

export const updateNoteMutation = graphql(/* GraphQL */ `
  mutation UpdateNote($input: UpdateNoteInput!) {
    updateNote(input: $input) {
      note {
        ...Note_FullFields
      }
    }
  }
`);

export const deleteNoteMutation = graphql(/* GraphQL */ `
  mutation DeleteNote($input: DeleteNoteInput!) {
    deleteNote(input: $input) {
      deletedNoteId
    }
  }
`);
