import { memo, useMemo } from 'react';
import { Box, Flex, Text } from '@mantine/core';
import { omit } from 'lodash-es';

import {
  canDisplayCodesheetSection,
  convertConditionToTimelineEvents,
  getKeywordsFromMedicalCondition,
  normalizeMedicalConditionFields,
  removeConditionDebugWords,
  shouldAlwaysHideNullFields,
} from '@/pageAI/services/medicalConditions';
import { MedicalConditionItemAsSummaryProps } from './MedicalConditionItemAsSummary.types';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import {
  isPrintingConditionSummarySelector,
  showNullFieldsSelector,
  summaryTabSortOrderSelector,
  summaryTabViewModeSelector,
  useUnifiedStore,
} from '@/pageAI/states/unified';
import { CaseTimeline } from '../../timeline/CaseTimeline';
import { ConditionSummarySection } from '../summary/ConditionSummarySection';
import { ConditionAllCodesheets } from '../summary/ConditionAllCodesheets';
import { ConditionClaimStatusSection } from '../claimStatus/ConditionClaimStatusSection';

const MedicalConditionItemAsSummaryBase = ({
  medicalCondition: rawMedicalCondition,
}: MedicalConditionItemAsSummaryProps) => {
  const client = useSelectedClient(true);
  const viewMode = useUnifiedStore(summaryTabViewModeSelector);
  const showNullFields = useUnifiedStore(showNullFieldsSelector);
  const sortOrder = useUnifiedStore(summaryTabSortOrderSelector);
  const isPrinting = useUnifiedStore(isPrintingConditionSummarySelector);

  const forceHideNullFields = useMemo(() => shouldAlwaysHideNullFields(rawMedicalCondition), [rawMedicalCondition]);

  const medicalCondition = useMemo(
    () => normalizeMedicalConditionFields(rawMedicalCondition, !forceHideNullFields && showNullFields),
    [rawMedicalCondition, showNullFields, forceHideNullFields],
  );

  const convertedEvents = useMemo(
    () => (viewMode === 'Chronological' ? convertConditionToTimelineEvents(medicalCondition) : []),
    [medicalCondition, viewMode],
  );

  const keywords = useMemo(
    () => getKeywordsFromMedicalCondition(rawMedicalCondition).map(removeConditionDebugWords),
    [rawMedicalCondition],
  );

  if (!client) return null;

  const renderBody = () => {
    if (viewMode === 'Chronological')
      return (
        <Box
          sx={{
            ul: {
              paddingLeft: 24,
              lineHeight: 0,
              li: {
                ul: {
                  li: {
                    ul: {
                      li: {
                        ul: {
                          marginBottom: -24,
                        },
                      },
                    },
                  },
                },
              },
            },
            li: {
              lineHeight: '24px',
            },
          }}
        >
          <CaseTimeline
            timeline={convertedEvents}
            virtualized={false}
            sortOrder={sortOrder}
            searchParamsDisabled
            readonly
            isPrinting={isPrinting}
          />
        </Box>
      );

    const displayCodesheetSection = canDisplayCodesheetSection(medicalCondition);

    return (
      <>
        {(medicalCondition.proceduralHistoryEntries || medicalCondition.filingsEntries || displayCodesheetSection) && (
          <Flex direction="column">
            <ConditionClaimStatusSection condition={medicalCondition} />

            <Flex align="center" justify="space-between" gap="xs">
              <Text fz="0.875rem" fw={600} color="dark" mb={4}>
                Procedural History
              </Text>
            </Flex>

            <ConditionSummarySection
              medicalCondition={medicalCondition}
              section={medicalCondition.proceduralHistory}
              sectionData={omit(medicalCondition.proceduralHistoryEntries, 'filings')}
              sectionKey="proceduralHistory"
              keywords={keywords}
              additionalSection={
                displayCodesheetSection ? (
                  <Flex
                    sx={(theme) => ({
                      borderBottom: `1px solid ${theme.colors.gray[3]}`,
                      padding: '8px 16px',
                    })}
                  >
                    <Flex>
                      <Text
                        fz="0.875rem"
                        color="dark"
                        sx={{ width: 220, whiteSpace: 'pre-line', wordBreak: 'break-word', paddingRight: 16 }}
                      >
                        Latest Codesheet
                      </Text>
                    </Flex>

                    <ConditionAllCodesheets condition={medicalCondition} />
                  </Flex>
                ) : null
              }
            />

            {medicalCondition.filingsEntries && (
              <>
                <Flex
                  sx={(theme) => ({
                    padding: 8,
                    borderLeft: `1px solid ${theme.colors.gray[3]}`,
                    borderRight: `1px solid ${theme.colors.gray[3]}`,
                  })}
                >
                  <Text fz="0.875rem" fw={600} color="dark">
                    Filings
                  </Text>
                </Flex>

                <ConditionSummarySection
                  medicalCondition={medicalCondition}
                  section={medicalCondition.proceduralHistory?.filings}
                  sectionData={medicalCondition.filingsEntries}
                  sectionKey="proceduralHistory.filings"
                  keywords={keywords}
                />
              </>
            )}
          </Flex>
        )}

        {medicalCondition.medicalEvidenceEntries && (
          <Flex direction="column" gap={4}>
            <Flex align="center" justify="space-between" gap="xs">
              <Text fz="0.875rem" fw={600} color="dark" mb={4}>
                Medical Evidence
              </Text>
            </Flex>

            <ConditionSummarySection
              medicalCondition={medicalCondition}
              section={medicalCondition.medicalEvidence}
              sectionData={medicalCondition.medicalEvidenceEntries}
              sectionKey="medicalEvidence"
              keywords={keywords}
            />
          </Flex>
        )}

        {medicalCondition.otherEvidenceEntries && (
          <Flex direction="column" gap={4}>
            <Text fz="0.875rem" fw={600} color="dark" mb={4}>
              Other Evidence
            </Text>

            <ConditionSummarySection
              medicalCondition={medicalCondition}
              section={medicalCondition.otherEvidence}
              sectionData={medicalCondition.otherEvidenceEntries}
              sectionKey="otherEvidence"
              keywords={keywords}
            />
          </Flex>
        )}
      </>
    );
  };

  return (
    <Flex direction="column" gap="lg" pos="relative">
      {renderBody()}
    </Flex>
  );
};

export const MedicalConditionItemAsSummary = memo(MedicalConditionItemAsSummaryBase);
