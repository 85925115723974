import { memo } from 'react';
import { Flex, Tooltip } from '@mantine/core';
import { IconTriangleFilled } from '@tabler/icons-react';

interface SearchRelevanceIndicatorProps {
  relevanceScore: number;
}

const SearchRelevanceIndicatorBase = ({ relevanceScore }: SearchRelevanceIndicatorProps) => {
  const isHighRelevance = relevanceScore >= 0.99;

  return (
    <Tooltip
      label={isHighRelevance ? 'High relevance' : relevanceScore > 0 ? 'Low relevance' : 'No results'}
      withArrow
      withinPortal
    >
      <Flex
        className="relevance-indicator"
        align="center"
        justify="center"
        mr={2}
        sx={(theme) => ({
          color: isHighRelevance
            ? theme.colors.teal[5]
            : relevanceScore > 0
            ? theme.colors.orange[5]
            : theme.colors.gray[5],
        })}
      >
        {isHighRelevance ? (
          <IconTriangleFilled size={8} />
        ) : (
          <Flex
            sx={(theme) => ({
              height: 2,
              width: 8,
              background: relevanceScore === 0 ? theme.colors.gray[5] : theme.colors.orange[5],
            })}
          />
        )}
      </Flex>
    </Tooltip>
  );
};

export const SearchRelevanceIndicator = memo(SearchRelevanceIndicatorBase);
