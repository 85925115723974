import { memo, useEffect, useMemo, useState } from 'react';
import { Alert, Button, Flex, List, Modal, Text, TextInput } from '@mantine/core';

import { MergeConditionsModalProps } from './MergeConditionsModal.types';
import { useMergeConditions } from '@/pageAI/hooks/conditions/useMergeConditions';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { truthy } from '@/shared/utils/boolean';
import { getFirstClaimedCondition } from '@/pageAI/services/medicalConditions';
import { ConditionType } from '@/pageAI/gql/graphql';
import { posthog } from '@/shared/plugins/posthog';
import { IconAlertTriangle } from '@tabler/icons-react';
import { quickFindClientFile } from '@/pageAI/services/clients';
import { getFileDisplayName } from '@/pageAI/services/files';

const MergeConditionsModalBase = ({
  opened = false,
  onClose,
  onConfirm,
  onSuccess,
  medicalConditions,
  conditionIdsToMerge,
}: MergeConditionsModalProps) => {
  const { mergeConditions, isLoading } = useMergeConditions();
  const client = useSelectedClient(true);

  const conditionsToMerge = useMemo(() => {
    return conditionIdsToMerge
      .map((conditionId) => medicalConditions.find((condition) => condition.id === conditionId))
      .filter(truthy)
      .sort((conditionA, conditionB) => (conditionA.conditionType === ConditionType.Claimed ? -1 : 1));
  }, [medicalConditions, conditionIdsToMerge]);

  const conditionsFromTheSameCodesheet = useMemo(() => {
    return conditionsToMerge.filter((currentCondition) => {
      const codesheetId = currentCondition.latestCodesheet?.data?.fileId;

      if (!codesheetId) return false;

      return conditionsToMerge.some(
        (targetCondition) =>
          targetCondition.id !== currentCondition.id && targetCondition.latestCodesheet?.data?.fileId === codesheetId,
      );
    });
  }, [conditionsToMerge]);

  const [newConditionName, setNewConditionName] = useState(
    getFirstClaimedCondition(conditionsToMerge)?.headerCondition || '',
  );

  useEffect(() => {
    setNewConditionName(getFirstClaimedCondition(conditionsToMerge)?.headerCondition || '');
  }, [opened, conditionsToMerge]);

  const handleMerge = async () => {
    onConfirm?.();

    posthog.capture('[Conditions] Merge conditions', {
      clientId: client.id,
      clientFirstName: client.firstName,
      clientLastName: client.lastName,
      conditions: conditionsToMerge.map((condition) => ({
        id: condition.id,
        headerCondition: condition.headerCondition,
      })),
      newConditionName,
    });

    const responseBody = await mergeConditions({
      clientId: client.id,
      mergedConditionHeader: newConditionName,
      sourceConditionIds: conditionIdsToMerge,
    });

    if (!responseBody) return;

    setTimeout(() => onSuccess?.(responseBody.mergeConditionsErrorCorrection.destCondition.id));
  };

  return (
    <Modal opened={opened} onClose={onClose} size={720} title="Merge Conditions">
      <Text fz="0.75rem" fw={500} color="gray.6" mb="xs">
        All the selected conditions will be merged into one. The merging process is irreversible and will take some
        time.
        <br />
        Please name the new condition and confirm merging.
      </Text>

      <TextInput
        label="New condition name"
        placeholder="Enter the name of the new condition..."
        value={newConditionName}
        onChange={(event) => setNewConditionName(event.target.value)}
      />

      <Flex direction="column">
        <Text fz="0.875rem" fw={600} color="dark.4" mt="xs">
          Selected conditions:
        </Text>

        <List type="ordered" fz="0.875rem" pl="xs">
          {conditionsToMerge.map((condition) => {
            const isFromTheSameCodesheet = conditionsFromTheSameCodesheet.some(
              (currentCondition) => currentCondition.id === condition.id,
            );

            const fileAsset =
              isFromTheSameCodesheet && condition.latestCodesheet?.data?.fileId
                ? quickFindClientFile(client, condition.latestCodesheet?.data?.fileId)
                : undefined;

            return (
              <List.Item
                key={condition.id}
                sx={(theme) => ({
                  ...(isFromTheSameCodesheet
                    ? { color: theme.colors.red[6], fontWeight: 600 }
                    : {
                        color: theme.colors.dark[4],
                      }),
                })}
              >
                <Text fz="0.875rem">
                  {condition.headerCondition}{' '}
                  {fileAsset && (
                    <Text component="span" fw={400} fz="0.75rem" color="dark.4">
                      from{' '}
                      <Text component="span" fw={600}>
                        {getFileDisplayName(fileAsset)}
                      </Text>
                    </Text>
                  )}
                </Text>
              </List.Item>
            );
          })}
        </List>
      </Flex>

      {conditionsFromTheSameCodesheet.length ? (
        <Alert variant="outline" color="red" mt="xs">
          <Flex align="center" gap={6}>
            <Text color="red" display="flex">
              <IconAlertTriangle size={16} />
            </Text>

            <Text>Conditions from the same codesheet are not allowed to be merged together.</Text>
          </Flex>
        </Alert>
      ) : null}

      <Flex justify="flex-end" mt="lg" gap="xs">
        <Button variant="subtle" color="gray.7" onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>

        <Button loading={isLoading} onClick={handleMerge} disabled={conditionsFromTheSameCodesheet.length > 0}>
          Confirm
        </Button>
      </Flex>
    </Modal>
  );
};

export const MergeConditionsModal = memo(MergeConditionsModalBase);
