import { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import { produce } from 'immer';

import { updateFileMutation } from '@/pageAI/api';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { UpdateFileInput, UpdateFileMutation } from '@/pageAI/gql/graphql';
import { useUpdateClient } from '@/pageAI/hooks/clients/useUpdateClient';
import { Client } from '@/pageAI/@types';
import { filterNullishKeys } from '@/shared/utils/object';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';

export const useUpdateFileMetadata = () => {
  const { request } = useGraphQLRequest();
  const { updateClientLocally } = useUpdateClient();
  const { notify } = useNotifications();

  const { mutateAsync, isLoading } = useMutation<UpdateFileMutation, Error, UpdateFileInput>({
    mutationFn: async (input) => {
      return request(updateFileMutation, { input });
    },
  });

  const updateFileMetadata = useCallback(
    async (input: UpdateFileInput) => {
      try {
        await mutateAsync(input);
      } catch (error) {
        notify('Error', 'Failed to update file');
      }
    },
    [notify, mutateAsync],
  );

  const updateFileMetadataOptimistically = useCallback(
    async (client: Client, input: UpdateFileInput) => {
      const newFileCollections = produce(client.fileCollections, (draft) => {
        if (!draft.nodes[0].files) return draft;

        draft.nodes[0].files.nodes = draft.nodes[0].files?.nodes.map((file) => {
          if (file.id !== input.fileId) return file;

          return produce(file, (fileDraft) => {
            if (input.newData.fileReceiveDate) fileDraft.fileReceiveDate = input.newData.fileReceiveDate;

            if (!fileDraft.metadata.pageAIMetadata) return fileDraft;

            fileDraft.metadata.pageAIMetadata = {
              ...fileDraft.metadata.pageAIMetadata,
              ...filterNullishKeys({
                eventTypeClassifications: input.newData.eventTypeClassifications,
                vaFileTypeName: input.newData.vaFileTypeName,
              }),
            };
          });
        });
      });

      const reverse = updateClientLocally({ id: client.id, fileCollections: newFileCollections });

      try {
        await mutateAsync(input);
      } catch (error) {
        reverse();

        notify('Error', 'Failed to update file');
      }
    },
    [notify, updateClientLocally, mutateAsync],
  );

  return { updateFileMetadata, updateFileMetadataOptimistically, isLoading };
};
