import { FileAsset } from '@/shared/@types';

export interface FilePreviewState {
  textContent?: string;
  asset?: FileAsset;
  isLoadingTextContent?: boolean;
  initialPageIndex?: number;
  defaultTab: FilePreviewTab;
  highlightedTexts: string[];
  pagesToHighlight?: number[];
  displayName?: string;
  additionalMenuItems?: React.ReactNode;
  currentPageIndex?: number;
}

export enum FilePreviewTab {
  PDF = 'PDF',
  Text = 'Text',
}
