import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Client } from '@/pageAI/@types';
import { quickFindClientFile } from '@/pageAI/services/clients';
import { openRightSidebar } from '@/shared/states/layout';
import { setFilePreviewAsset, setPdfPreviewInitalPageIndex } from '@/shared/states/filePreview';
import { highlightTimelineEventPubsub } from '@/pageAI/services/caseTimeline';
import { CaseTimelineEvent } from '@/pageAI/@types/summaries';

export const timelineParamsInitialState = {
  isHardRefresh: true,
};

export const useTimelineSearchParams = (timelineEvents?: CaseTimelineEvent[], client?: Client) => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!timelineParamsInitialState.isHardRefresh || !client || !timelineEvents?.length) return;

    const eventId = searchParams.get('eventId');
    const fileId = searchParams.get('fileId');
    const rawPage = searchParams.get('page');

    if (eventId) {
      const eventToOpen = timelineEvents.find((timelineEvent) => timelineEvent.id === eventId);

      if (eventToOpen) {
        timelineParamsInitialState.isHardRefresh = false;

        searchParams.delete('eventId');
        searchParams.delete('fileId');
        searchParams.delete('page');
        setSearchParams(searchParams, { replace: true });

        requestIdleCallback(() => {
          highlightTimelineEventPubsub.publish({ event: eventToOpen, shouldOpenReference: true });
        });

        return;
      }
    }

    if (fileId) {
      const fileAsset = quickFindClientFile(client, fileId);

      if (fileAsset?.metadata?.pageAIMetadata) {
        timelineParamsInitialState.isHardRefresh = false;

        const { vaFileTypeName, indexNumber } = fileAsset.metadata.pageAIMetadata;

        const pageIndex = rawPage ? Number(rawPage) - 1 : 0;

        openRightSidebar();
        setFilePreviewAsset(fileAsset, `[${indexNumber}] ${vaFileTypeName}`);
        setPdfPreviewInitalPageIndex(Number.isNaN(pageIndex) ? 0 : pageIndex);
      }
    }
  }, [client, timelineEvents, searchParams, setSearchParams]);
};
