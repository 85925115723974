import { memo } from 'react';
import ReactTimeAgo from 'react-time-ago';
import { Text } from '@mantine/core';

interface CommentTimeProps {
  date: Date | string;
  compact?: boolean;
}

const TimeAgoBase = ({ date, compact = false }: CommentTimeProps) => {
  const createdDate = new Date(date);

  return (
    <Text
      fz="0.625rem"
      color="dark.3"
      sx={{
        display: 'inline-block',
        transition: 'all 0.1s',
        ...(compact
          ? {
              whiteSpace: 'nowrap',
              maxWidth: 36,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }
          : {}),
      }}
    >
      <ReactTimeAgo date={createdDate} />
    </Text>
  );
};

export const TimeAgo = memo(TimeAgoBase);
