import { memo, useState } from 'react';
import { Button, Flex, Popover, Select } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useDisclosure } from '@mantine/hooks';
import { IconPencil } from '@tabler/icons-react';

import { MedicalConditionItem } from '@/pageAI/@types/summaries';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { useUpdateCondition } from '@/pageAI/hooks/conditions/useUpdateCondition';
import { ClaimStatus } from '@/pageAI/gql/graphql';
import { keys } from '@/shared/utils/object';
import { CONDITION_CLAIM_STATUS_TEXT_MAPPING } from '@/pageAI/services/medicalConditions';
import { formatShortDate, formatUSDate, parseDateOnly } from '@/shared/utils/date';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { ClaimStatusInfo } from '../ClaimStatusInfo';

interface ClaimStatusEditButtonProps {
  condition: MedicalConditionItem;
  disabled?: boolean;
}

const ClaimStatusEditButtonBase = ({ condition, disabled = false }: ClaimStatusEditButtonProps) => {
  const client = useSelectedClient(true);
  const { notify } = useNotifications();
  const { updateCondition, isUpdating } = useUpdateCondition(client.id);

  const [opened, { toggle, close }] = useDisclosure();
  const [sourceDocumentDate, setSourceDocumentDate] = useState<Date | null>(() =>
    condition.claimStatus?.lastFilingDate ? parseDateOnly(condition.claimStatus.lastFilingDate) : new Date(),
  );
  const [claimStatus, setClaimStatus] = useState(condition.claimStatus?.status || ClaimStatus.Unknown);

  const handleConfirmEdit = async () => {
    if (!sourceDocumentDate) return;

    try {
      await updateCondition({
        id: condition.id,
        claimStatus: {
          status: claimStatus,
          lastFilingDate: formatShortDate(sourceDocumentDate),
          isConfirmed: true,
        },
      });

      close();
      notify('Success', 'Successfully confirmed claim status', 'brand');
    } catch (error) {
      notify('Error', 'Failed to edit claim status');
    }
  };

  if (!condition.claimStatus?.lastFilingDate || condition.claimStatus.status === ClaimStatus.Unknown) return null;

  return (
    <Popover opened={opened} onClose={close} shadow="sm" width={320}>
      <Popover.Target>
        <Button
          onClick={toggle}
          size="xs"
          leftIcon={<IconPencil size={16} />}
          variant="subtle"
          color="gray.7"
          disabled={disabled || isUpdating}
        >
          Edit
        </Button>
      </Popover.Target>

      <Popover.Dropdown p={0}>
        <Flex direction="column">
          <Flex direction="column" px={16} py={12} gap={4}>
            <DateInput
              label="Source document date"
              size="xs"
              value={sourceDocumentDate}
              onChange={setSourceDocumentDate}
              popoverProps={{
                shadow: 'sm',
              }}
            />

            <Select
              label="Claim status"
              size="xs"
              value={claimStatus}
              onChange={(value) => setClaimStatus(value as ClaimStatus)}
              data={keys(ClaimStatus).map((claimStatusKey) => ({
                value: ClaimStatus[claimStatusKey],
                label: CONDITION_CLAIM_STATUS_TEXT_MAPPING[ClaimStatus[claimStatusKey]],
              }))}
            />

            {sourceDocumentDate && (
              <Flex
                align="center"
                p="xs"
                mt={4}
                sx={(theme) => ({
                  background: theme.colors.gray[1],
                  borderRadius: 4,
                  fontSize: '0.75rem',
                })}
              >
                <ClaimStatusInfo claimStatus={claimStatus} sourceDocumentDate={formatUSDate(sourceDocumentDate)} />
              </Flex>
            )}
          </Flex>

          <Flex align="center" gap="xs" justify="end" px={16} pb={12}>
            <Button size="xs" variant="subtle" color="gray.7" onClick={close} disabled={isUpdating}>
              Cancel
            </Button>

            <Button size="xs" onClick={handleConfirmEdit} loading={isUpdating}>
              Confirm
            </Button>
          </Flex>
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );
};

export const ClaimStatusEditButton = memo(ClaimStatusEditButtonBase);
