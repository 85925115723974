import React, { forwardRef, memo, useImperativeHandle, useState } from 'react';
import { Accordion, Anchor, Box, Button, Flex, Popover, Text, Tooltip } from '@mantine/core';
import { useClickOutside, useDisclosure } from '@mantine/hooks';
import { useKeyPress } from 'ahooks';

import { InlineEditor } from '@/shared/components/editor/InlineEditor';
import { NoteEntityIndicator } from '../NoteEntityIndicator';
import { useFilePreviewStore } from '@/shared/states/filePreview';
import { useManageNotes } from '@/pageAI/hooks/notes/useManageNotes';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { ConditionDropdown } from '../../medicalConditions/ConditionDropdown';
import { resetAddNoteState, setNoteCondition, useNotesStore } from '@/pageAI/states/notes';
import { NoteFileSnippet } from '../NoteFileSnippet';
import { AddNoteButton } from '../AddNoteButton';
import { getConditionName } from '@/pageAI/services/medicalConditions';
import { formatUSDate } from '@/shared/utils/date';

interface AddNoteContainerProps {
  onClose?: () => void;
  onExpand?: () => void;
  compact?: boolean;
  defaultCollapsed?: boolean;
}

export interface AddNoteRef {
  expand: (autofocus?: boolean) => void;
}

const AddNoteContainerBase = (
  { onClose, onExpand, compact = false, defaultCollapsed = false }: AddNoteContainerProps,
  forwardedRef: React.ForwardedRef<AddNoteRef>,
) => {
  const client = useSelectedClient();
  const pageIndex = useFilePreviewStore((state) => state.currentPageIndex);
  const { addNote, isAdding } = useManageNotes(client?.id);
  const fileAsset = useNotesStore((state) => state.fileAsset);
  const fileSnippet = useNotesStore((state) => state.fileSnippet);
  const isSnippetMarkdown = useNotesStore((state) => state.isSnippetMarkdown);
  const conditionEntryKey = useNotesStore((state) => state.conditionEntryKey);
  const timelineEvent = useNotesStore((state) => state.timelineEvent);
  const selectedCondition = useNotesStore((state) => state.condition);
  const [collapsed, setCollapsed] = useState(defaultCollapsed);
  const [conditionDropdownOpened, { toggle: toggleConditionDropdown, close: closeConditionDropdown }] = useDisclosure();
  const [content, setContent] = useState('');

  const editorHeight = collapsed ? 24 : 96;

  useImperativeHandle(forwardedRef, () => ({
    expand: () => {
      setCollapsed(false);
    },
  }));

  const handleChange = (value: string) => {
    setContent(value);
  };

  const handleSave = async () => {
    if ((!content && !fileSnippet) || !client) return;

    await addNote({
      clientId: client.id,
      content,
      medicalConditionId: selectedCondition?.id,
      fileId: fileAsset?.id,
      timelineEventId: timelineEvent?.id,
      metadata: [
        {
          key: 'fileSnippet',
          value: fileSnippet,
        },
        {
          key: 'pageIndex',
          value: String(pageIndex || 0),
        },
        {
          key: 'isSnippetMarkdown',
          value: String(isSnippetMarkdown),
        },
        ...(conditionEntryKey ? [{ key: 'conditionEntryKey', value: conditionEntryKey }] : []),
      ],
    });

    setContent('');
    resetAddNoteState();

    onClose?.();
  };

  const handleExpand = () => {
    setCollapsed(false);
    onExpand?.();
  };

  const renderConditionOrEvent = () => {
    if (timelineEvent) {
      return (
        <Text
          fz="0.75rem"
          fw={500}
          color="blue.6"
          sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
        >
          Timeline Event - {formatUSDate(timelineEvent.date)}
        </Text>
      );
    }

    const conditionName = selectedCondition ? getConditionName(selectedCondition) : '';

    return (
      <Popover opened={conditionDropdownOpened} withinPortal shadow="sm" onClose={closeConditionDropdown}>
        <Popover.Target>
          <Tooltip
            {...(!selectedCondition ? { opened: false } : {})}
            openDelay={500}
            label={conditionName}
            withArrow
            withinPortal
          >
            <Anchor
              color="blue.6"
              fw={500}
              fz="0.75rem"
              sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              onClick={toggleConditionDropdown}
            >
              {selectedCondition ? conditionName : 'Select a condition...'}
            </Anchor>
          </Tooltip>
        </Popover.Target>

        <Popover.Dropdown
          p={0}
          sx={{
            '.condition-item': {},
          }}
        >
          <ConditionDropdown
            onConfirm={(conditions) => setNoteCondition(conditions[0])}
            onClose={closeConditionDropdown}
            multiple={false}
            noConfirm
          />
        </Popover.Dropdown>
      </Popover>
    );
  };

  const renderBottom = () => {
    return (
      <Accordion value={collapsed ? '' : 'default'}>
        <Accordion.Item
          value="default"
          sx={{
            border: 'none',
            '.ghost-Accordion-content': {
              padding: 0,
            },

            '.note-file-indicator': {
              maxWidth: '50%',
            },
          }}
        >
          <Accordion.Panel>
            <Flex align="center" gap={6} sx={{ width: '100%' }}>
              {fileAsset ? (
                <NoteEntityIndicator
                  fileAsset={fileAsset}
                  pageNumbers={typeof pageIndex === 'number' ? [pageIndex + 1] : [1]}
                  noHighlight
                />
              ) : null}

              <Flex ml="xs" align="center" justify="end" gap={6} sx={{ flexGrow: 1, overflow: 'hidden', width: '50%' }}>
                {renderConditionOrEvent()}

                {compact && <AddNoteButton onClick={handleSave} loading={isAdding} collapsed={collapsed} />}
              </Flex>
            </Flex>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    );
  };

  const ref = useClickOutside(() => {
    if (conditionDropdownOpened) return;

    setCollapsed(true);
  });

  useKeyPress(['meta.enter', 'ctrl.enter'], handleSave);

  return (
    <Flex direction="column" sx={{ width: '100%' }} className="add-note-container">
      <Flex ref={ref} p={12} pt={0} sx={{ width: '100%' }} direction="column" gap="xs">
        <Flex
          direction="column"
          pos="relative"
          gap={collapsed ? 0 : 'xs'}
          className="normalized-markdown"
          sx={(theme) => ({
            transition: 'all 0.2s ease-in-out',
            border: collapsed ? `1px solid ${theme.colors.gray[3]}` : `1px solid ${theme.colors.gray[7]}`,
            borderRadius: 4,
            padding: collapsed ? '6px 8px' : '6px 8px 12px',
            width: '100%',
          })}
        >
          {compact && collapsed && (
            <AddNoteButton
              sx={{ position: 'absolute', right: 8, top: 9 }}
              onClick={handleExpand}
              collapsed={collapsed}
            />
          )}

          {!content && (
            <Text
              fz="0.875rem"
              color="dark.3"
              sx={{ position: 'absolute', top: 8, left: 8, pointerEvents: 'none', userSelect: 'none' }}
            >
              Add your note...
            </Text>
          )}

          <Box
            sx={{
              height: editorHeight,
              flexGrow: 1,
              overflow: 'auto',
              '> div': {
                minHeight: editorHeight,
                '.ck.ck-content': {
                  minHeight: editorHeight,
                },
              },
            }}
            onFocus={handleExpand}
          >
            <InlineEditor data={content} onDataChange={handleChange} autofocus={!collapsed} />
          </Box>

          {!collapsed && (
            <NoteFileSnippet snippet={fileSnippet} sx={{ margin: 0 }} removable isMarkdown={isSnippetMarkdown} />
          )}

          {renderBottom()}
        </Flex>
      </Flex>

      {!compact && (
        <Flex direction="column">
          <Flex align="center" justify="end" p={12} pt={0} gap="xs">
            <Button color="gray.7" variant="subtle" onClick={onClose} size="xs" disabled={isAdding}>
              Cancel
            </Button>

            <Button size="xs" onClick={handleSave} disabled={!content && !fileSnippet} loading={isAdding}>
              Save
            </Button>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export const AddNoteContainer = memo(forwardRef(AddNoteContainerBase));
