import { memo, useCallback, useEffect, useState } from 'react';
import { ActionIcon, Anchor, Button, Flex, Popover, Text, Tooltip } from '@mantine/core';
import { IconFilter, IconFilterFilled, IconInfoTriangle, IconX } from '@tabler/icons-react';
import { EventBus } from 'native-pubsub';

import { truthy } from '@/shared/utils/boolean';
import { toggleFileGroupManagementModalEventBus } from '../FileGroupManagementModal';
import {
  TimelineFileGroupFilter,
  useFileGroupConfirmationModalStore,
} from '@/pageAI/components/timeline/TimelineFileGroupFilter';
import { FileGroup } from '@/pageAI/gql/graphql';
import { TimelineFileFilter } from '@/pageAI/components/timeline/TimelineFileFilter';
import { FileFiltersProps } from './FileFilters.types';
import { useSelectableFileTypes } from '@/pageAI/hooks/caseTimeline/useSelectableFileTypes';
import { useFileSearchContext } from '@/pageAI/contexts/fileSearchContext';
import { useLocalStorage } from '@mantine/hooks';
import { useUnifiedTab } from '@/pageAI/hooks/unified/useUnifiedTab';
import { UnifiedTab } from '@/pageAI/services/medicalConditions';

export const applyFileGroupFilterEventBus = new EventBus<{ fileGroups: FileGroup[]; showHint?: boolean }>();

const FileFiltersBase = ({ client, compact }: FileFiltersProps) => {
  const [tab] = useUnifiedTab();
  const { fileGroups, setFileGroups, fileTypes, setFileTypes } = useFileSearchContext();
  const [showHintPersistent, setShowHintPersistent] = useLocalStorage<boolean | undefined>({
    key: 'showFileGroupFilterHint',
    defaultValue: undefined,
  });

  const isFileGroupConfirmationModalOpened = useFileGroupConfirmationModalStore(
    (state) => state.isConfirmationModalOpened,
  );

  const { selectableFileTypes } = useSelectableFileTypes({ client });

  const [forceRemountCounter, setForceRemountCounter] = useState(0);

  const handleChangeFileTypes = useCallback(
    (value: string[] | null) => {
      setFileTypes(value);
    },
    [setFileTypes],
  );

  const handleChangeFileGroups = useCallback(
    (value: string[] | null) => {
      setFileGroups(value);
    },
    [setFileGroups],
  );

  useEffect(() => {
    const unsubscribe = applyFileGroupFilterEventBus.subscribe(({ fileGroups, showHint }) => {
      if (typeof showHintPersistent === 'undefined' && showHint) {
        setShowHintPersistent(true);

        setTimeout(() => setShowHintPersistent(false), 10000);
      }

      setFileGroups(fileGroups.map((fileGroup) => fileGroup.displayName));
      setForceRemountCounter((prev) => prev + 1);
    });

    return unsubscribe;
  }, [setFileGroups, showHintPersistent, setShowHintPersistent]);

  const hasFilters = fileTypes === null || fileTypes.length > 0 || (fileGroups && fileGroups.length > 0);
  const filterIcon = hasFilters ? (
    <Flex sx={{ position: 'relative' }} align="center" justify="center">
      <IconFilterFilled size={16} />

      <Flex
        align="center"
        justify="center"
        sx={(theme) => ({
          position: 'absolute',
          right: -4,
          top: -4,
          width: 12,
          height: 12,
          background: theme.colors.red[6],
          borderRadius: '50%',
        })}
      >
        <Text color="white" fz="0.5rem" fw={600}>
          {[fileTypes === null || fileTypes.length > 0 || (fileGroups && fileGroups.length > 0)].filter(truthy).length}
        </Text>
      </Flex>
    </Flex>
  ) : (
    <IconFilter size={16} />
  );

  const fileFilters = (
    <Popover
      withinPortal
      position="bottom-end"
      shadow="sm"
      keepMounted
      closeOnClickOutside={!isFileGroupConfirmationModalOpened}
    >
      <Popover.Target>
        {compact ? (
          <Tooltip label="Filter" withArrow withinPortal>
            <ActionIcon color="gray">{filterIcon}</ActionIcon>
          </Tooltip>
        ) : (
          <Button color="gray.7" size="xs" variant="subtle" leftIcon={filterIcon}>
            Filter
          </Button>
        )}
      </Popover.Target>

      <Popover.Dropdown>
        <Flex direction="column" gap="sm">
          <Flex direction="column">
            <Flex align="center" justify="space-between" mb={2}>
              <Text fz="0.75rem" color="dark.4" fw={500}>
                Custom Filters
              </Text>

              <Anchor
                size="xs"
                sx={(theme) => ({
                  fontWeight: 600,
                  color: theme.colors.red[5],
                })}
                onClick={(event) => {
                  toggleFileGroupManagementModalEventBus.publish({});
                }}
              >
                Customize
              </Anchor>
            </Flex>

            <TimelineFileGroupFilter
              key={forceRemountCounter}
              fileTypes={fileTypes}
              initialValue={fileGroups}
              onChange={handleChangeFileGroups}
            />
          </Flex>

          <Flex direction="column">
            <Flex align="center" justify="space-between" mb={2}>
              <Text fz="0.75rem" color="dark.4" fw={500}>
                Filter by File Type
              </Text>

              {!fileGroups?.length && fileTypes?.length ? (
                <Tooltip label="Save as a new custom filter" withinPortal withArrow>
                  <Anchor
                    size="xs"
                    onClick={(event) => {
                      toggleFileGroupManagementModalEventBus.publish({ fileNames: fileTypes });
                    }}
                  >
                    Save
                  </Anchor>
                </Tooltip>
              ) : null}
            </Flex>

            <TimelineFileFilter
              key={forceRemountCounter}
              fileGroupValues={fileGroups}
              initialValue={fileTypes}
              selectableFileTypes={selectableFileTypes}
              onChange={handleChangeFileTypes}
              maxHeight={232}
            />
          </Flex>
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );

  return (
    <Popover
      opened={showHintPersistent && !!fileGroups?.length}
      shadow="sm"
      withArrow
      withinPortal
      position="top"
      offset={0}
    >
      <Popover.Target>
        <Flex align="center" justify="center">
          {fileFilters}
        </Flex>
      </Popover.Target>

      {fileGroups?.length && tab === UnifiedTab.CONTENT_SEARCH ? (
        <Popover.Dropdown
          p="xs"
          sx={(theme) => ({
            background: theme.colors.dark[5],
          })}
        >
          <Flex align="center" gap={6}>
            <Flex
              align="center"
              justify="center"
              sx={(theme) => ({
                color: theme.colors.orange[4],
              })}
            >
              <IconInfoTriangle size={16} />
            </Flex>

            <Text fz="0.875rem" color="white">
              The &quot;{fileGroups?.[0]}&quot; filter has been automatically applied.
            </Text>

            <ActionIcon size="xs" onClick={() => setShowHintPersistent(false)}>
              <IconX size={14} />
            </ActionIcon>
          </Flex>
        </Popover.Dropdown>
      ) : null}
    </Popover>
  );
};

export const FileFilters = memo(FileFiltersBase);
