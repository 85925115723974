import { memo } from 'react';
import { ActionIcon, Flex, Text, Tooltip } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';

import { RelatedConditionsTooltipProps } from './RelatedConditionsTooltip.types';
import { selectedConditionSelector, useUnifiedStore } from '@/pageAI/states/unified';

const RelatedConditionsTooltipBase = ({ medicalCondition }: RelatedConditionsTooltipProps) => {
  const selectedCondition = useUnifiedStore(selectedConditionSelector);

  if (!medicalCondition?.subConditions?.length) return null;

  return (
    <Tooltip
      sx={(theme) => ({
        background: theme.white,
        border: `1px solid ${theme.colors.gray[3]}`,
        borderRadius: 4,
        boxShadow: theme.shadows.sm,
        color: theme.colors.dark[4],
      })}
      label={
        <Flex direction="column" gap={4} py={4} sx={{ order: 2 }}>
          <Text fw={600} fz="0.75rem" color="dark.3">
            Related conditions
          </Text>

          <ol style={{ paddingLeft: 24, margin: 0 }}>
            {medicalCondition.subConditions.map((subCondition) => {
              return (
                <li key={subCondition} style={{ fontWeight: 400, paddingLeft: 4 }}>
                  <Text
                    fz="0.875rem"
                    fw={400}
                    sx={{
                      maxWidth: 'calc(100vw - 88px)',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {subCondition}
                  </Text>
                </li>
              );
            })}
          </ol>

          {selectedCondition?.mergedConditions?.length ? (
            <>
              <Text fw={600} fz="0.75rem" color="dark.3" mt={4}>
                Merged conditions
              </Text>

              <ol style={{ paddingLeft: 24, margin: 0 }}>
                {selectedCondition.mergedConditions.map((mergedCondition) => {
                  return (
                    <li key={mergedCondition} style={{ fontWeight: 400, paddingLeft: 4 }}>
                      <Text
                        fz="0.875rem"
                        fw={400}
                        sx={{
                          maxWidth: 'calc(100vw - 88px)',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {mergedCondition}
                      </Text>
                    </li>
                  );
                })}
              </ol>
            </>
          ) : null}
        </Flex>
      }
      withArrow
      withinPortal
    >
      <ActionIcon>
        <IconInfoCircle size={16} />
      </ActionIcon>
    </Tooltip>
  );
};

export const RelatedConditionsTooltip = memo(RelatedConditionsTooltipBase);
