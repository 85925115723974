import { memo, useCallback, useMemo } from 'react';
import { Flex } from '@mantine/core';

import { SummaryFileReferenceBadge } from '../../summaries/SummaryFileReferenceBadge';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { FileAsset } from '@/shared/@types';
import { generateFileReferenceMarkdown } from '@/pageAI/services/files';
import { Note_FullFieldsFragment } from '@/pageAI/gql/graphql';
import { getNoteFileSnippet } from '@/pageAI/services/notes';
import { setHighlightedReference, useNotesStore } from '@/pageAI/states/notes';
import { truthy } from '@/shared/utils/boolean';

interface NoteEntityIndicatorProps {
  note?: Note_FullFieldsFragment;
  fileAsset: FileAsset;
  pageNumbers?: number[];
  noHighlight?: boolean;
}

const NoteEntityIndicatorBase = ({ note, fileAsset, pageNumbers, noHighlight = false }: NoteEntityIndicatorProps) => {
  const client = useSelectedClient(true);
  const highlightedReference = useNotesStore((state) => state.highlightedReference);

  const reference = useMemo(() => {
    if (!fileAsset) return null;

    return generateFileReferenceMarkdown(fileAsset, pageNumbers);
  }, [fileAsset, pageNumbers]);

  const highlightReference = useMemo(() => [note?.id, reference].filter(truthy).join('-'), [note?.id, reference]);

  const noteFileSnippet = useMemo(() => (note ? getNoteFileSnippet(note) : undefined), [note]);

  const handleBadgeClick = useCallback(() => {
    setHighlightedReference(highlightReference);
  }, [highlightReference]);

  if (!reference) return null;

  return (
    <Flex
      className="note-file-indicator"
      sx={{
        '.ghost-Badge-root': {
          width: '100%',
        },
      }}
    >
      <SummaryFileReferenceBadge
        client={client}
        reference={reference}
        alwaysFullDisplay
        referencedTexts={noteFileSnippet ? [noteFileSnippet] : []}
        onClick={handleBadgeClick}
        isHighlighted={!noHighlight && highlightedReference === highlightReference}
      />
    </Flex>
  );
};

export const NoteEntityIndicator = memo(NoteEntityIndicatorBase);
