import { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Anchor,
  Avatar,
  Box,
  Flex,
  Loader,
  ScrollArea,
  SegmentedControl,
  Skeleton,
  Text,
  TextInput,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { IconSearch } from '@tabler/icons-react';

import { Panel } from '@/pageAI/designSystem/Panel';
import { withPageWrapper } from '@/shared/hocs/withPageWrapper';
import { ErrorPlaceholder } from '@/shared/components/common/placeholders/ErrorPlaceholder';
import { PAGE_AI_HEADER_HEIGHT, PageAIHeader } from '@/pageAI/components/layout/PageAIHeader';
import { DataEmptyPlaceholder } from '@/pageAI/components/clients/DataEmptyPlaceholder';
import { singularOrPlural } from '@/shared/utils/string';
import { useOrganization } from '@/pageAI/hooks/organizations/useOrganization';
import { OrganizationMembersTable } from '@/pageAI/components/organizations/OrganizationMembersTable';
import { getUserDisplayName } from '@/pageAI/services/users/users.services';
import { canInviteMembers, canManageRequests, getOrganizationDisplayName } from '@/pageAI/services/organizations';
import { OrganizationRequestsTab } from '@/pageAI/components/organizations/OrganizationRequestsTab';
import { InviteMemberButton } from '@/pageAI/components/organizations/InviteMemberButton';
import { UsageLayout } from '@/pageAI/components/usage/UsageLayout';
import { BillingLayout } from '@/pageAI/components/billing/BillingLayout';
import { canViewBilling, canViewUsage } from '@/pageAI/services/billing';
import { truthy } from '@/shared/utils/boolean';
import { useCurrentMember } from '@/pageAI/hooks/organizations/useCurrentMember';

export type OrganizationTab = 'Members' | 'Requests' | 'Usage' | 'Billing';

const OrganizationPageBase = () => {
  const { organizationId } = useParams();
  const { organization, isLoading, isError } = useOrganization(organizationId);
  const currentMember = useCurrentMember(organization);

  const [tab, setTab] = useState<OrganizationTab>('Members');
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 500);

  const joinRequestCount = organization?.joinRequests.length || 0;

  const filteredMembers = useMemo(
    () =>
      organization?.members.filter((member) => {
        return getUserDisplayName(member.user).toLowerCase().includes(debouncedQuery.toLowerCase());
      }) || [],
    [organization, debouncedQuery],
  );

  const totalCount = filteredMembers.length;

  if (isError || (!isLoading && !currentMember))
    return (
      <Flex sx={{ width: '100%', height: '100%' }} align="center" justify="center">
        <ErrorPlaceholder />
      </Flex>
    );

  const renderContent = () => {
    if (isLoading)
      return (
        <DataEmptyPlaceholder
          icon={<Loader size={40} />}
          title="Loading organization..."
          description="Please wait while the info of your organization is being loaded."
        />
      );

    if (!totalCount)
      return (
        <DataEmptyPlaceholder
          title="No members found"
          description="No members in your organization match the current criteria."
        />
      );

    if (!organization || !currentMember)
      return (
        <DataEmptyPlaceholder
          title="No organization found"
          description="The organization you are looking for does not exist."
        />
      );

    if (tab === 'Members') {
      return (
        <Flex direction="column" sx={{ width: '100%' }}>
          <OrganizationMembersTable
            currentMember={currentMember}
            members={filteredMembers}
            query={debouncedQuery}
            organizationId={organization.id}
          />
        </Flex>
      );
    }

    if (tab === 'Requests') {
      return <OrganizationRequestsTab organization={organization} />;
    }

    if (tab === 'Usage') {
      return <UsageLayout organization={organization} />;
    }

    return <BillingLayout organization={organization} />;
  };

  const canUsage = currentMember && canViewUsage(currentMember);
  const canBilling = currentMember && canViewBilling(currentMember);
  const organizationDisplayName = organization ? getOrganizationDisplayName(organization) : 'Organization';
  const canInvite = currentMember && canInviteMembers(currentMember) && !['Usage', 'Billing'].includes(tab);
  const canAcceptRequests = currentMember && canManageRequests(currentMember);
  const canDisplayTabs = (canInvite && canAcceptRequests) || canUsage || canBilling;

  const availableTabs = [
    'Members',
    canAcceptRequests ? 'Requests' : null,
    // canUsage ? 'Usage' : null,
    // canBilling ? 'Billing' : null,
  ].filter(truthy);

  return (
    <Flex direction="column">
      <PageAIHeader
        headerItems={
          <Anchor to={window.location.href} component={Link}>
            <Flex align="center" gap={6}>
              <Avatar src={organization?.avatarUrl} alt={organizationDisplayName} radius="50%" size={24} />

              <Text color="dark" fw={500}>
                {organizationDisplayName}
              </Text>
            </Flex>
          </Anchor>
        }
        headerSuffix={
          <Flex align="center" gap="xs" ml="xs">
            <Flex sx={(theme) => ({ width: 4, height: 4, background: theme.colors.dark[4], borderRadius: '50%' })} />

            {isLoading ? (
              <Skeleton width={64} height={24} />
            ) : (
              <Text fw={500} fz="0.875rem" color="dark.3" sx={{ whiteSpace: 'nowrap' }}>
                {totalCount} {singularOrPlural('member', 'members')(totalCount)}
              </Text>
            )}

            {canDisplayTabs && (
              <Flex pos="relative">
                <SegmentedControl
                  size="xs"
                  data={availableTabs}
                  value={tab}
                  sx={{
                    '.ghost-SegmentedControl-label': {
                      width: 96,
                    },
                  }}
                  onChange={(value) => setTab(value as OrganizationTab)}
                />

                {joinRequestCount > 0 && canAcceptRequests && (
                  <Flex
                    sx={(theme) => ({
                      position: 'absolute',
                      zIndex: 1,
                      top: 8,
                      right: 16,
                      width: 8,
                      height: 8,
                      borderRadius: '50%',
                      backgroundColor: theme.colors.red[6],
                    })}
                  />
                )}
              </Flex>
            )}
          </Flex>
        }
        rightSection={
          <Flex align="center" gap="xs">
            {tab === 'Members' && (
              <TextInput
                icon={<IconSearch size={16} />}
                placeholder="Search for members..."
                value={query}
                onChange={(event) => setQuery(event.target.value)}
                rightSection={(query && isLoading) || query !== debouncedQuery ? <Loader size={12} /> : null}
                w={240}
              />
            )}

            {organization && canInvite && <InviteMemberButton organization={organization} />}
          </Flex>
        }
      />

      <ScrollArea sx={{ height: `calc(100vh - ${PAGE_AI_HEADER_HEIGHT}px)`, overflow: 'auto' }}>
        <Box p="md" pt={4}>
          <Panel
            sx={{
              width: '100%',
              height: 'fit-content',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              padding: 16,
              gap: 4,
            }}
          >
            {renderContent()}
          </Panel>
        </Box>
      </ScrollArea>
    </Flex>
  );
};

export const OrganizationPage = withPageWrapper(OrganizationPageBase);
