import { memo, useMemo } from 'react';
import { Badge, Tooltip } from '@mantine/core';

import { FileStatusBadgeProps } from './FileStatusBadge.types';
import {
  FILE_DISPLAY_STATUS_COLOR_MAPPING,
  FileDisplayStatus,
  getFileDisplayStatus,
  getFileTextExtractionProgress,
} from '@/shared/services/files';
import { toPercent } from '@/shared/utils/number';
import { isFileAssetUnrecognized } from '@/pageAI/services/files';

const FileStatusBadgeBase: React.FC<FileStatusBadgeProps> = ({ file }) => {
  const fileDisplayStatus = getFileDisplayStatus(file);

  const textExtractionProgress = useMemo(() => getFileTextExtractionProgress(file), [file]);

  const textExtractionProgressPercentContent = useMemo(
    () =>
      fileDisplayStatus === 'OCR In Progress' &&
      typeof textExtractionProgress === 'number' && <>&nbsp;({toPercent(textExtractionProgress)}%)</>,
    [textExtractionProgress, fileDisplayStatus],
  );

  if (isFileAssetUnrecognized(file)) {
    return (
      <Tooltip label="File type not recognized. Please review before generating summaries." withArrow withinPortal>
        <Badge variant="dot" color="orange" data-testid="file-status-badge">
          Unrecognized
        </Badge>
      </Tooltip>
    );
  }

  const badge = (
    <Badge variant="dot" color={FILE_DISPLAY_STATUS_COLOR_MAPPING[fileDisplayStatus]} data-testid="file-status-badge">
      {fileDisplayStatus}
      {textExtractionProgressPercentContent}
    </Badge>
  );

  if (fileDisplayStatus === FileDisplayStatus.CORRUPTED) {
    return (
      <Tooltip
        label="PDF cannot be read. Try opening on your computer and re-download from the source if corrupted. If you generate summaries, this file will be skipped."
        withArrow
        withinPortal
      >
        {badge}
      </Tooltip>
    );
  }

  return badge;
};

export const FileStatusBadge = memo(FileStatusBadgeBase);
