import { memo, useMemo, useRef, useState } from 'react';
import { Table, useMantineTheme } from '@mantine/core';
import { IconChevronDown, IconChevronUp, IconSelector } from '@tabler/icons-react';

import { AssetType, FileAsset } from '@/shared/@types';
import { sortAlphabetically } from '@/shared/utils/string';
import { sortDate } from '@/shared/utils/date';
import { UnrecognizedFileRow } from '../UnrecognizedFileRow';
import { isFileFromEFolder } from '@/pageAI/services/files';
import { useLayoutStore } from '@/shared/states/layout';

interface UnrecognizedFileTableProps {
  fileAssets: FileAsset[];
}

const headers = ['#', 'Cases', 'Original File Type', 'New File Type', 'Received Date', 'Size'];

const UnrecognizedFileTableBase = ({ fileAssets }: UnrecognizedFileTableProps) => {
  const theme = useMantineTheme();
  const isRightSidebarOpened = useLayoutStore((state) => state.isRightSidebarOpened);

  const tableRef = useRef<HTMLTableElement | null>(null);
  const [sortedColumnName, setSortedColumnName] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | null>(null);

  const sortedAssetItems = useMemo(() => {
    let sorted = [...fileAssets].sort((assetA, assetB) => {
      if (!sortedColumnName || sortedColumnName === 'Original File Type') {
        return sortAlphabetically(
          assetA.metadata?.pageAIMetadata?.vaFileTypeName || assetA.name,
          assetB.metadata?.pageAIMetadata?.vaFileTypeName || assetB.name,
        );
      }

      if (sortedColumnName === 'Cases') {
        return isFileFromEFolder(assetA) ? -1 : 1;
      }

      if (sortedColumnName === '#') {
        return sortAlphabetically(assetA.index || '', assetB.index || '');
      }

      if (sortedColumnName === 'Received Date') {
        return sortDate(assetA.fileReceiveDate || new Date(), assetB.fileReceiveDate || new Date());
      }

      if (sortedColumnName === 'Size') {
        if (assetA.type === AssetType.FILE && assetB.type === AssetType.FILE) {
          if (typeof assetA.metadata?.totalPages === 'number' && typeof assetB.metadata?.totalPages === 'number') {
            return assetA.metadata.totalPages - assetB.metadata.totalPages;
          }
        }

        const sizeA = assetA.size || 0;
        const sizeB = assetB.size || 0;

        return sizeA - sizeB;
      }

      return 0;
    });

    if (sortOrder === 'asc') sorted = sorted.reverse();

    return sorted;
  }, [fileAssets, sortedColumnName, sortOrder]);

  const handleSortColumn = (columnName: string) => () => {
    setSortedColumnName(columnName);

    if (sortOrder === null || sortedColumnName !== columnName) return setSortOrder('desc');

    if (sortOrder === 'desc') return setSortOrder('asc');

    if (sortOrder === 'asc') {
      setSortedColumnName(null);

      return setSortOrder(null);
    }
  };

  const renderTableHeader = () => {
    return (
      <thead>
        <tr
          className="unrecognized-file-table-header-row"
          style={{
            position: 'sticky',
            top: -1,
            background: 'white',
            border: `1px solid ${theme.colors.orange[2]}`,
            outline: `1px solid ${theme.colors.orange[2]}`,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            zIndex: 3,
          }}
        >
          {headers.map((header, headerIndex) => {
            if (header === 'Size' && isRightSidebarOpened) return null;

            return (
              <th key={header} onClick={handleSortColumn(header)}>
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 4,
                    ...(header === 'Received Date'
                      ? { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }
                      : {}),
                  }}
                >
                  {header}

                  {sortedColumnName === header ? (
                    <>{sortOrder === 'asc' ? <IconChevronUp size={14} /> : <IconChevronDown size={14} />}</>
                  ) : (
                    <IconSelector size={14} />
                  )}
                </span>
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  const renderTableBody = () => {
    return (
      <tbody>
        {sortedAssetItems.map((fileAsset, rowIndex) => {
          return (
            <UnrecognizedFileRow
              key={fileAsset.id}
              fileAsset={fileAsset}
              rowIndex={rowIndex}
              style={{
                ...(rowIndex === sortedAssetItems.length - 1 && {
                  border: `1px solid ${theme.colors.orange[2]}`,
                  borderBottomLeftRadius: 4,
                  borderBottomRightRadius: 4,
                }),
              }}
              hiddenColumns={isRightSidebarOpened ? ['Size'] : undefined}
            />
          );
        })}
      </tbody>
    );
  };

  return (
    <Table
      ref={tableRef}
      className="asset-table"
      highlightOnHover={!!fileAssets.length}
      sx={(theme) => ({
        tableLayout: 'fixed',
        background: theme.white,
        '&.ghost-Table-root.asset-table': {
          outline: 'none',
        },
        th: {
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: theme.colors.gray[1],
          },
        },
        '[data-active=true]': {
          background: theme.colors.gray[1],
        },
      })}
    >
      <colgroup>
        <col style={{ width: '3%', minWidth: 100 }} />
        <col style={{ width: '15%' }} />
        <col style={{ width: '27.5%' }} />
        <col style={{ width: '27.5%' }} />
        <col style={{ width: '15%' }} />
        {!isRightSidebarOpened && <col style={{ width: '10%' }} />}
      </colgroup>

      {renderTableHeader()}

      {renderTableBody()}
    </Table>
  );
};

export const UnrecognizedFileTable = memo(UnrecognizedFileTableBase);
