import { memo } from 'react';
import { ActionIcon, Flex, Spoiler, Sx, Tooltip } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import { addNoteHasReferencesSelector, resetAddNoteState, useNotesStore } from '@/pageAI/states/notes';
import { MarkdownRenderer } from '@/shared/components/common/markdown/MarkdownRenderer';
import { UnderlinedTextButton } from '@/shared/designSystem/UnderlinedTextButton';

interface NoteSnippetProps {
  snippet: string | undefined;
  sx?: Sx;
  removable?: boolean;
  isMarkdown?: boolean;
}

const NoteFileSnippetBase = ({ snippet, sx, removable = false, isMarkdown = false }: NoteSnippetProps) => {
  const hasReferences = useNotesStore(addNoteHasReferencesSelector);

  if (snippet) {
    return (
      <Flex
        align="center"
        gap="xs"
        pos="relative"
        sx={(theme) => ({
          padding: '4px 8px',
          borderRadius: 4,
          border: `1px solid ${theme.colors.gray[2]}`,
          background: theme.colors.gray[2],
          transition: 'all 0.1s ease-in-out',
          width: '100%',
          ...sx,
        })}
      >
        <Spoiler
          maxHeight={40}
          showLabel="Show more"
          hideLabel="Show less"
          fz="0.75rem"
          className="normalized-markdown"
          sx={(theme) => ({
            '*:not(.ghost-Spoiler-control)': {
              fontSize: '0.75rem',
              wordBreak: 'break-word',
            },
            '.ghost-Spoiler-control': {
              color: theme.colors.dark[4],
              fontWeight: 600,
            },
            ul: {
              margin: 0,
            },
          })}
        >
          {isMarkdown ? <MarkdownRenderer content={snippet} /> : snippet}
        </Spoiler>

        {removable && (
          <Tooltip label="Clear references" withinPortal withArrow>
            <ActionIcon size="xs" pos="absolute" right={2} bottom={4} onClick={() => resetAddNoteState()}>
              <IconX size={14} />
            </ActionIcon>
          </Tooltip>
        )}
      </Flex>
    );
  }

  if (hasReferences) {
    return (
      <Flex justify="end" sx={{ width: '100%' }}>
        <UnderlinedTextButton color="gray.7" sx={{ fontSize: '0.75rem' }} onClick={resetAddNoteState}>
          Clear references
        </UnderlinedTextButton>
      </Flex>
    );
  }

  return null;
};

export const NoteFileSnippet = memo(NoteFileSnippetBase);
