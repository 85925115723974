import { memo } from 'react';
import { ActionIcon, clsx, Tooltip } from '@mantine/core';

import { CaseTimelineEvent } from '@/pageAI/@types/summaries';
import { takeNoteFromTimelineEvent } from '@/pageAI/services/notes';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';

import IconNotePlus from '@/shared/icons/note-plus.svg?react';

interface TimelineEventTakeNoteButtonProps {
  event: CaseTimelineEvent;
  className?: string;
}

const TimelineEventTakeNoteButtonBase = ({ event, className }: TimelineEventTakeNoteButtonProps) => {
  const client = useSelectedClient(true);

  return (
    <Tooltip key={event.id} label="Take note" withinPortal withArrow>
      <ActionIcon
        size="xs"
        color="gray.7"
        className={clsx('unprintable', className)}
        onClick={() => takeNoteFromTimelineEvent({ client, event })}
      >
        <IconNotePlus width={16} height={16} />
      </ActionIcon>
    </Tooltip>
  );
};

export const TimelineEventTakeNoteButton = memo(TimelineEventTakeNoteButtonBase);
