import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  CreateNoteInput,
  CreateNoteMutation,
  DeleteNoteInput,
  DeleteNoteMutation,
  Note_FullFieldsFragment,
  UpdateNoteInput,
  UpdateNoteMutation,
} from '@/pageAI/gql/graphql';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { createNoteMutation, deleteNoteMutation, updateNoteMutation } from '@/pageAI/api';
import { ClientNotesQuery, queryMap } from '../useNotes';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';

export const useManageNotes = (clientId: string | undefined) => {
  const queryClient = useQueryClient();
  const { notify } = useNotifications();
  const { request } = useGraphQLRequest();

  const addNoteLocally = useCallback(
    (note: Note_FullFieldsFragment) => {
      const notesQuery = queryClient.getQueryData<ClientNotesQuery>(queryMap.client.notes.queryKey(clientId));

      if (!notesQuery) return;

      queryClient.setQueryData<ClientNotesQuery>(queryMap.client.notes.queryKey(clientId), [note, ...notesQuery]);

      return () => queryClient.setQueryData<ClientNotesQuery>(queryMap.client.notes.queryKey(clientId), notesQuery);
    },
    [queryClient, clientId],
  );

  const updateNoteLocally = useCallback(
    (note: Note_FullFieldsFragment) => {
      const notesQuery = queryClient.getQueryData<ClientNotesQuery>(queryMap.client.notes.queryKey(clientId));

      if (!notesQuery) return;

      queryClient.setQueryData<ClientNotesQuery>(
        queryMap.client.notes.queryKey(clientId),
        notesQuery.map((n) => (n.id === note.id ? note : n)),
      );

      return () => queryClient.setQueryData<ClientNotesQuery>(queryMap.client.notes.queryKey(clientId), notesQuery);
    },
    [queryClient, clientId],
  );

  const deleteNoteLocally = useCallback(
    (noteId: string) => {
      const notesQuery = queryClient.getQueryData<ClientNotesQuery>(queryMap.client.notes.queryKey(clientId));

      if (!notesQuery) return;

      queryClient.setQueryData<ClientNotesQuery>(
        queryMap.client.notes.queryKey(clientId),
        notesQuery.filter((note) => note.id !== noteId),
      );

      return () => queryClient.setQueryData<ClientNotesQuery>(queryMap.client.notes.queryKey(clientId), notesQuery);
    },
    [queryClient, clientId],
  );

  const { mutateAsync: addNoteBase, isLoading: isAdding } = useMutation<CreateNoteMutation, Error, CreateNoteInput>({
    mutationFn: async (input) => {
      const responseBody = await request(createNoteMutation, { input });

      return responseBody;
    },
  });

  const addNote = useCallback(
    async (input: CreateNoteInput) => {
      try {
        const responseBody = await addNoteBase(input);

        if (responseBody.createNote) addNoteLocally(responseBody.createNote.note);

        notify('Success', 'The note has been added for the client.', 'brand');
      } catch (error) {
        notify('Error', 'Failed to add note');
      }
    },
    [addNoteBase, addNoteLocally, notify],
  );

  const { mutateAsync: updateNoteBase, isLoading: isUpdating } = useMutation<
    UpdateNoteMutation,
    Error,
    UpdateNoteInput
  >({
    mutationFn: (input) => request(updateNoteMutation, { input }),
  });

  const updateNote = useCallback(
    async (input: UpdateNoteInput) => {
      try {
        const responseBody = await updateNoteBase(input);

        if (responseBody.updateNote) updateNoteLocally(responseBody.updateNote.note);
      } catch (error) {
        notify('Error', 'Failed to update note');
      }
    },
    [updateNoteBase, updateNoteLocally, notify],
  );

  const { mutateAsync: deleteNoteBase, isLoading: isDeleting } = useMutation<
    DeleteNoteMutation,
    Error,
    DeleteNoteInput
  >({
    mutationFn: (input) => request(deleteNoteMutation, { input }),
  });

  const deleteNote = useCallback(
    async (input: DeleteNoteInput) => {
      try {
        deleteNoteLocally(input.noteId);

        await deleteNoteBase(input);
      } catch (error) {
        notify('Error', 'Failed to delete note');
      }
    },
    [deleteNoteBase, deleteNoteLocally, notify],
  );

  return {
    addNote,
    isAdding,
    updateNote,
    isUpdating,
    deleteNote,
    isDeleting,
  };
};
