import { memo } from 'react';
import { Flex } from '@mantine/core';

import { useMouseDragging } from '@/shared/hooks/mouse/useMouseDragging';

interface NotepadResizeHandlesProps {
  containerX: number;
  containerY: number;
  containerWidth: number;
  containerHeight: number;
  onResize: ({
    newX,
    newY,
    newWidth,
    newHeight,
  }: {
    newX: number;
    newY: number;
    newWidth: number;
    newHeight: number;
  }) => void;
}

const NotepadResizeHandlesBase = ({
  containerX,
  containerY,
  containerWidth,
  containerHeight,
  onResize,
}: NotepadResizeHandlesProps) => {
  const { onMouseDown: onLeftHandleMouseDown } = useMouseDragging({
    onDragging: (deltaX) => {
      onResize({
        newX: containerX + deltaX,
        newY: containerY,
        newWidth: containerWidth - deltaX,
        newHeight: containerHeight,
      });
    },
    stopPropagation: true,
  });

  const { onMouseDown: onRightHandleMouseDown } = useMouseDragging({
    onDragging: (deltaX) => {
      onResize({
        newX: containerX,
        newY: containerY,
        newWidth: containerWidth + deltaX,
        newHeight: containerHeight,
      });
    },
    stopPropagation: true,
  });

  const { onMouseDown: onTopHandleMouseDown } = useMouseDragging({
    onDragging: (deltaX, deltaY) => {
      onResize({
        newX: containerX,
        newY: containerY + deltaY,
        newWidth: containerWidth,
        newHeight: containerHeight - deltaY,
      });
    },
    stopPropagation: true,
  });

  const leftHandle = (
    <Flex
      pos="absolute"
      sx={(theme) => ({
        left: 0,
        background: theme.colors.dark[3],
        width: 1.5,
        height: '100%',
        cursor: 'ew-resize',
        zIndex: 1,
        userSelect: 'none',
      })}
      onMouseDownCapture={onLeftHandleMouseDown}
    />
  );

  const rightHandle = (
    <Flex
      pos="absolute"
      sx={(theme) => ({
        right: 0,
        background: theme.colors.dark[3],
        width: 1.5,
        height: '100%',
        cursor: 'ew-resize',
        zIndex: 300,
        userSelect: 'none',
      })}
      onMouseDownCapture={onRightHandleMouseDown}
    />
  );

  const topHandle = (
    <Flex
      pos="absolute"
      sx={(theme) => ({
        top: 1,
        background: theme.colors.dark[3],
        width: '100%',
        height: 1.5,
        cursor: 'ns-resize',
        zIndex: 300,
        userSelect: 'none',
      })}
      onMouseDownCapture={onTopHandleMouseDown}
    />
  );

  return (
    <>
      {topHandle}
      {rightHandle}
      {leftHandle}
    </>
  );
};

export const NotepadResizeHandles = memo(NotepadResizeHandlesBase);
