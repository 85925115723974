import { memo, useCallback, useRef, useState } from 'react';
import { Flex, Text, Tooltip, clsx, createStyles } from '@mantine/core';

import { formatDate, formatShortDate, parseDateOnly } from '@/shared/utils/date';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { singularOrPlural } from '@/shared/utils/string';
import { posthog } from '@/shared/plugins/posthog';
import { FileAsset } from '@/shared/@types';
import { FileNameSelect } from '../FileNameSelect';
import { useUpdateFileMetadata } from '@/pageAI/hooks/files/useUpdateFileMetadata/useUpdateFileMetadata';
import { getFileDisplayName, isFileFromEFolder } from '@/pageAI/services/files';
import { openRightSidebar } from '@/shared/states/layout';
import { setFilePreviewAsset } from '@/shared/states/filePreview';
import { setSelectedClientFile, useClientFileIndexStore } from '@/pageAI/states/client/clientFileIndex.states';
import {
  setUnrecognizedFileNewValue,
  unrecognizedFileNewValueSelector,
  useUnrecognizedFilesStore,
} from '@/pageAI/states/unrecognizedFiles';
import { DateInput } from '@mantine/dates';
import { IconChevronDown } from '@tabler/icons-react';

export interface UnrecognizedFileRowProps {
  fileAsset: FileAsset;
  rowIndex: number;
  style?: React.CSSProperties;
  onClick?: (event: React.MouseEvent, rowIndex: number, fileAsset: FileAsset) => void;
  hiddenColumns?: string[];
}

const useStyles = createStyles((theme) => ({
  tableRow: {
    borderLeft: `1px solid ${theme.colors.orange[2]}`,
    borderRight: `1px solid ${theme.colors.orange[2]}`,
  },
}));

const UnrecognizedFileRowBase = ({ fileAsset, rowIndex, style, onClick, hiddenColumns }: UnrecognizedFileRowProps) => {
  const client = useSelectedClient(true);

  const { classes } = useStyles();
  const oldFileType = useRef(fileAsset.metadata?.pageAIMetadata?.vaFileTypeName || fileAsset.name);
  const newFileType = useUnrecognizedFilesStore(unrecognizedFileNewValueSelector(client.id, fileAsset.id));
  const [date, setDate] = useState(parseDateOnly(fileAsset.fileReceiveDate));
  const { updateFileMetadata } = useUpdateFileMetadata();

  const isSelected = useClientFileIndexStore((state) => state.selectedFileMapping[fileAsset.id]);

  const trRef = useRef<HTMLTableRowElement>(null);

  const setNewFileType = useCallback(
    (value: string) => {
      setUnrecognizedFileNewValue(client.id)(fileAsset.id, value);
    },
    [client.id, fileAsset.id],
  );

  const handleSelectFile = useCallback(
    (event: React.MouseEvent) => {
      openRightSidebar();
      setFilePreviewAsset(fileAsset, getFileDisplayName(fileAsset));
      setSelectedClientFile(fileAsset.id);

      onClick?.(event, rowIndex, fileAsset);

      posthog.capture('[Files] Select file', {
        fileId: fileAsset.id,
        fileName: fileAsset.name,
        fileIndex: fileAsset.metadata?.pageAIMetadata?.vaFileTypeName,
      });
    },
    [fileAsset, onClick, rowIndex],
  );

  const handleChangeNewFileType = useCallback(
    async (value: string) => {
      setNewFileType(value);

      posthog.capture('[Files] Change file type', {
        clientId: client?.id,
        clientFirstName: client?.firstName,
        clientLastName: client?.lastName,
        fileId: fileAsset.id,
        fileIndex: fileAsset.metadata?.pageAIMetadata?.indexNumber,
        oldFileType: oldFileType.current,
        newFileType: value,
      });

      try {
        await updateFileMetadata({
          fileId: fileAsset.id,
          newData: {
            vaFileTypeName: value,
          },
        });
      } catch {
        setNewFileType(oldFileType.current);
      }
    },
    [fileAsset, updateFileMetadata, client, setNewFileType],
  );

  const handleChangeNewDate = useCallback(
    async (date: Date) => {
      setDate(date);
      const newDate = formatShortDate(date);

      try {
        await updateFileMetadata({
          fileId: fileAsset.id,
          newData: {
            fileReceiveDate: newDate,
          },
        });
      } catch {
        setDate(parseDateOnly(fileAsset.fileReceiveDate));
      }
    },
    [fileAsset, updateFileMetadata],
  );

  const formattedDate = date ? formatDate(parseDateOnly(date)) : '-';

  return (
    <tr
      key={fileAsset.id}
      ref={trRef}
      className={clsx('file-row', ['row', rowIndex].join('-'), classes.tableRow)}
      {...(isSelected ? { 'data-active': true } : {})}
      data-index={rowIndex}
      onClick={handleSelectFile}
      style={{
        cursor: 'pointer',
        userSelect: 'none',
        transition: 'all 0.1s',
        height: 40,
        ...style,
      }}
      data-testid={fileAsset.metadata?.pageAIMetadata?.indexNumber}
    >
      <td style={{ fontWeight: 500 }} data-testid="file-id">
        {fileAsset.metadata?.pageAIMetadata?.indexNumber || fileAsset.index || rowIndex + 1}
      </td>

      <td data-testid="cases">
        <Text
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {isFileFromEFolder(fileAsset) ? 'From eFolder' : 'Outside eFolder format'}
        </Text>
      </td>

      <td data-testid="file-name">
        <Tooltip label={oldFileType.current} withinPortal withArrow openDelay={500}>
          <Text
            className="file-name"
            fw={500}
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {oldFileType.current}
          </Text>
        </Tooltip>
      </td>

      <td>
        <FileNameSelect fileAsset={fileAsset} value={newFileType} onChange={handleChangeNewFileType} />
      </td>

      <td onClick={(event) => event.stopPropagation()}>
        <Flex
          align="center"
          sx={(theme) => ({
            width: 'fit-content',
            borderBottom: `1px solid ${theme.colors.dark[3]}`,
          })}
        >
          <Tooltip label={formattedDate} withArrow withinPortal openDelay={500}>
            <DateInput
              value={date}
              onChange={handleChangeNewDate}
              popoverProps={{
                withinPortal: true,
              }}
              color="dark"
              onClick={(event) => event.stopPropagation()}
              sx={(theme) => ({
                width: 'fit-content',
                '.ghost-DateInput-input': {
                  background: 'none',
                  border: 'none',
                  padding: 0,
                  borderRadius: 0,
                  fontWeight: 500,
                  height: 20,
                  minHeight: 20,
                  minWidth: 140,
                  maxWidth: 140,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },
              })}
            />
          </Tooltip>

          <Flex>
            <IconChevronDown size={14} />
          </Flex>
        </Flex>
      </td>

      {!hiddenColumns?.includes('Size') ? (
        <td>
          <Text fz="0.875rem" color="dark" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {fileAsset.metadata?.totalPages} {singularOrPlural('page', 'pages')(fileAsset.metadata?.totalPages)}
          </Text>
        </td>
      ) : null}
    </tr>
  );
};

export const UnrecognizedFileRow = memo(UnrecognizedFileRowBase);
