import { graphql } from '@/pageAI/gql';

export const userCommonFieldsFragment = graphql(/* GraphQL */ `
  fragment User_CommonFields on User {
    id
    firstName
    lastName
    email
    avatarUrl
    phone
    role
    createdAt
    updatedAt
  }
`);

export const currentUserQuery = graphql(/* GraphQL */ `
  query Viewer {
    viewer {
      id
      firstName
      lastName
      email
      avatarUrl
      phone
      role
      createdAt
      updatedAt
      organizations(first: 1) {
        nodes {
          id
          name
          avatarUrl
          joinRequests {
            nodes {
              id
            }
          }
          createdAt
          updatedAt
        }
        totalCount
      }
    }
  }
`);

export const updateUserProfileMutation = graphql(/* GraphQL */ `
  mutation UpdateUserProfile($input: UpdateUserProfileInput!) {
    updateUserProfile(input: $input) {
      user {
        id
        firstName
        lastName
        email
        avatarUrl
        phone
        role
        createdAt
        updatedAt
      }
      clientMutationId
    }
  }
`);
