import { memo } from 'react';
import { ActionIcon, Badge, Flex, Popover, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconChevronDown, IconChevronUp, IconInfoCircle } from '@tabler/icons-react';

import { CombinedRatingBadgeProps } from './CombinedRatingBadge.types';
import { isCombinedRatingEmpty } from '@/pageAI/services/medicalConditions';
import { useLayoutStore } from '@/shared/states/layout';

const CombinedRatingBadgeBase = ({ client }: CombinedRatingBadgeProps) => {
  const [opened, { toggle, close }] = useDisclosure();
  const isRightSidebarOpened = useLayoutStore((state) => state.isRightSidebarOpened);

  const isEmpty = isCombinedRatingEmpty(client.latestCombinedRating);

  const renderBadgeContent = () => {
    if (isEmpty) return 'No combined rating';

    return (
      <Flex align="center" gap={2}>
        Current combined rating
        {opened ? <IconChevronUp size={14} /> : <IconChevronDown size={14} />}
      </Flex>
    );
  };

  const renderBody = () => {
    if (isRightSidebarOpened)
      return (
        <Tooltip {...(opened ? { opened: false } : {})} label="Current combined rating" withArrow withinPortal>
          <ActionIcon onClick={toggle} color={isEmpty ? 'red.7' : 'gray.7'} ml={-4}>
            <IconInfoCircle size={20} />
          </ActionIcon>
        </Tooltip>
      );

    return (
      <Badge
        size="lg"
        variant="outline"
        color={isEmpty ? 'red.7' : 'dark.4'}
        sx={(theme) => ({
          textTransform: 'none',
          ...(!isEmpty && {
            cursor: 'pointer',
            transition: 'all 0.1s ease-in-out',
            '&:hover': {
              backgroundColor: theme.colors.gray[1],
            },
          }),
          '.ghost-Badge-inner': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        })}
        onClick={toggle}
      >
        {renderBadgeContent()}
      </Badge>
    );
  };

  return (
    <Popover shadow="sm" onClose={close} opened={opened} withArrow withinPortal offset={4}>
      <Popover.Target>{renderBody()}</Popover.Target>

      <Popover.Dropdown>
        <Text fz="0.875rem" color="dark" sx={{ maxWidth: 640 }}>
          {client.latestCombinedRating}
        </Text>
      </Popover.Dropdown>
    </Popover>
  );
};

export const CombinedRatingBadge = memo(CombinedRatingBadgeBase);
