import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { ActionIcon, Flex, Popover, ScrollArea, Text, TextInput } from '@mantine/core';
import { IconInfoTriangle, IconSearch, IconX } from '@tabler/icons-react';

import { NoteItem } from '../NoteItem';
import { Note_FullFieldsFragment } from '@/pageAI/gql/graphql';
import { AddNoteContainer, AddNoteRef } from '../AddNoteContainer';
import { NoteFilters } from '../NoteFilters';
import {
  setNoteConditionEntryKey,
  setFileSnippet,
  useNotesStore,
  setNoteTimelineEvent,
  setNoteFileAsset,
  setNoteCondition,
} from '@/pageAI/states/notes';
import { openNotepadEventBus, startTakingNoteEventBus } from '@/pageAI/services/notes';
import { NotepadResizeHandles } from '../NotepadResizeHandles';
import { NotepadResizeBottomHandle } from '../NotepadResizeHandles/NotepadResizeBottomHandle';
import { useFilePreviewStore } from '@/shared/states/filePreview';
import { useUnifiedStore } from '@/pageAI/states/unified';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';

interface NoteListContainerProps {
  notes: Note_FullFieldsFragment[];
  x: number;
  y: number;
  setX: (x: number) => void;
  setY: (y: number) => void;
  onOpen?: () => void;
  onClose?: () => void;
  isDragging?: boolean;
}

const NoteListContainerBase = ({
  notes,
  x,
  y,
  setX,
  setY,
  onOpen,
  onClose,
  isDragging = false,
}: NoteListContainerProps) => {
  const client = useSelectedClient(true);

  const conditionFilter = useNotesStore((state) => state.conditionFilter);
  const authorFilter = useNotesStore((state) => state.authorFilter);
  const isFullscreen = useNotesStore((state) => state.isFullscreen);
  const [searchValue, setSearchValue] = useState('');
  const [isAddingNote, setIsAddingNote] = useState(false);
  const addNoteRef = useRef<AddNoteRef>(null);
  const [width, setWidth] = useState(524);
  const [height, setHeight] = useState(640);
  const [showSearchHint, setShowSearchHint] = useState(false);

  // useRightSidebarSize((rightSidebarWidth) => {
  //   if (!isFullscreen) return;

  //   setFullscreenWidth(window.innerWidth - rightSidebarWidth - 2);
  // });

  useEffect(() => {
    const unsubscribe = startTakingNoteEventBus.subscribe(
      ({ snippet, isMarkdown = false, conditionEntryKey, event, fileAsset }) => {
        const selectedCondition = useUnifiedStore.getState().selectedSimpleCondition;

        if (selectedCondition) {
          setNoteCondition(selectedCondition);
        }

        document.querySelector('.add-note-container')?.scrollIntoView({ block: 'start' });

        if (fileAsset) {
          setNoteFileAsset(fileAsset);
        } else {
          const currentlyOpenedFileAsset = useFilePreviewStore.getState().asset;

          setNoteFileAsset(currentlyOpenedFileAsset || null);
        }

        if (snippet) setFileSnippet(snippet, isMarkdown);

        setNoteTimelineEvent(event || null);
        setNoteConditionEntryKey(conditionEntryKey || '');

        onOpen?.();

        addNoteRef.current?.expand(true);
      },
    );

    return unsubscribe;
  }, [onOpen]);

  useEffect(() => {
    const unsubscribe = openNotepadEventBus.subscribe(({ searchValue }) => {
      onOpen?.();

      if (searchValue) {
        setSearchValue(searchValue);

        setShowSearchHint(true);

        setTimeout(() => {
          setShowSearchHint(false);
        }, 5000);
      }
    });

    return unsubscribe;
  }, [onOpen]);

  const filteredNotes = useMemo(
    () =>
      notes
        .filter((note) => {
          if (!searchValue) return true;

          const loweredCaseSearchValue = searchValue.toLowerCase();

          return (
            note.content.toLowerCase().includes(loweredCaseSearchValue) ||
            note.file?.metadata.pageAIMetadata?.indexNumber.toLowerCase().includes(loweredCaseSearchValue) ||
            note.file?.name.toLowerCase().includes(loweredCaseSearchValue) ||
            note.medicalCondition?.headerCondition.toLowerCase().includes(loweredCaseSearchValue)
          );
        })
        .filter((note) => {
          if (!conditionFilter) return true;

          return note.medicalCondition?.id === conditionFilter.id;
        })
        .filter((note) => {
          if (!authorFilter) return true;

          return note.author?.id === authorFilter.id;
        }),
    [notes, searchValue, conditionFilter, authorFilter],
  );

  const hasFilters = !!conditionFilter || !!searchValue;

  const renderContent = () => {
    if (!filteredNotes.length)
      return (
        <Flex px={12} align="center" justify="center" pt={16} pb={28}>
          <Text fz="0.875rem" color="dark.4">
            {hasFilters
              ? 'There are no notes matching the current criteria.'
              : 'There are no notes for this client yet.'}
          </Text>
        </Flex>
      );

    return (
      <Flex p={12} pt={4} sx={{ width: '100%', cursor: 'initial' }} direction="column" gap="xs">
        {filteredNotes.map((note) => {
          return <NoteItem key={note.id} note={note} />;
        })}
      </Flex>
    );
  };

  const renderBody = () => {
    return (
      <>
        <Flex
          align="center"
          justify="space-between"
          gap="xs"
          p={12}
          sx={{
            width: '100%',
            ...(isFullscreen
              ? {}
              : {
                  cursor: isDragging ? 'grabbing' : 'grab',
                }),
          }}
        >
          <Flex align="center" gap="xs">
            <Text color="dark.4" fw={600} fz="0.875rem" sx={{ userSelect: 'none' }}>
              Notes
            </Text>
          </Flex>

          <Flex align="center" gap={4}>
            {/* {isFullscreen ? (
              <Tooltip label="Minimize" withArrow withinPortal key={String(isFullscreen)}>
                <ActionIcon size="xs" onClick={toggleNotepadFullscreen}>
                  <IconMinimize size={14} />
                </ActionIcon>
              </Tooltip>
            ) : (
              <Tooltip label="Maximize" withArrow withinPortal key={String(isFullscreen)}>
                <ActionIcon size="xs" onClick={toggleNotepadFullscreen}>
                  <IconMaximize size={14} />
                </ActionIcon>
              </Tooltip>
            )} */}

            <Flex align="center" gap={4}>
              <Flex
                align="center"
                gap="xs"
                pr={4}
                sx={(theme) => ({
                  '*': {
                    color: theme.colors.gray[6],
                  },
                })}
              >
                <NoteFilters notes={notes} />

                <Popover opened={showSearchHint} shadow="sm" withArrow withinPortal position="top" offset={0}>
                  <Popover.Target>
                    <TextInput
                      size="xs"
                      value={searchValue}
                      onChange={(event) => setSearchValue(event.target.value)}
                      icon={<IconSearch size={14} />}
                      placeholder="Search..."
                      color="gray.6"
                      sx={(theme) => ({
                        '*': {
                          borderColor: theme.colors.gray[4],
                        },
                      })}
                    />
                  </Popover.Target>

                  <Popover.Dropdown p="xs" sx={(theme) => ({ background: theme.colors.dark[5] })}>
                    <Flex align="center" gap={6}>
                      <Flex
                        align="center"
                        justify="center"
                        sx={(theme) => ({
                          color: theme.colors.orange[4],
                        })}
                      >
                        <IconInfoTriangle size={16} />
                      </Flex>

                      <Text fz="0.875rem" color="white">
                        Only the notes associated with the file are being displayed.
                      </Text>

                      <ActionIcon size="xs" onClick={() => setShowSearchHint(false)}>
                        <IconX size={14} />
                      </ActionIcon>
                    </Flex>
                  </Popover.Dropdown>
                </Popover>
              </Flex>

              <ActionIcon onClick={onClose} size="xs">
                <IconX size={14} />
              </ActionIcon>
            </Flex>
          </Flex>
        </Flex>

        <ScrollArea.Autosize
          mah={height}
          sx={{
            position: 'relative',
            width: '100%',
            '> div': {
              width: '100%',
            },
          }}
        >
          {client.viewerCanUpdate ? (
            <AddNoteContainer ref={addNoteRef} onExpand={() => setIsAddingNote(true)} compact defaultCollapsed />
          ) : null}

          <NotepadResizeBottomHandle
            containerY={y}
            containerHeight={height}
            onResize={({ newY, newHeight }) => {
              if (newHeight >= 320) {
                setY(newY);
                setHeight(newHeight);
              }
            }}
          />

          {renderContent()}
        </ScrollArea.Autosize>
      </>
    );
  };

  return (
    <Flex
      sx={(theme) => ({
        position: 'fixed',
        background: theme.white,
        ...(isFullscreen
          ? {
              top: 0,
              left: 0,
              width: width,
              height: '100%',
              zIndex: 200,
            }
          : {
              top: y,
              left: x,
              width,
              borderRadius: 4,
              overflow: 'hidden',
              boxShadow: theme.shadows.md,
              zIndex: 200,
            }),
      })}
      direction="column"
    >
      <NotepadResizeHandles
        containerX={x}
        containerY={y}
        containerWidth={width}
        containerHeight={height}
        onResize={({ newX, newY, newWidth, newHeight }) => {
          if (newWidth >= 500) {
            setX(newX);
            setWidth(newWidth);
          }

          if (newHeight >= 320) {
            setY(newY);
            setHeight(newHeight);
          }
        }}
      />

      {renderBody()}
    </Flex>
  );
};

export const NoteListContainer = memo(NoteListContainerBase);
