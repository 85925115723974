import { create } from 'zustand';

import { CaseTimelineTab, MedicalConditionItem, SimpleCondition } from '@/pageAI/@types/summaries';
import {
  UnifiedTab,
  categorizeConditions,
  getMedicalConditionParamsFromSearchParams,
  normalizeMedicalCondition,
  normalizeSimpleCondition,
} from '@/pageAI/services/medicalConditions';
import { SortOrder } from '@/shared/@types';
import { ConditionTermCategory } from '@/pageAI/hooks/conditions/useConditionSearchTerms';

export type SummaryViewMode = 'Table View' | 'Chronological';

export interface SummaryTabState {
  highlighted?: string;
  viewMode: SummaryViewMode;
  showNullFields: boolean;
  sortOrder: SortOrder;
  isPrinting?: boolean;
}

export interface ContentSearchState {
  showMedicalTermSearch: boolean;
  selectedTerms: string[];
  showSearchTermsWithNoResults: boolean;
  selectedTermCategory: ConditionTermCategory;
}

export interface UnifiedState {
  conditions: SimpleCondition[];
  isSidebarOpened: boolean;
  summaryTabState: SummaryTabState;
  contentSearchState: ContentSearchState;
  selectedSimpleCondition?: SimpleCondition;
  selectedCondition?: MedicalConditionItem;
  scrollPosition: {
    [UnifiedTab.CONDITION_SUMMARY]?: number;
    [UnifiedTab.TIMELINE]?: {
      All?: number;
      Bookmarked?: number;
    };
    [UnifiedTab.CONTENT_SEARCH]?: number;
    [UnifiedTab.FILE_INDEX]?: {
      loaded?: number;
      scrollPosition?: number;
    };
  };
}

export const useUnifiedStore = create<UnifiedState>((set, get) => ({
  conditions: [],
  isSidebarOpened: false,
  summaryTabState: {
    viewMode: 'Table View',
    showNullFields: false,
    sortOrder: 'desc',
  },
  contentSearchState: {
    showMedicalTermSearch: false,
    showIncludedFiles: true,
    selectedTerms: [],
    showSearchTermsWithNoResults: false,
    selectedTermCategory: ConditionTermCategory.TERMS_FROM_CFR,
  },
  scrollPosition: {},
}));

export const setConditions = (conditions: MedicalConditionItem[]) => {
  useUnifiedStore.setState({ conditions });
};

export const selectCondition = (selectedConditionId: string) => {
  const selectedSimpleCondition = useUnifiedStore
    .getState()
    .conditions.find((condition) => condition.id === selectedConditionId);

  useUnifiedStore.setState({ selectedSimpleCondition, scrollPosition: {} });
};

export const setSelectedCondition = (selectedCondition: MedicalConditionItem) => {
  useUnifiedStore.setState({ selectedCondition });
};

export const updateCondition = (conditionId: string, fieldsToUpdate: Partial<MedicalConditionItem>) => {
  useUnifiedStore.setState((state) => ({
    ...state,
    selectedCondition:
      state.selectedCondition?.id === conditionId
        ? normalizeMedicalCondition({ ...state.selectedCondition, ...fieldsToUpdate })
        : state.selectedCondition,
    conditions: state.conditions.map((condition) => {
      if (condition.id !== conditionId) return condition;

      return normalizeSimpleCondition({
        ...condition,
        ...fieldsToUpdate,
      });
    }),
  }));
};

export const toggleConditionSidebar = (opened?: boolean) => {
  if (typeof opened === 'boolean') {
    return useUnifiedStore.setState({ isSidebarOpened: opened });
  }

  useUnifiedStore.setState((state) => ({ isSidebarOpened: !state.isSidebarOpened }));
};

export const saveSummaryScrollPosition = (scrollPosition: number) => {
  useUnifiedStore.setState((state) => ({
    ...state,
    scrollPosition: {
      ...state.scrollPosition,
      [UnifiedTab.CONDITION_SUMMARY]: scrollPosition,
    },
  }));
};

export const saveTimelineScrollPosition = (tab: CaseTimelineTab, scrollPosition: number) => {
  useUnifiedStore.setState((state) => ({
    ...state,
    scrollPosition: {
      ...state.scrollPosition,
      [UnifiedTab.TIMELINE]: {
        ...state.scrollPosition[UnifiedTab.TIMELINE],
        [tab]: scrollPosition,
      },
    },
  }));
};

export const saveContentSearchScrollPosition = (scrollPosition: number) => {
  useUnifiedStore.setState((state) => ({
    ...state,
    scrollPosition: {
      ...state.scrollPosition,
      [UnifiedTab.CONTENT_SEARCH]: scrollPosition,
    },
  }));
};

export const saveFileIndexLoadedCount = (loaded: number) => {
  useUnifiedStore.setState((state) => ({
    ...state,
    scrollPosition: {
      ...state.scrollPosition,
      [UnifiedTab.FILE_INDEX]: {
        ...state.scrollPosition[UnifiedTab.FILE_INDEX],
        loaded,
      },
    },
  }));
};

export const saveFileIndexScrollPosition = (scrollPosition: number) => {
  useUnifiedStore.setState((state) => ({
    ...state,
    scrollPosition: {
      ...state.scrollPosition,
      [UnifiedTab.FILE_INDEX]: {
        ...state.scrollPosition[UnifiedTab.FILE_INDEX],
        scrollPosition,
      },
    },
  }));
};

export const resetUnifiedState = () => {
  useUnifiedStore.setState({
    conditions: [],
    isSidebarOpened: false,
    selectedSimpleCondition: undefined,
    selectedCondition: undefined,
    summaryTabState: {
      viewMode: 'Table View',
      showNullFields: false,
      sortOrder: 'desc',
    },
    scrollPosition: {},
  });
};

// =============================== Condition Summary ===============================
export const setSummaryTabState = (state: Partial<SummaryTabState>) => {
  useUnifiedStore.setState((prevState) => ({
    ...prevState,
    summaryTabState: {
      ...prevState.summaryTabState,
      ...state,
    },
  }));
};

export const setSummaryTabViewMode = (viewMode: SummaryViewMode) => {
  setSummaryTabState({ viewMode });
};

export const setShowConditionSummaryNullFields = (showNullFields: boolean) => {
  setSummaryTabState({ showNullFields });
};

export const setSummaryTabSortOrder = (sortOrder: SortOrder) => {
  setSummaryTabState({ sortOrder });
};

export const setSummaryHighlighted = (highlighted?: string) => {
  setSummaryTabState({ highlighted });
};

export const setSummaryIsPrinting = (isPrinting: boolean) => {
  setSummaryTabState({ isPrinting });
};

export const setDefaultCondition = () => {
  const conditions = useUnifiedStore.getState().conditions;

  const { medicalConditionId } = getMedicalConditionParamsFromSearchParams(new URLSearchParams(window.location.search));

  selectCondition(medicalConditionId || categorizeConditions(conditions).claimedConditions[0]?.id || conditions[0].id);
};

// =============================== Content Search ===============================
export const setContentSearchState = (state: Partial<ContentSearchState>) => {
  useUnifiedStore.setState((prevState) => ({
    ...prevState,
    contentSearchState: {
      ...prevState.contentSearchState,
      ...state,
    },
  }));
};

export const setShowMedicalTermSearch = (showMedicalTermSearch: boolean) => {
  setContentSearchState({ showMedicalTermSearch });
};

export const toggleShowMedicalTermSearch = () => {
  setShowMedicalTermSearch(!useUnifiedStore.getState().contentSearchState.showMedicalTermSearch);
};

export const setSelectedTerms = (selectedTerms: string[]) => {
  setContentSearchState({ selectedTerms });
};

export const setShowSearchTermsWithNoResults = (showSearchTermsWithNoResults: boolean) => {
  setContentSearchState({ showSearchTermsWithNoResults });
};

export const selectTermCategory = (selectedTermCategory: ConditionTermCategory) => {
  setContentSearchState({ selectedTermCategory });
};
