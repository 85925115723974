import { memo, useMemo, useState } from 'react';
import { Flex, Input, Loader, Menu, ScrollArea, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconChevronDown, IconSearch } from '@tabler/icons-react';

import { FileAsset } from '@/shared/@types';
import { useFileIndexConfig } from '@/pageAI/hooks/files/useFileIndexConfig';

interface FileNameSelectProps {
  value?: string;
  onChange?: (value: string) => void;
  fileAsset: FileAsset;
}

const ALWAYS_DISPLAYED_FILE_TYPES = [
  'Other Evidence - Medical Evidence',
  'Other Evidence - General',
  'Other Evidence - Service Records',
];

const FileNameSelectBase = ({ fileAsset, value, onChange }: FileNameSelectProps) => {
  const { fileNames, isLoading } = useFileIndexConfig();
  const [searchValue, setSearchValue] = useState('');
  const [opened, { open, close }] = useDisclosure();

  const filteredFileNames = useMemo(
    () =>
      fileNames.filter((fileName) => {
        return fileName.toLowerCase().includes(searchValue.toLowerCase());
      }),
    [fileNames, searchValue],
  );

  const availableFileTypes = filteredFileNames.length > 0 ? filteredFileNames : ALWAYS_DISPLAYED_FILE_TYPES;

  const renderSearchInput = () => {
    return (
      <Flex
        sx={(theme) => ({
          borderTop: `1px solid ${theme.colors.gray[2]}`,
          borderBottom: `1px solid ${theme.colors.gray[2]}`,
          '.ghost-Input-icon': {
            paddingLeft: 4,
          },
        })}
      >
        <Input
          type="text"
          placeholder="Search file type..."
          value={searchValue}
          onChange={(event) => setSearchValue(event.target.value)}
          icon={<IconSearch size={16} />}
          sx={(theme) => ({
            width: '100%',
            padding: 0,
            input: {
              fontSize: '0.875rem',
              border: 'none',
              background: theme.fn.lighten(theme.colors.gray[0], 0.5),
            },
          })}
        />
      </Flex>
    );
  };

  return (
    <Menu withinPortal zIndex={302} shadow="sm" opened={opened} onClose={close} position="bottom">
      <Menu.Target>
        <Flex>
          <Tooltip label={value} withinPortal withArrow openDelay={1000} {...(!value ? { opened: false } : {})}>
            <Flex
              align="center"
              gap={4}
              sx={(theme) => ({
                borderBottom: `1px solid ${theme.colors.dark[3]}`,
                cursor: 'pointer',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              })}
              onClick={(event) => {
                event.stopPropagation();

                open();
              }}
            >
              <Text
                fw={500}
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {value || 'Select a file type...'}
              </Text>

              {fileAsset.viewerCanUpdate && <IconChevronDown size={12} style={{ minWidth: 12 }} />}
            </Flex>
          </Tooltip>
        </Flex>
      </Menu.Target>

      <Menu.Dropdown p={0} onClick={(event) => event.stopPropagation()}>
        {renderSearchInput()}

        {isLoading ? (
          <Flex align="center" justify="center" sx={{ height: 40 }} gap={6}>
            <Loader size={14} />

            <Text fz="0.875rem" color="gray.7">
              Loading file types...
            </Text>
          </Flex>
        ) : (
          <ScrollArea.Autosize mah={224}>
            <Flex direction="column" p={4}>
              {availableFileTypes.map((fileName) => {
                const isActive = fileName === value;

                return (
                  <Menu.Item
                    key={fileName}
                    onClick={() => {
                      onChange?.(fileName);
                    }}
                    sx={(theme) => ({
                      maxWidth: 400,
                      ...(isActive
                        ? {
                            color: `${theme.white} !important`,
                            backgroundColor: `${theme.colors.dark[4]} !important`,
                          }
                        : {}),
                    })}
                  >
                    {fileName}
                  </Menu.Item>
                );
              })}
            </Flex>
          </ScrollArea.Autosize>
        )}
      </Menu.Dropdown>
    </Menu>
  );
};

export const FileNameSelect = memo(FileNameSelectBase);
