import { useMemo } from 'react';

import { useCurrentUser } from '../../users/useCurrentUser';
import { Organization } from '@/pageAI/@types';

export const useCurrentMember = (organization: Organization | undefined | null) => {
  const { currentUser } = useCurrentUser();

  const currentMember = useMemo(
    () => organization?.members.find((member) => member.user.id === currentUser?.id),
    [organization, currentUser],
  );

  return currentMember;
};
