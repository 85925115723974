import { memo } from 'react';

import { Alert, Box, Button, Flex, Text } from '@mantine/core';

import { MedicalConditionItem } from '@/pageAI/@types/summaries';
import { SummaryFileReferenceBadge } from '../../../summaries/SummaryFileReferenceBadge';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { IconCheck } from '@tabler/icons-react';
import { ClaimStatus } from '@/pageAI/gql/graphql';
import { useUpdateCondition } from '@/pageAI/hooks/conditions/useUpdateCondition';
import { ClaimStatusEditButton } from '../ClaimStatusEditButton';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import {
  isPrintingConditionSummarySelector,
  setSummaryHighlighted,
  summaryTabHighlightedSelector,
  useUnifiedStore,
} from '@/pageAI/states/unified';
import { ClaimStatusInfo } from '../ClaimStatusInfo';
import { shouldShowDateHint } from '@/pageAI/services/medicalConditions';
import { useConditionClaimStatusFeatureFlag } from '@/pageAI/hooks/featureFlags/useConditionClaimStatusFeatureFlag';

interface ConditionClaimStatusSectionProps {
  condition: MedicalConditionItem;
}

const ConditionClaimStatusSectionBase = ({ condition }: ConditionClaimStatusSectionProps) => {
  const client = useSelectedClient(true);
  const { notify } = useNotifications();
  const { updateCondition, isUpdating } = useUpdateCondition(client.id);
  const { enabled } = useConditionClaimStatusFeatureFlag();

  const isPrinting = useUnifiedStore(isPrintingConditionSummarySelector);
  const highlighted = useUnifiedStore(summaryTabHighlightedSelector);

  const entryKey = 'claimStatus';

  const handleConfirm = async () => {
    try {
      await updateCondition({ id: condition.id, claimStatus: { isConfirmed: true } });

      notify('Success', 'Successfully confirmed claim status', 'brand');
    } catch {
      notify('Error', 'Failed to confirm claim status');
    }
  };

  if (!enabled || !condition.claimStatus || condition.claimStatus.status === ClaimStatus.Unknown) return null;

  return (
    <Alert
      variant="outline"
      color="dark"
      mb="xl"
      pos="relative"
      sx={{
        overflow: 'visible',
        padding: 0,
        '.ghost-Alert-body': {
          width: '100%',
        },
      }}
    >
      <Text
        fw={500}
        pos="absolute"
        fz="0.75rem"
        top={-10}
        left={10}
        px={4}
        sx={(theme) => ({
          background: theme.white,
        })}
      >
        Condition claim status
      </Text>

      <Flex align="center" justify="space-between" gap="xs" sx={{ flexWrap: 'wrap', maxWdith: '100%' }} px={16} py={12}>
        <Box>
          <ClaimStatusInfo
            claimStatus={condition.claimStatus.status}
            sourceDocumentDate={condition.claimStatus.lastFilingDate}
            dateHint={
              shouldShowDateHint(condition.claimStatus)
                ? 'A notification letter was not found. The Rating Decision - Narrative is being used to calculate the appeal deadline.'
                : undefined
            }
          />

          {condition.claimStatus.fileReference && (
            <Box display="inline-block" ml={6}>
              <SummaryFileReferenceBadge
                client={client}
                reference={condition.claimStatus.fileReference}
                isPrinting={isPrinting}
                isHighlighted={highlighted === entryKey}
                onClick={() => setSummaryHighlighted(entryKey)}
              />
            </Box>
          )}
        </Box>

        {condition.claimStatus.isPendingConfirmation && (
          <Flex align="center" justify="end" gap="xs" sx={{ flexGrow: 1 }} className="unprintable">
            <ClaimStatusEditButton condition={condition} />

            <Button size="xs" leftIcon={<IconCheck size={16} />} onClick={handleConfirm} loading={isUpdating}>
              Confirm
            </Button>
          </Flex>
        )}
      </Flex>
    </Alert>
  );
};

export const ConditionClaimStatusSection = memo(ConditionClaimStatusSectionBase);
