import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { MedicalConditionItem } from '@/pageAI/@types/summaries';
import { ClientConditionsQuery, queryMap as conditionsQueryMap } from '../../clients/useClientMedicalConditions';
import { normalizeMedicalCondition, normalizeSimpleCondition } from '@/pageAI/services/medicalConditions';
import { ClientConditionQuery, queryMap } from '../useCondition';
import { updateCondition as updateConditionInStore } from '@/pageAI/states/unified';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { EditMedicalConditionInput, EditMedicalConditionMutation } from '@/pageAI/gql/graphql';
import { editMedicalConditionMutation } from '@/pageAI/api';

export const useUpdateCondition = (clientId: string) => {
  const { request } = useGraphQLRequest();
  const queryClient = useQueryClient();

  const { mutateAsync: editCondition, isLoading: isUpdating } = useMutation<
    EditMedicalConditionMutation,
    Error,
    EditMedicalConditionInput
  >({
    mutationFn: async (input) => {
      const responseBody = await request(editMedicalConditionMutation, { input });

      return responseBody;
    },
  });

  const updateConditionsQuery = useCallback(
    (conditionId: string, fieldsToUpdate: Partial<MedicalConditionItem>) => {
      const queryKey = conditionsQueryMap.client.medicalConditions.queryKey(clientId);

      const previousConditionsQuery = queryClient.getQueryData<ClientConditionsQuery>(queryKey);

      queryClient.setQueriesData<ClientConditionsQuery>(queryKey, (conditionsQuery) => {
        if (!conditionsQuery) return conditionsQuery;

        return conditionsQuery.map((condition) => {
          if (condition.id !== conditionId) return condition;

          return normalizeSimpleCondition({
            ...condition,
            ...fieldsToUpdate,
          });
        });
      });

      return () => queryClient.setQueriesData<ClientConditionsQuery>(queryKey, previousConditionsQuery);
    },
    [queryClient, clientId],
  );

  const updateConditionQuery = useCallback(
    (conditionId: string, fieldsToUpdate: Partial<MedicalConditionItem>) => {
      const queryKey = queryMap.condition.queryKey(conditionId);

      const previousConditionQuery = queryClient.getQueryData<ClientConditionQuery>(queryKey);

      queryClient.setQueryData<ClientConditionQuery>(queryKey, (conditionQuery) => {
        if (!conditionQuery) return conditionQuery;

        return normalizeMedicalCondition({
          ...conditionQuery,
          ...fieldsToUpdate,
        });
      });

      return () => queryClient.setQueryData<ClientConditionQuery>(queryKey, previousConditionQuery);
    },
    [queryClient],
  );

  const updateConditionLocally = useCallback(
    (conditionId: string, fieldsToUpdate: Partial<MedicalConditionItem>) => {
      const reverseConditionsQuery = updateConditionsQuery(conditionId, fieldsToUpdate);
      const reverseConditionQuery = updateConditionQuery(conditionId, fieldsToUpdate);

      updateConditionInStore(conditionId, fieldsToUpdate);

      return () => {
        reverseConditionsQuery();
        reverseConditionQuery();
      };
    },
    [updateConditionQuery, updateConditionsQuery],
  );

  const updateCondition = useCallback(
    async (input: EditMedicalConditionInput) => {
      const responseBody = await editCondition(input);

      updateConditionLocally(input.id, responseBody.editMedicalCondition);

      return responseBody;
    },
    [updateConditionLocally, editCondition],
  );

  return {
    updateCondition,
    updateConditionLocally,
    isUpdating,
  };
};
