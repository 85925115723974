import { useQuery, useQueryClient } from '@tanstack/react-query';

import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { GraphQLRequestFunction } from '@/shared/lib/graphql';
import { normalizeMedicalCondition } from '@/pageAI/services/medicalConditions';
import { conditionQuery } from '@/pageAI/api/conditions';
import { useCallback, useEffect, useRef } from 'react';
import { setSelectedCondition } from '@/pageAI/states/unified';

const fetchConditionById = async (request: GraphQLRequestFunction, conditionId?: string) => {
  if (!conditionId) return Promise.resolve(null);

  const responseBody = await request(conditionQuery, { id: conditionId });

  if (!responseBody.clientMedicalCondition) return;

  return normalizeMedicalCondition(responseBody.clientMedicalCondition);
};

export const queryMap = {
  condition: {
    queryKey: (conditionId?: string) => ['condition', conditionId],
    queryFn: fetchConditionById,
  },
};

export type ClientConditionQuery = Awaited<ReturnType<typeof queryMap.condition.queryFn>>;

export interface UseConditionProps {
  conditionId?: string;
}

export const useCondition = ({ conditionId }: UseConditionProps) => {
  const { request } = useGraphQLRequest();
  const isUnmounted = useRef(false);

  const { data, isLoading, isError } = useQuery(
    queryMap.condition.queryKey(conditionId),
    () => queryMap.condition.queryFn(request, conditionId),
    {
      onSuccess: (condition) => {
        if (!condition || isUnmounted.current) return;

        setSelectedCondition(condition);
      },
    },
  );

  useEffect(() => {
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  return {
    condition: data,
    isLoading,
    isError,
  };
};

export const useConditionRequest = () => {
  const { request } = useGraphQLRequest();
  const queryClient = useQueryClient();

  const fetchCondition = useCallback(
    async (conditionId: string) => {
      return queryClient.fetchQuery(queryMap.condition.queryKey(conditionId), () =>
        queryMap.condition.queryFn(request, conditionId),
      );
    },
    [request, queryClient],
  );

  return { fetchCondition };
};
