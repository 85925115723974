import React, { memo, useMemo, useRef } from 'react';
import { Accordion, ActionIcon, Flex, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';

import { openRightSidebar } from '@/shared/states/layout';
import {
  setFilePreviewAsset,
  setHighlightedTexts,
  setPagesToHighlight,
  setPdfPreviewInitalPageIndex,
} from '@/shared/states/filePreview';
import { FileSearchResultCardProps } from './FileSearchResultCard.types';
import { getFileSearchResultElementId, groupFileSearchResultByPage } from '@/pageAI/services/files';
import { keys } from '@/shared/utils/object';
import { setActiveResult, useFileSearchStore } from '@/pageAI/states/fileSearch';
import { isInViewport } from '@/shared/lib/dom';
import { formatDate } from '@/shared/utils/date';
import { FileSearchResultSummary } from '../search/FileSearchResultSummary';
import { MarkdownRenderer } from '@/shared/components/common/markdown/MarkdownRenderer';
import { replaceMarkTags } from '@/shared/utils/markdown';
import { AddOrRemoveFileFromConditionButton } from '../../medicalConditions/errorCorrection/AddOrRemoveFileFromConditionButton';
import { posthog } from '@/shared/plugins/posthog';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { useConditionTermSearchFeatureFlag } from '@/pageAI/hooks/featureFlags/useConditionTermSearchFeatureFlag';
import { extractStringsBetweenTags } from '@/shared/utils/html';
import { SearchRelevanceIndicator } from '../../search/SearchRelevanceIndicator';

const FileSearchResultCardBase = ({ resultGroup, index, offset, query, onClick }: FileSearchResultCardProps) => {
  const accordionRef = useRef<HTMLDivElement>(null);
  const client = useSelectedClient(true);
  const { enabled: conditionTermSearchEnabled } = useConditionTermSearchFeatureFlag();

  const fileAsset = resultGroup.file;

  const [opened, { toggle }] = useDisclosure(true);
  const activeResult = useFileSearchStore((state) => state.activeResult);

  const snippet = resultGroup.results[0]?.snippet || '';
  const resultsByPage = useMemo(() => groupFileSearchResultByPage(resultGroup.results), [resultGroup.results]);
  const replacedSnippet = useMemo(() => replaceMarkTags(snippet), [snippet]);
  const pageNumbers = useMemo(() => keys(resultsByPage), [resultsByPage]);
  const extractedKeywords = useMemo(() => extractStringsBetweenTags(snippet, 'mark'), [snippet]);
  const isActive = activeResult?.file.id === fileAsset.id && activeResult?.pageNumber === resultGroup.pageNumber;
  const firstResult = resultGroup.results[0];

  const ensureVisible = (resultByPageIndex: number) => {
    const element = document.getElementById(getFileSearchResultElementId(offset + resultByPageIndex));

    if (!element) return;

    const isAccordionActive = accordionRef.current?.getAttribute('data-active') === 'true';

    if (!isAccordionActive)
      (accordionRef.current?.querySelector('.ghost-Accordion-control') as HTMLButtonElement | null)?.click();

    requestIdleCallback(() => {
      if (isInViewport(element)) return;

      element.scrollIntoView();
    });
  };

  const handleSelectResult = (pageNumber: number, resultByPageIndex: number) => (event: React.MouseEvent) => {
    if (!query) return;

    setActiveResult({ file: fileAsset, pageNumber, rowIndex: offset + resultByPageIndex });

    if (conditionTermSearchEnabled) {
      setHighlightedTexts(extractedKeywords);
    } else {
      setHighlightedTexts(query.split(' '));
    }

    setPagesToHighlight([pageNumber - 1]);
    setPdfPreviewInitalPageIndex(pageNumber - 1);
    openRightSidebar();
    setFilePreviewAsset({
      ...fileAsset,
      name: fileAsset.metadata?.pageAIMetadata?.vaFileTypeName || '',
      index: fileAsset.metadata?.pageAIMetadata?.indexNumber,
    });

    onClick?.(event, index, fileAsset);

    posthog.capture('[Search] Select search result', {
      clientId: client.id,
      clientFirstName: client.firstName,
      clientLastName: client.lastName,
      fileId: fileAsset.id,
      fileName: fileAsset.name,
      query,
      resultIndex: resultByPageIndex,
      resultPage: pageNumber,
    });

    requestIdleCallback(() => ensureVisible(resultByPageIndex));
  };

  return (
    <Accordion
      className={['file-search-result-card', index].join('-')}
      sx={(theme) => ({
        transition: 'all 0.1s',
        border: `1px solid ${theme.colors.gray[3]}`,
        borderTop: 'none',
      })}
      value={opened ? 'item' : undefined}
    >
      <Accordion.Item
        ref={accordionRef}
        value="item"
        data-file-id={fileAsset.id}
        onClick={handleSelectResult(resultGroup.pageNumber, 0)}
        sx={(theme) => ({
          position: 'relative',
          borderBottom: 'none',
          transition: 'all 0.1s ease-in-out',
          cursor: 'pointer',
          ...(isActive
            ? {
                background: theme.colors.gray[1],
                '.Accordion-control': {
                  background: theme.colors.gray[1],
                },
                '.file-search-result-snippet': {
                  border: `1px solid ${theme.colors.gray[4]}`,
                },
              }
            : {
                '.Accordion-control': {
                  background: theme.white,
                },
              }),

          '&:hover': {
            background: theme.colors.gray[1],
            '.Accordion-control': {
              background: theme.colors.gray[1],
            },
            '.file-search-result-snippet': {
              border: `1px solid ${theme.colors.gray[4]}`,
            },
          },
        })}
      >
        <Flex
          className="Accordion-control"
          sx={(theme) => ({
            position: 'sticky',
            top: 0,
            zIndex: 1,
            padding: 0,
            transition: 'all 0.1s ease-in-out',
            borderTop: `1px solid ${theme.colors.gray[3]}`,
            borderBottom: `1px solid ${theme.colors.gray[3]}`,
          })}
        >
          <Flex direction="column" pt="md" pb="sm" gap={4} sx={{ width: '100%' }}>
            <Flex align="center" justify="space-between" gap="xs" sx={{ width: '100%', overflow: 'hidden' }}>
              <Flex align="center" gap="xs" ml="md" sx={{ maxWidth: '70%' }}>
                <Flex
                  align="center"
                  justify="center"
                  sx={(theme) => ({
                    padding: 4,
                    background: theme.colors.dark[4],
                    minWidth: 20,
                    height: 20,
                    borderRadius: 4,
                  })}
                >
                  <Text fz="0.75rem" fw={500} color="white">
                    {fileAsset.index || index + 1}
                  </Text>
                </Flex>

                <Text
                  fz="0.875rem"
                  color="dark.5"
                  fw={isActive ? 600 : 500}
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {fileAsset.name}
                </Text>

                <Flex
                  sx={(theme) => ({
                    width: 4,
                    height: 4,
                    borderRadius: '50%',
                    background: theme.colors.gray[5],
                  })}
                />

                <Text fz="0.875rem" color="dark.3" sx={{ whiteSpace: 'nowrap' }}>
                  Page {resultGroup.pageNumber}
                </Text>

                <SearchRelevanceIndicator relevanceScore={firstResult.rerankMultiplier} />
              </Flex>

              <Flex align="center" gap="xs" pr="md" sx={{ overflow: 'hidden' }}>
                {fileAsset.fileReceiveDate && (
                  <Flex align="center" sx={{ overflow: 'hidden' }}>
                    <Text
                      fz="0.875rem"
                      color="dark.3"
                      sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                    >
                      Received {formatDate(fileAsset.fileReceiveDate)}
                    </Text>
                  </Flex>
                )}

                <AddOrRemoveFileFromConditionButton
                  fileAsset={fileAsset}
                  pageIndices={[firstResult.pageNumber - 1]}
                  color="gray.7"
                  removeButtonColor="dark.4"
                  variant="filled"
                  removeButtonVariant="outline"
                />

                <ActionIcon
                  size="xs"
                  color="gray.6"
                  ml={-4}
                  onClick={(event) => {
                    event.stopPropagation();
                    toggle();
                  }}
                >
                  {opened ? <IconChevronUp size={16} /> : <IconChevronDown size={16} />}
                </ActionIcon>
              </Flex>
            </Flex>

            <FileSearchResultSummary fileId={resultGroup.file.id} />
          </Flex>
        </Flex>

        <Accordion.Panel p={0} sx={{ '.ghost-Accordion-content': { padding: 0 } }}>
          <Flex direction="column">
            {pageNumbers.map((pageNumber, resultByPageIndex) => {
              const results = resultsByPage[pageNumber];

              return (
                <Flex
                  key={pageNumber}
                  id={getFileSearchResultElementId(offset + resultByPageIndex)}
                  align="center"
                  gap="xs"
                  py="xs"
                  px="md"
                  sx={(theme) => ({
                    ...(resultByPageIndex !== pageNumbers.length - 1 && {
                      borderBottom: `1px solid ${theme.colors.gray[3]}`,
                    }),
                  })}
                >
                  <Flex direction="column" sx={{ flex: '1 1' }} gap="xs">
                    {results.map((result, resultIndex) => (
                      <Flex
                        key={result.snippet + result.score + resultIndex}
                        className="file-search-result-snippet"
                        align="center"
                        gap="xs"
                        pos="relative"
                        sx={(theme) => ({
                          padding: '4px 8px',
                          borderRadius: 4,
                          border: `1px solid ${theme.colors.gray[2]}`,
                          background: theme.colors.gray[2],
                          transition: 'all 0.1s ease-in-out',
                        })}
                      >
                        <Text
                          fz="0.75rem"
                          sx={(theme) => ({
                            '*': {
                              fontSize: '0.75rem',
                              fontWeight: 400,
                            },
                            wordBreak: 'break-word',
                            p: { margin: 0 },
                            ul: {
                              margin: 0,
                            },
                            ol: {
                              margin: 0,
                            },
                            del: {
                              textDecoration: 'none',
                              fontFamily: 'unset',
                              background: 'rgba(255, 180, 40, 0.5)',
                              borderRadius: 4,
                            },
                          })}
                        >
                          <MarkdownRenderer content={replacedSnippet} />
                        </Text>
                      </Flex>
                    ))}
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

export const FileSearchResultCard = memo(FileSearchResultCardBase);
