import { memo, useMemo } from 'react';
import { ActionIcon, Flex, Sx, Tooltip } from '@mantine/core';
import { IconCircleFilled, IconNotes } from '@tabler/icons-react';

import { useNotes } from '@/pageAI/hooks/notes/useNotes';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { getNotesForFile, openNotepadEventBus, takeNoteFromFile } from '@/pageAI/services/notes';
import { singularOrPlural } from '@/shared/utils/string';
import { FileAsset } from '@/shared/@types';
import { posthog } from '@/shared/plugins/posthog';

import IconNotePlus from '@/shared/icons/note-plus.svg?react';
import { useNotesFeatureFlag } from '@/pageAI/hooks/featureFlags/useNotesFeatureFlag/useNotesFeatureFlag';

interface FileNotesProps {
  fileAsset: FileAsset;
  sx?: Sx;
  canTakeNote?: boolean;
  size?: number;
}

const FileNotesBase = ({ fileAsset, sx, canTakeNote = true, size = 24 }: FileNotesProps) => {
  const { enabled } = useNotesFeatureFlag();
  const client = useSelectedClient(true);
  const { notes, isLoading } = useNotes({ clientId: client?.id });

  const fileNotes = useMemo(() => (notes ? getNotesForFile(notes, fileAsset.id) : []), [notes, fileAsset]);

  const handleClick = () => {
    if (!client) return;

    if (fileNotes.length) {
      posthog.capture('[Notes] Open file notes', {
        clientId: client.id,
        clientFirstName: client.firstName,
        clientLastName: client.lastName,
        fileId: fileAsset.id,
        fileName: fileAsset.metadata?.pageAIMetadata?.vaFileTypeName,
        fileIndex: fileAsset.metadata?.pageAIMetadata?.indexNumber,
      });

      openNotepadEventBus.publish({ searchValue: fileAsset.metadata?.pageAIMetadata?.indexNumber });

      return;
    }

    takeNoteFromFile({ client, fileAsset });
  };

  if (!enabled || isLoading || (!canTakeNote && !fileNotes.length)) return null;

  return (
    <Flex
      align="center"
      justify="center"
      sx={{ position: 'absolute', right: 12, top: 16, zIndex: 200, ...sx }}
      onClick={(event) => event.stopPropagation()}
    >
      <Tooltip
        label={
          fileNotes.length
            ? `There ${singularOrPlural('is', 'are')(fileNotes.length)} ${fileNotes.length} ${singularOrPlural(
                'note',
                'notes',
              )(fileNotes.length)} for this file.`
            : 'Take note for this file'
        }
        withArrow
      >
        <Flex pos="relative" align="center" justify="center" onClick={handleClick}>
          <ActionIcon color="gray.7" size="xs">
            {fileNotes.length ? <IconNotes size={size} /> : <IconNotePlus width={size} height={size} />}
          </ActionIcon>

          {!!fileNotes.length && (
            <Flex
              sx={(theme) => ({
                color: theme.colors.red[6],
                background: theme.white,
                borderRadius: '50%',
              })}
              pos="absolute"
              top={0}
              right={0}
            >
              <IconCircleFilled size={8} />
            </Flex>
          )}
        </Flex>
      </Tooltip>
    </Flex>
  );
};

export const FileNotes = memo(FileNotesBase);
