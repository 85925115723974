import { create } from 'zustand';

interface UnrecognizedFilesStore {
  client: Record<string, Record<string, string | undefined>>;
}

export const useUnrecognizedFilesStore = create<UnrecognizedFilesStore>((set, get) => ({
  client: {},
}));

export const unrecognizedFileNewValueSelector =
  (clientId: string, fileId: string) => (state: UnrecognizedFilesStore) => {
    return state.client[clientId]?.[fileId];
  };

export const setUnrecognizedFileNewValue = (clientId: string) => (fileId: string, newFileType: string) => {
  useUnrecognizedFilesStore.setState((state) => {
    const client = state.client[clientId] || {};

    return {
      client: {
        ...state.client,
        [clientId]: {
          ...client,
          [fileId]: newFileType,
        },
      },
    };
  });
};
