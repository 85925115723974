import { ClaimStatus, ClaimStatusInfo } from '@/pageAI/gql/graphql';

export const CONDITION_CLAIM_STATUS_TEXT_MAPPING = {
  [ClaimStatus.Closed]: 'Closed',
  [ClaimStatus.Open]: 'Open',
  [ClaimStatus.PendingAdjudication]: 'Pending Adjudication',
  [ClaimStatus.Unknown]: 'Unknown',
};

export const CONDITION_CLAIM_STATUS_COLOR_MAPPING = {
  [ClaimStatus.Closed]: 'red',
  [ClaimStatus.Open]: 'teal',
  [ClaimStatus.PendingAdjudication]: 'yellow',
  [ClaimStatus.Unknown]: 'gray',
};

export const CONDITION_CLAIM_STATUS_ORDER_MAPPING = {
  [ClaimStatus.Open]: 0,
  [ClaimStatus.PendingAdjudication]: 1,
  [ClaimStatus.Closed]: 2,
  [ClaimStatus.Unknown]: 3,
};

export const shouldShowDateHint = (claimStatus: ClaimStatusInfo) => {
  return (
    [ClaimStatus.Open, ClaimStatus.Closed].includes(claimStatus.status) &&
    claimStatus.fileReference?.toLowerCase().includes('rating decision')
  );
};
