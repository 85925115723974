import React, { memo, useEffect, useMemo } from 'react';
import { Accordion, Alert, Anchor, Button, Flex, ScrollArea, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconAlertTriangle, IconChevronDown, IconChevronUp } from '@tabler/icons-react';

import { getClientFiles } from '@/pageAI/services/clients';
import { FileClassificationAlertProps } from './FileClassificationAlert.types';
import { filterUnrecognizedFileAssets, reviewUnrecognizedFilesPubsub } from '@/pageAI/services/files';
import { UnrecognizedFileTable } from '../UnrecognizedFileTable';
import { useConfirmVAFileType } from '@/pageAI/hooks/files/useConfirmVAFileType';
import { useUnrecognizedFilesStore } from '@/pageAI/states/unrecognizedFiles';
import { keys } from '@/shared/utils/object';

const FileClassificationAlertBase = ({ client }: FileClassificationAlertProps) => {
  const { confirmVAFileType, isLoading } = useConfirmVAFileType();

  const [opened, { open, toggle, close }] = useDisclosure();
  const { fileAssets } = useMemo(() => getClientFiles(client), [client]);
  const unrecognizedFiles = useMemo(() => filterUnrecognizedFileAssets(fileAssets), [fileAssets]);
  const unrecognizedFileMapping = useUnrecognizedFilesStore((state) => state.client[client.id]);

  const reviewedFileIds = useMemo(
    () => (unrecognizedFileMapping ? keys(unrecognizedFileMapping) : []),
    [unrecognizedFileMapping],
  );

  useEffect(() => {
    const unsubscribe = reviewUnrecognizedFilesPubsub.subscribe(() => {
      open();
    });

    return unsubscribe;
  }, [open]);

  const handleReview = (event: React.MouseEvent) => {
    event.preventDefault();

    toggle();
  };

  const handleConfirm = async () => {
    await confirmVAFileType(reviewedFileIds);

    close();
  };

  if (!unrecognizedFiles.length) return null;

  return (
    <Alert variant="light" color="orange" sx={(theme) => ({ border: `1px solid ${theme.colors.orange[5]}` })}>
      <Flex direction="column">
        <Flex justify="space-between">
          <Flex align="center" gap={6}>
            <Flex
              align="center"
              justify="center"
              sx={(theme) => ({
                color: theme.colors.orange[5],
              })}
            >
              <IconAlertTriangle size={20} />
            </Flex>

            <Text fz="0.875rem" color="dark">
              You have unrecognized file types –&nbsp;
              <Anchor sx={{ textDecoration: 'underline' }} onClick={handleReview}>
                please review
              </Anchor>
              &nbsp;before generating your summaries.
            </Text>
          </Flex>

          <Button
            variant="light"
            color="orange"
            size="xs"
            onClick={handleReview}
            sx={{
              height: 20,
              padding: '0 4px',
              '.ghost-Button-rightIcon': {
                marginLeft: 4,
              },
            }}
            rightIcon={opened ? <IconChevronUp size={14} /> : <IconChevronDown size={14} />}
          >
            Review
          </Button>
        </Flex>

        <Accordion
          value={opened ? 'default' : undefined}
          sx={{
            '.ghost-Accordion-content': {
              padding: 0,
            },
          }}
        >
          <Accordion.Item value="default" p={0} sx={{ border: 'none' }}>
            <Accordion.Panel p={0}>
              <Flex direction="column" gap={4}>
                <ScrollArea.Autosize mah={396} mt="md">
                  <Flex pb="xs">
                    <UnrecognizedFileTable fileAssets={unrecognizedFiles} />
                  </Flex>
                </ScrollArea.Autosize>

                <Flex align="center" justify="end" gap="xs">
                  <Button variant="subtle" color="gray.7" onClick={close}>
                    Close
                  </Button>

                  <Button
                    color="orange.6"
                    onClick={handleConfirm}
                    loading={isLoading}
                    disabled={!reviewedFileIds.length}
                  >
                    Confirm
                  </Button>
                </Flex>
              </Flex>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Flex>
    </Alert>
  );
};

export const FileClassificationAlert = memo(FileClassificationAlertBase);
