import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { confirmVAFileTypeMutation } from '@/pageAI/api';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { ConfirmVaFileTypeMutation, ConfirmVaFileTypeMutationVariables } from '@/pageAI/gql/graphql';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { useSelectedClient } from '../../clients/useSelectedClient';

export const useConfirmVAFileType = () => {
  const { request } = useGraphQLRequest();
  const { notify } = useNotifications();
  const client = useSelectedClient(true);
  const queryClient = useQueryClient();

  const refetchClient = useCallback(async () => {
    await queryClient.refetchQueries(['client', client.id]);
  }, [client.id, queryClient]);

  const { mutateAsync, isLoading } = useMutation<ConfirmVaFileTypeMutation, Error, ConfirmVaFileTypeMutationVariables>({
    mutationFn: async (input) => {
      const response = await request(confirmVAFileTypeMutation, input);

      await refetchClient();

      return response;
    },
  });

  const confirmVAFileType = useCallback(
    async (fileIds: string[]) => {
      try {
        await mutateAsync({ fileIds });

        notify('Success', 'File types updated successfully', 'brand');
      } catch (error) {
        notify('Error', 'Failed to update file types');
      }
    },
    [notify, mutateAsync],
  );

  return { confirmVAFileType, isLoading };
};
