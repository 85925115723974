import { memo, useMemo, useState } from 'react';
import { Alert, Button, Checkbox, Flex, Modal, Text } from '@mantine/core';
import { IconAlertTriangle } from '@tabler/icons-react';
import { capitalize } from 'lodash-es';

import { GenerateSummariesModalProps } from './GenerateSummariesModal.types';
import { getClientDisplayName, getClientFiles } from '@/pageAI/services/clients';
import { useTriggerSummariesGeneration } from '@/pageAI/hooks/caseTimeline/useEnsureTimelineGeneration';
import { filterUnrecognizedFileAssets, reviewUnrecognizedFilesPubsub } from '@/pageAI/services/files';
import { posthog } from '@/shared/plugins/posthog';

const GenerateSummariesModalBase = ({
  client,
  opened = false,
  onClose,
  isRegenerate = false,
  defaultIsCaseTimelineSelected = true,
  defaultIsConditionSummarySelected = true,
}: GenerateSummariesModalProps) => {
  const [shouldGenerateCaseTimeline, setShouldGenerateCaseTimeline] = useState(defaultIsCaseTimelineSelected);
  const [shouldGenerateConditionSummary, setShouldGenerateConditionSummary] = useState(
    defaultIsConditionSummarySelected,
  );

  const { triggerSummariesGeneration } = useTriggerSummariesGeneration(client, {
    generateTimeline: shouldGenerateCaseTimeline,
    generateMedicalConditionSummary: shouldGenerateConditionSummary,
  });
  const { fileAssets } = useMemo(() => getClientFiles(client), [client]);
  const unrecognizedFileAssets = useMemo(() => filterUnrecognizedFileAssets(fileAssets), [fileAssets]);

  if (!client.viewerCanGenerateTimeline) return null;

  const handleTriggerSummariesGeneration = async () => {
    posthog.capture(isRegenerate ? '[Summaries] Regenerate summaries' : '[Summaries] Generate summaries', {
      clientId: client.id,
      clientFirstName: client.firstName,
      clientLastName: client.lastName,
      generateCaseTimeline: shouldGenerateCaseTimeline,
      generateConditionSummary: shouldGenerateConditionSummary,
    });

    triggerSummariesGeneration(client);

    onClose();
  };

  const handleReviewUnclassifiedFiles = () => {
    onClose();

    reviewUnrecognizedFilesPubsub.publish({});
  };

  const actionText = isRegenerate ? 'regenerate' : 'generate';
  const title = [capitalize(actionText), 'summaries'].join(' ');

  return (
    <Modal centered opened={opened} onClose={onClose} title={title} size={640}>
      <Flex direction="column" gap="md">
        <Flex direction="column" gap={6}>
          <Text color="dark.5" fz="0.875rem">
            Please ensure that you have uploaded all relevant files before confirming that you want to {actionText}{' '}
            summaries for <strong>{getClientDisplayName(client)}</strong>.
          </Text>

          {isRegenerate ? (
            <>
              <Text color="dark.5" fz="0.875rem">
                Select the summaries you would like to {actionText}:
              </Text>

              <Flex direction="column" gap={6} pl="xs">
                <Checkbox
                  label="Case Timeline"
                  sx={{ '.ghost-Checkbox-label': { fontSize: '0.875rem' } }}
                  size="xs"
                  checked={shouldGenerateCaseTimeline}
                  onChange={(event) => setShouldGenerateCaseTimeline(event.target.checked)}
                />

                <Checkbox
                  label="Condition Summary"
                  sx={{ '.ghost-Checkbox-label': { fontSize: '0.875rem' } }}
                  size="xs"
                  checked={shouldGenerateConditionSummary}
                  onChange={(event) => setShouldGenerateConditionSummary(event.target.checked)}
                />
              </Flex>
            </>
          ) : null}

          <Text color="dark.5" fz="0.875rem">
            You will receive an email when your summaries are complete.
          </Text>

          {/* {!isRegenerate && !unrecognizedFileAssets.length && (
            <Alert
              mt={4}
              title={
                <Flex align="center" gap={6}>
                  <IconAlertTriangle size="1rem" />
                  Credit deduction warning
                </Flex>
              }
              color="dark"
              variant="outline"
            >
              When you confirm that you want to generate summaries for this client, <strong>1 credit</strong> will be
              deducted from the credit balance of your organization.
            </Alert>
          )} */}

          {unrecognizedFileAssets.length > 0 && (
            <Alert
              mt={4}
              title={
                <Flex align="center" gap={6}>
                  <IconAlertTriangle size="1rem" />
                  You have unrecognized file types!
                </Flex>
              }
              color="orange"
              variant="outline"
            >
              There are currently unrecognized file types associated with this client. Please review to ensure your
              summaries will be of the highest possible quality.
            </Alert>
          )}
        </Flex>

        <Flex sx={{ alignSelf: 'flex-end' }} gap="xs">
          {unrecognizedFileAssets.length > 0 ? (
            <>
              <Button onClick={handleReviewUnclassifiedFiles} color="orange.6">
                Review unclassified files
              </Button>
            </>
          ) : (
            <>
              <Button onClick={onClose} color="gray.7" variant="subtle">
                Cancel
              </Button>

              <Button onClick={handleTriggerSummariesGeneration} color="red">
                Confirm
              </Button>
            </>
          )}
        </Flex>
      </Flex>
    </Modal>
  );
};

export const GenerateSummariesModal = memo(GenerateSummariesModalBase);
