import { graphql } from '@/pageAI/gql';

export const createConversationMutation = graphql(/* GraphQL */ `
  mutation CreateConversation($input: CreateConversationInput!) {
    createConversation(input: $input) {
      id
      name
      createdAt
      updatedAt
    }
  }
`);

export const updateConversationMutation = graphql(/* GraphQL */ `
  mutation UpdateConversation($input: UpdateConversationInput!) {
    updateConversation(input: $input) {
      id
      name
      createdAt
      updatedAt
    }
  }
`);

export const generateConversationNameMutation = graphql(/* GraphQL */ `
  mutation GenerateConversationName($input: GenerateConversationNameInput!) {
    generateConversationName(input: $input)
  }
`);

export const sendMessageMutation = graphql(/* GraphQL */ `
  mutation SendMessage($input: SendMessageInput!) {
    sendMessage(input: $input) {
      id
      content
      role
      retrieverType
      createdAt
      updatedAt
    }
  }
`);

export const conversationQuery = graphql(/* GraphQL */ `
  query Conversation($id: ID!) {
    conversation(id: $id) {
      id
      name
      createdAt
      updatedAt
      messages(first: 1000) {
        nodes {
          id
          content
          role
          retrieverType
          createdAt
          updatedAt
          sources {
            snippet
            website {
              url
              name
            }
            file {
              id
              name
              mimeType
              url
              size
              viewerCanUpdate
              viewerCanDelete
              isImportant
              isCombinedCfile
              status {
                indexingStatus
                ocrStatus
                splitStatus
                transcriptionStatus
                uploadStatus
              }
              textExtractionProcessingDetails {
                processingProgress {
                  partsProcessed
                  partsTotal
                }
                processingStatus
              }
              metadata {
                totalPages
                pageAIMetadata {
                  isVA
                  indexNumber
                  fileExtension
                  vaFileTypeName
                  eventTypeClassifications
                  isVAFileTypeRecognized
                  cFileTypes
                  fileEventDate
                }
              }
              createdAt
              updatedAt
            }
          }
        }
      }
      client {
        id
        firstName
        lastName
        bio
        overview
        caseTimelineStatus
        summaryByConditionStatus
        viewerCanArchive
        viewerCanDelete
        viewerCanGenerateTimeline
        viewerCanShare
        viewerCanUnarchive
        viewerCanUpdate
        viewerCanUploadFiles
        creator {
          id
          firstName
          lastName
          email
          avatarUrl
          phone
          role
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`);

export const conversationsQuery = graphql(/* GraphQL */ `
  query Conversations($first: Int) {
    conversations(first: $first) {
      nodes {
        id
        name
        createdAt
        updatedAt
        client {
          id
          firstName
          lastName
          bio
          overview
          caseTimelineStatus
          summaryByConditionStatus
          viewerCanArchive
          viewerCanDelete
          viewerCanGenerateTimeline
          viewerCanShare
          viewerCanUnarchive
          viewerCanUpdate
          viewerCanUploadFiles
          creator {
            id
            firstName
            lastName
            email
            avatarUrl
            phone
            role
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }
      totalCount
    }
  }
`);

export const regenerateAnswerMutation = graphql(/* GraphQL */ `
  mutation RegenerateAnswer($id: ID!) {
    regenerateAnswer(id: $id) {
      id
      content
      role
      retrieverType
      createdAt
      updatedAt
    }
  }
`);

export const updateMessageMutation = graphql(/* GraphQL */ `
  mutation UpdateMessage($input: UpdateMessageInput!) {
    updateMessage(input: $input) {
      id
      content
      role
      retrieverType
      createdAt
      updatedAt
    }
  }
`);

export const getMessageUpdatedSubscriptionQuery = (conversationId: string) => {
  return `subscription MessageUpdatedSubscription {
    messageUpdated(conversationId: "${conversationId}") {
      message {
        id
        content
        role
        retrieverType
        createdAt
        updatedAt
        sources {
          snippet
          website {
            url
            name
          }
          file {
            id
            name
            mimeType
            size
            url
            isImportant
            isCombinedCfile
            status {
              indexingStatus
              ocrStatus
              splitStatus
              transcriptionStatus
              uploadStatus
            }
            textExtractionProcessingDetails {
              processingProgress {
                partsProcessed
                partsTotal
              }
              processingStatus
            }
            createdAt
            updatedAt
          }
        }
      }
    }
  }`;
};
