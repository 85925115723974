import { memo } from 'react';
import { Alert, Button, Flex, Modal, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useNavigate } from 'react-router-dom';

import { UpsertClientModalProps } from './UpsertClientModal.types';
import { useCreateClient } from '@/pageAI/hooks/clients/useCreateClient';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { useUpdateClient } from '@/pageAI/hooks/clients/useUpdateClient';
import { getClientDetailsUrl } from '@/pageAI/services/clients';

import { posthog } from '@/shared/plugins/posthog';
import { IconAlertCircleFilled } from '@tabler/icons-react';
import { DeleteClientButton } from '../DeleteClientButton';

const UpsertClientModalBase = ({ opened = false, isEdit = false, client, onClose }: UpsertClientModalProps) => {
  const { notify } = useNotifications();
  const navigate = useNavigate();

  const { createClient, isLoading: isCreating } = useCreateClient();
  const { updateClient, isLoading: isUpdating } = useUpdateClient();
  const form = useForm({
    initialValues: {
      firstName: client?.firstName || '',
      lastName: client?.lastName || '',
      bio: client?.bio || '',
    },
  });

  const isLoading = isCreating || isUpdating;

  const handleSubmit = async () => {
    const { firstName, lastName, bio } = form.values;

    try {
      if (isEdit) {
        if (!client) throw new Error('Client is not provided');

        posthog.capture('[Clients] Edit client', {
          clientId: client.id,
          clientFirstName: client.firstName,
          clientLastName: client.lastName,
          clientBio: client.bio,
          updatedFirstName: firstName,
          updatedLastName: lastName,
          updatedBio: bio,
        });

        await updateClient({ id: client.id, firstName, lastName, bio });
      } else {
        posthog.capture('[Clients] Create client', {
          clientFirstName: firstName,
          clientLastName: lastName,
        });

        const responseBody = await createClient({ firstName, lastName, bio });

        Promise.resolve().then(() => navigate(getClientDetailsUrl(responseBody.createClient.id)));

        form.reset();
      }

      onClose();
    } catch (error) {
      notify('Error', isEdit ? "Failed to edit client's info" : 'Failed to create client');
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      centered
      title={isEdit ? 'Edit client' : 'Create client'}
      sx={{
        '.ghost-Modal-title': {
          fontWeight: 500,
        },
      }}
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Flex direction="column" gap="md">
          <Flex direction="column" gap="xs">
            <TextInput
              label="First name"
              placeholder="Enter first name..."
              required
              {...form.getInputProps('firstName')}
            />

            <TextInput
              label="Last name"
              placeholder="Enter last name..."
              required
              {...form.getInputProps('lastName')}
            />

            {isEdit && client && (
              <Alert
                color="red"
                variant="outline"
                title={
                  <Flex align="center" gap={4}>
                    <IconAlertCircleFilled size={16} />
                    <Text>Archive client</Text>
                  </Flex>
                }
                sx={{
                  '.ghost-Alert-title': {
                    marginBottom: 0,
                  },
                }}
              >
                <Flex align="center" justify="space-between" gap={4} sx={{ overflow: 'visible', zIndex: 1 }}>
                  <Text>You won&apos;t be able to undo the action.</Text>

                  <Flex align="center" justify="end">
                    <DeleteClientButton compact={false} client={client} />
                  </Flex>
                </Flex>
              </Alert>
            )}
          </Flex>

          <Flex justify="flex-end" gap="xs">
            <Button variant="subtle" onClick={onClose}>
              Cancel
            </Button>

            <Button color="red" type="submit" loading={isLoading}>
              {isEdit ? 'Edit' : 'Create'}
            </Button>
          </Flex>
        </Flex>
      </form>
    </Modal>
  );
};

export const UpsertClientModal = memo(UpsertClientModalBase);
